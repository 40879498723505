import { isToday, isTomorrow } from "date-fns";
import { format } from "date-fns";

import { Skeleton } from "@/components/Skeleton";
import { Text } from "@/components/v2/Text/Text";

import type { ChoiceBoxProps } from "../../ChoiceBox";
import { ChoiceBox, ChoiceBoxItem } from "../../ChoiceBox";
import type { Slots } from "../Scheduler";

import { Slider } from "./Slider";

const SKELETON_COUNT = 5;

function getDisplayValues(firstSlot: Date) {
  let dayOfWeek = format(firstSlot, "EEEE");

  if (isToday(firstSlot)) {
    dayOfWeek = "Today";
  }
  if (isTomorrow(firstSlot)) {
    dayOfWeek = "Tomorrow";
  }

  return { dayOfWeek, monthAndDay: format(firstSlot, "MMM dd") };
}

type DayPickerProps = Pick<ChoiceBoxProps, "onValueChange" | "defaultValue"> & {
  loading?: boolean;
  slots: Slots;
};

export function DayPicker({
  slots,
  onValueChange,
  loading,
  defaultValue,
}: DayPickerProps) {
  return (
    <div className="overflow-x-hidden pb-2">
      {loading ? (
        <div className="flex flex-row gap-2 overflow-x-auto">
          {[...Array(SKELETON_COUNT)].map((_, index) => (
            <Skeleton key={index} className="h-[72px] min-w-32" />
          ))}
        </div>
      ) : (
        <ChoiceBox
          name="date"
          aria-label="Day picker"
          onValueChange={onValueChange}
          defaultValue={defaultValue}
        >
          <Slider loading={loading} scrollBy={136} scrollThreshold={24}>
            {Object.entries(slots)?.map(([day, slots]) => {
              const { dayOfWeek, monthAndDay } = getDisplayValues(
                slots[0].start,
              );
              return (
                <ChoiceBoxItem
                  aria-label="Day slot"
                  className="group flex h-[72px] min-w-32 flex-col  items-center justify-center data-[state=checked]:bg-green-100 data-[state=checked]:hover:bg-green-100"
                  value={day}
                  key={day}
                >
                  <Text
                    variant="text-2"
                    className="font-medium group-aria-checked:text-white-80"
                  >
                    {dayOfWeek}
                  </Text>
                  <Text
                    variant="text-5"
                    className="text-blue-40 group-aria-checked:text-green-20"
                  >
                    {monthAndDay}
                  </Text>
                </ChoiceBoxItem>
              );
            })}
          </Slider>
        </ChoiceBox>
      )}
    </div>
  );
}

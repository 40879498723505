export function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.548 10.35c0-1.865.688-3.311 1.754-4.296C9.375 5.062 10.874 4.5 12.548 4.5c1.675 0 3.174.562 4.247 1.554 1.065.985 1.753 2.431 1.753 4.296 0 1.541-.56 2.996-1.384 4.3a.75.75 0 1 0 1.269.801c.921-1.46 1.615-3.191 1.615-5.1 0-2.261-.846-4.115-2.235-5.399C16.43 3.676 14.555 3 12.548 3c-2.007 0-3.883.676-5.264 1.952-1.39 1.284-2.236 3.138-2.236 5.398 0 2.31 1.016 4.36 2.228 5.992 1.216 1.635 2.674 2.908 3.666 3.678a2.603 2.603 0 0 0 3.215-.001c.543-.422 1.22-.99 1.919-1.682a.75.75 0 1 0-1.055-1.067 20.073 20.073 0 0 1-1.785 1.564c-.412.321-.963.321-1.374.002-.933-.726-2.277-1.903-3.382-3.39-1.108-1.49-1.932-3.23-1.932-5.096Zm7.5-.6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm1.5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      ></path>
    </svg>
  );
}

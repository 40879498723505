import { FUNNEL_META_SESSION_STORAGE_KEY } from "@/services/storage";
import type { StorageData } from "@/services/storage/session-storage";
import { getData, setData } from "@/services/storage/session-storage";

/**
 * This hook is used to get and set the funnel meta in the session storage
 */

export type FunnelMeta =
  | Record<string, string | boolean | number>
  | StorageData;

export function useFunnelMeta() {
  function setFunnelMeta(data: FunnelMeta) {
    setData(FUNNEL_META_SESSION_STORAGE_KEY, data);
  }

  function getFunnelMeta() {
    const funnelMeta = getData(FUNNEL_META_SESSION_STORAGE_KEY) || {};

    return funnelMeta;
  }

  return { getFunnelMeta, setFunnelMeta };
}

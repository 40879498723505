import { useContext } from "react";
import type { Attributes } from "@splitsoftware/splitio/types/splitio";
import { SplitContext, useTreatments } from "@splitsoftware/splitio-react";

import type { FeatureFlagName } from "../constants";
import { getTreatmentConfig } from "../utils";

export function useFeatureFlag(
  name: FeatureFlagName,
  attributes: Attributes = {},
) {
  const { isReady } = useContext(SplitContext);
  const result = useTreatments([name], attributes)[name];

  // @ts-expect-error: Wrong type
  const config = getTreatmentConfig(result.config);
  const isOn = result.treatment === "on";

  return { isOn, isReady, config };
}

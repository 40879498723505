import * as Sentry from "@sentry/nextjs";
import { differenceInDays, differenceInHours } from "date-fns";

import { analytics } from "@flare/analytics";

import { getCompetencies } from "@/components/Funnel/utils/get-competencies";
import { getFips } from "@/components/Funnel/utils/get-fips";
import type { GetAvailableSlotsQuery } from "@/generated/graphql.vinny";
import {
  BookeeMeetingType,
  GetAvailableSlotsDocument,
  PracticeAreaName,
} from "@/generated/graphql.vinny";
import { client as apolloClient } from "@/lib/apollo-client";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";
import { FUNNEL_DATA_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData, setData } from "@/services/storage/session-storage";
import { requestTimeout } from "@/utils/request-timeout";

import {
  isQualifiedInternalEmail,
  testCounty,
  testStateCode,
} from "./checkQualifiedInternalEmail";

const MAX_MS_BOOKIT_RESPONSE = 12000;
const MAX_HOURS_TO_INTAKE_MEETING = 2;
const MAX_DAYS_TO_LSS_MEETING = 2;
export const MIN_LSS_SLOTS = 2;

type ApplyLssQualificationArgs = {
  additionalEventData?: Record<string, string | undefined>;
  funnelAnswers: FunnelAnswers;
  isQualified: boolean;
  outcomeReason: string;
};

function applyLssQualification({
  isQualified,
  outcomeReason,
  funnelAnswers,
  additionalEventData,
}: ApplyLssQualificationArgs) {
  setData(FUNNEL_DATA_SESSION_STORAGE_KEY, {
    ...funnelAnswers,
    isQualifiedForLss: isQualified,
  });

  analytics.track(BiEventsNames.WebDirectLssQualification, {
    is_qualified: isQualified,
    outcome_reason: outcomeReason,
    funnel_answers: funnelAnswers,

    ...additionalEventData,
  });
}

export async function calculateLssQualification() {
  const funnelAnswers =
    (getData(FUNNEL_DATA_SESSION_STORAGE_KEY) as FunnelAnswers) || {};
  const { practice, subPractice, county, eventStartTime, stateCode, email } =
    funnelAnswers;

  if (!practice || !subPractice || !eventStartTime) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "missing required funnel answers",
      funnelAnswers,
    });
    return false;
  }

  // Checks if county is known (Immigration doesn't require county)
  if (practice === "family" && !county) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "no county",
      funnelAnswers,
    });
    return false;
  }

  // Checks that the intake meeting is more than MAX_HOURS_TO_INTAKE_MEETING
  const intakeMeetingTime = new Date(eventStartTime);
  const currentDate = new Date();
  const hoursDifference = Math.abs(
    differenceInHours(intakeMeetingTime, currentDate),
  );
  if (hoursDifference < MAX_HOURS_TO_INTAKE_MEETING) {
    applyLssQualification({
      isQualified: false,
      outcomeReason: "intake meeting close",
      funnelAnswers,
    });
    return false;
  }

  const practiceArea: PracticeAreaName =
    practice?.toUpperCase() === PracticeAreaName.Immigration
      ? PracticeAreaName.Immigration
      : PracticeAreaName.Family;

  const competencies = getCompetencies({ practice: practiceArea, subPractice });

  const [actualStateCode, actualCounty] = isQualifiedInternalEmail(
    email,
    "dlss",
  )
    ? [testStateCode, testCounty]
    : [stateCode, county];

  const fips = getFips({
    county: actualCounty,
    stateCode: actualStateCode,
  });

  try {
    const { data } = await Promise.race([
      apolloClient.query<GetAvailableSlotsQuery>({
        query: GetAvailableSlotsDocument,
        variables: {
          payload: {
            meetingType: BookeeMeetingType.Lss,
            practiceArea,
            stateCode: actualStateCode,
            fips,
            competencies,
          },
        },
      }),
      requestTimeout(MAX_MS_BOOKIT_RESPONSE),
    ]);

    // Checks that there are more than MIN_LSS_SLOTS available slots
    if (data.getAvailableSlots.length < MIN_LSS_SLOTS) {
      applyLssQualification({
        isQualified: false,
        outcomeReason: "no lss slot",
        funnelAnswers,
        additionalEventData: {
          practice_area: practiceArea,
          state_code: stateCode,
          fips,
          competencies: competencies.toString(),
          number_of_slots: data.getAvailableSlots.length.toString(),
        },
      });
      return false;
    }

    // Checks that there are LSS meetings available in the next 2 days
    const firstAvailableSlot = new Date(data.getAvailableSlots[0].start);
    const daysDifference = Math.abs(
      differenceInDays(currentDate, firstAvailableSlot),
    );

    if (daysDifference > MAX_DAYS_TO_LSS_MEETING) {
      applyLssQualification({
        isQualified: false,
        outcomeReason: "no close lss slots",
        funnelAnswers,
      });
      return false;
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: `error fetching lss slots for practiceArea: ${practice.toString().toUpperCase()}, stateCode: ${stateCode}`,
      },
    });

    analytics.track(BiEventsNames.WebFunnelError, {
      error_type: "is_qualified_for_lss_get_available_slots_error",
      error_message: error instanceof Error ? error?.message : "unknown error",
    });

    applyLssQualification({
      isQualified: false,
      outcomeReason: "fetch lss slots failure",
      funnelAnswers,
      additionalEventData: {
        practice_area: practiceArea,
        state_code: stateCode,
        fips,
        competencies: competencies.toString(),
      },
    });
    return false;
  }

  applyLssQualification({
    isQualified: true,
    outcomeReason: "success",
    funnelAnswers,
  });
  return true;
}

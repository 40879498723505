export const lssPrices = {
  FAMILY: 49,
  IMMIGRATION: 49,
} as const;

export type LssPricesKeys = keyof typeof lssPrices;

export const CHECKOUT_STATUS = "checkoutStatus";
// Constants for form fields
export const CARD_NUMBER = "cardNumber";
export const CARD_EXPIRY = "cardExpiry";
export const CARD_CVC = "cardCvc";
export const CARD_ZIP_CODE = "cardZipCode";
export const CARD_OWNER = "cardOwner";

import { useController } from "react-hook-form";
import { Builder, withChildren } from "@builder.io/react";
import { string } from "yup";

import {
  FUNNEL_CHOICE_BOX,
  FUNNEL_CHOICE_BOX_ITEM,
} from "@/builder/components-sections";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";

import { Hint } from "./base/Hint";
import type { RadioGroupItemProps, RadioGroupProps } from "./base/RadioGroup";
import { RadioGroup, RadioGroupItem } from "./base/RadioGroup";

export type ChoiceBoxProps = Pick<
  RadioGroupProps,
  | "children"
  | "required"
  | "orientation"
  | "className"
  | "onValueChange"
  | "aria-label"
  | "defaultValue"
> & {
  hint?: string;
  name: string;
};

type ChoiceBoxItemProps = Pick<
  RadioGroupItemProps,
  "value" | "children" | "className" | "aria-label"
>;

async function handleValidation(value: string): Promise<string | undefined> {
  const schema = string().required();

  try {
    await schema.validate(value);
  } catch (error) {
    // @ts-expect-error: Wrong type
    return error.message;
  }
}

const ChoiceBox = ({
  children,
  hint,
  name,
  required,
  orientation,
  className,
  "aria-label": ariaLabel,
  onValueChange,
  defaultValue,
}: ChoiceBoxProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: { validate: (value: string) => required && handleValidation(value) },
  });

  const hasError = Boolean(error);
  const innerHint = hasError ? error?.message : hint;

  function handleValueChange(value: string) {
    field.onChange(value);
    onValueChange?.(value);
  }

  return (
    <div className="flex flex-col gap-2">
      <RadioGroup
        onValueChange={handleValueChange}
        value={field.value}
        ref={field.ref}
        orientation={orientation}
        className={className}
        aria-label={ariaLabel}
        defaultValue={defaultValue}
      >
        {children}
      </RadioGroup>
      {innerHint && <Hint error={hasError}>{innerHint}</Hint>}
    </div>
  );
};

const ChoiceBoxWithChildren = withChildren(ChoiceBox);

Builder.registerComponent(ChoiceBoxWithChildren, {
  name: FUNNEL_CHOICE_BOX,
  childRequirements: {
    message: "Choice box can only have choice box items as children",
    query: {
      "component.name": FUNNEL_CHOICE_BOX_ITEM,
    },
  },
  inputs: [
    {
      name: "hint",
      type: "string",
      helperText: "Text displayed below the choice box items",
    },
    {
      name: "name",
      type: "string",
      defaultValue: "choiceBox",
      required: true,
      helperText: "Should be written in camelCase",
      enum: Object.values(funnelInputsNameMap),
    },
    {
      name: "required",
      type: "boolean",
      defaultValue: false,
      helperText: "Is this field required?",
    },
  ],
});

const ChoiceBoxItem = ({
  value,
  children,
  className,
  "aria-label": ariaLabel,
}: ChoiceBoxItemProps) => {
  return (
    <RadioGroupItem value={value} className={className} aria-label={ariaLabel}>
      {children}
    </RadioGroupItem>
  );
};

Builder.registerComponent(withChildren(ChoiceBoxItem), {
  name: FUNNEL_CHOICE_BOX_ITEM,
  inputs: [
    {
      name: "children",
      friendlyName: "Choice text",
      defaultValue: "Choice",
      type: "string",
      helperText: "Text displayed on the choice box item",
    },
    {
      name: "value",
      type: "string",
      required: true,
      helperText: "Value of the choice box item that will be sent to the API",
    },
  ],
});

export { ChoiceBox, ChoiceBoxItem };

import { ValidationError } from "yup";
import { string } from "yup";

type ValidationProps = {
  errorMessage?: string;
  required?: boolean;
};

export function createValidationFunc({
  required,
  errorMessage,
}: ValidationProps) {
  const schema = required
    ? string().trim().required(errorMessage)
    : string().trim();

  return async (value: unknown) => {
    try {
      await schema.validate(value);
    } catch (validationError) {
      if (validationError instanceof ValidationError) {
        return validationError.errors[0];
      }
    }
  };
}

import React from "react";
import type { RegisterOptions, UseControllerProps } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import type { TextareaProps } from "@marbletech/core";

import { StyledTextarea } from "./Textarea.styled";

export type ControllerProps = Pick<
  UseControllerProps,
  "name" | "shouldUnregister"
>;

export interface TextAreaControllerProps
  extends Omit<Partial<TextareaProps>, "name">,
    ControllerProps {
  registerProps?: Omit<RegisterOptions, "shouldUnregister">;
}

export const TextAreaController = ({
  name,
  onChange,
  registerProps,
  shouldUnregister,
  ...props
}: TextAreaControllerProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = Boolean(errors[name]);
  const errorMessage = errors[name]?.message;

  const { onChange: registerOnChange, ...restRegister } = register(name, {
    shouldUnregister,
    ...registerProps,
  } as { shouldUnregister: boolean });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registerOnChange(event);
    onChange?.(event);
  };

  return (
    <StyledTextarea
      error={hasError}
      errorMessage={errorMessage as string}
      onChange={handleChange}
      {...restRegister}
      {...props}
    />
  );
};

import { format } from "date-fns";
import groupBy from "lodash/groupBy";

import { isValidDate } from "../IntakeScheduler/IntakeScheduler.utils";

/**
 * Groups an array of slots by date.
 * @param slots - An array of slots represented as `{ start: string }`.
 * @returns An object where the keys are the formatted dates and the values are arrays of slots for each date.
 */
export function groupByDate(slots?: { start: string | Date }[]) {
  if (!slots) {
    return {};
  }

  const slotsInClientTimezone = slots
    .map((slot) => {
      return slot ? { start: new Date(slot.start) } : null;
    })
    .filter(
      // TODO - remove the `slot is ...` after upgrading typescript to v5.5
      (slot): slot is { start: Date } => isValidDate(slot?.start),
    );

  return groupBy(slotsInClientTimezone, (slot) => {
    return format(slot.start, "yyyy-MM-dd");
  });
}

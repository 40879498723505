/**
 * Get the time zone abbreviation of the client.
 *
 * @param locale The locale to use for the time zone abbreviation.
 *
 * @example getTimeZoneAbbreviation(); // Output: "EST"
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts
 */
export function getTimeZoneAbbreviation(locale = "en-US") {
  const clientTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Intl.DateTimeFormat(locale, {
    timeZoneName: "short",
    timeZone: clientTimeZone,
  })
    .formatToParts(new Date())
    .find((part) => part.type === "timeZoneName")?.value;
}

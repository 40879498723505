import { format } from "date-fns";
import { groupBy } from "lodash";

/**
 * Checks if a given date is valid.
 *
 * @param date - The date to be checked.
 */
export function isValidDate(date?: Date) {
  return !!date && !isNaN(date.getTime());
}

/**
 * Groups an array of slots by date.
 * @param slots - An array of slots represented as strings.
 * @returns An object where the keys are the formatted dates and the values are arrays of slots for each date.
 */
export function groupByDate(slots: string[]) {
  const slotsInClientTimezone = slots
    .map((slot) => {
      return slot ? { start: new Date(slot) } : null;
    })
    .filter(
      // TODO - remove the `slot is ...` after upgrading typescript to v5.5
      (slot): slot is { start: Date } => isValidDate(slot?.start),
    );

  return groupBy(slotsInClientTimezone, (slot) => {
    return format(slot.start, "yyyy-MM-dd");
  });
}

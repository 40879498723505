import * as o from "react";
import { jsx as y, Fragment as E } from "react/jsx-runtime";
function R(n, t) {
  typeof n == "function" ? n(t) : n != null && (n.current = t);
}
function j(...n) {
  return (t) => n.forEach((e) => R(e, t));
}
var O = o.forwardRef((n, t) => {
  const { children: e, ...r } = n, l = o.Children.toArray(e), i = l.find(A);
  if (i) {
    const a = i.props.children, u = l.map((v) => v === i ? o.Children.count(a) > 1 ? o.Children.only(null) : o.isValidElement(a) ? a.props.children : null : v);
    return /* @__PURE__ */ y(h, { ...r, ref: t, children: o.isValidElement(a) ? o.cloneElement(a, void 0, u) : null });
  }
  return /* @__PURE__ */ y(h, { ...r, ref: t, children: e });
});
O.displayName = "Slot";
var h = o.forwardRef((n, t) => {
  const { children: e, ...r } = n;
  if (o.isValidElement(e)) {
    const l = W(e);
    return o.cloneElement(e, {
      ...P(r, e.props),
      // @ts-ignore
      ref: t ? j(t, l) : l
    });
  }
  return o.Children.count(e) > 1 ? o.Children.only(null) : null;
});
h.displayName = "SlotClone";
var S = ({ children: n }) => /* @__PURE__ */ y(E, { children: n });
function A(n) {
  return o.isValidElement(n) && n.type === S;
}
function P(n, t) {
  const e = { ...t };
  for (const r in t) {
    const l = n[r], i = t[r];
    /^on[A-Z]/.test(r) ? l && i ? e[r] = (...u) => {
      i(...u), l(...u);
    } : l && (e[r] = l) : r === "style" ? e[r] = { ...l, ...i } : r === "className" && (e[r] = [l, i].filter(Boolean).join(" "));
  }
  return { ...n, ...e };
}
function W(n) {
  var r, l;
  let t = (r = Object.getOwnPropertyDescriptor(n.props, "ref")) == null ? void 0 : r.get, e = t && "isReactWarning" in t && t.isReactWarning;
  return e ? n.ref : (t = (l = Object.getOwnPropertyDescriptor(n, "ref")) == null ? void 0 : l.get, e = t && "isReactWarning" in t && t.isReactWarning, e ? n.props.ref : n.props.ref || n.ref);
}
function p(n) {
  var t, e, r = "";
  if (typeof n == "string" || typeof n == "number") r += n;
  else if (typeof n == "object") if (Array.isArray(n)) for (t = 0; t < n.length; t++) n[t] && (e = p(n[t])) && (r && (r += " "), r += e);
  else for (t in n) n[t] && (r && (r += " "), r += t);
  return r;
}
function w() {
  for (var n, t, e = 0, r = ""; e < arguments.length; ) (n = arguments[e++]) && (t = p(n)) && (r && (r += " "), r += t);
  return r;
}
const C = (n) => typeof n == "boolean" ? "".concat(n) : n === 0 ? "0" : n, V = w, k = (n, t) => (e) => {
  var r;
  if ((t == null ? void 0 : t.variants) == null) return V(n, e == null ? void 0 : e.class, e == null ? void 0 : e.className);
  const { variants: l, defaultVariants: i } = t, a = Object.keys(l).map((s) => {
    const c = e == null ? void 0 : e[s], f = i == null ? void 0 : i[s];
    if (c === null) return null;
    const d = C(c) || C(f);
    return l[s][d];
  }), u = e && Object.entries(e).reduce((s, c) => {
    let [f, d] = c;
    return d === void 0 || (s[f] = d), s;
  }, {}), v = t == null || (r = t.compoundVariants) === null || r === void 0 ? void 0 : r.reduce((s, c) => {
    let { class: f, className: d, ...b } = c;
    return Object.entries(b).every((N) => {
      let [g, m] = N;
      return Array.isArray(m) ? m.includes({
        ...i,
        ...u
      }[g]) : {
        ...i,
        ...u
      }[g] === m;
    }) ? [
      ...s,
      f,
      d
    ] : s;
  }, []);
  return V(n, a, v, e == null ? void 0 : e.class, e == null ? void 0 : e.className);
};
export {
  O as S,
  k as c
};

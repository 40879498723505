import type { HTMLInputTypeAttribute } from "react";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/react";
import type { StringSchema } from "yup";
import { string } from "yup";

import { FUNNEL_CHILDREN_INFO } from "@/builder/components-sections";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";

import { PlusIcon } from "../icons/PlusIcon";
import { TrashIcon } from "../icons/TrashIcon";
import { Text } from "../v2/Text/Text";

import { Input } from "./base/Input";

const ordinals = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
];

type FormValues = Pick<FunnelAnswers, "childrenInfo">;

type ChildrenInfoProps = {
  ageValidationErrorMessage?: string;
  nameValidationErrorMessage?: string;
};

type TypeToValidationSchema = Partial<
  Record<HTMLInputTypeAttribute, StringSchema<string>>
>;

type HandleValidationParams = {
  ageValidationErrorMessage?: string;
  inputName: string;
  nameValidationErrorMessage?: string;
  value?: string;
};

async function handleValidation({
  value,
  inputName,
  nameValidationErrorMessage,
  ageValidationErrorMessage,
}: HandleValidationParams): Promise<string | undefined> {
  const typeToValidationSchema = {
    fullName: string().trim().required(nameValidationErrorMessage),
    age: string()
      .trim()
      .matches(/^\d+(\.\d+)?$/, ageValidationErrorMessage)
      .test({
        message: ageValidationErrorMessage,
        test: (value) => {
          return Number(value) < 18;
        },
      })
      .required(ageValidationErrorMessage),
  } satisfies TypeToValidationSchema;

  const schema =
    typeToValidationSchema[inputName as keyof typeof typeToValidationSchema];

  try {
    await schema.validate(value);
  } catch (error) {
    return (error as Error).message;
  }
}

const initialValue = { name: "", age: "" };

export default function ChildrenInfo({
  nameValidationErrorMessage,
  ageValidationErrorMessage,
}: ChildrenInfoProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: funnelInputsNameMap.childrenInfo,
    rules: { minLength: 1, maxLength: 10 },
  });

  const addChildren = () => {
    append(initialValue);
  };

  useEffect(() => {
    if (!fields.length) append(initialValue);
  }, [fields, append]);

  return (
    <>
      <ul className="space-y-8">
        {fields.map((field, index) => {
          const fieldError = errors?.childrenInfo?.[index];

          return (
            <li className="space-y-7" key={field.id}>
              <div className="flex items-center gap-3">
                <Text className="min-w-max" variant="text-4">
                  {ordinals[index]} child
                </Text>
                <div className="h-px w-full rounded-[100px] bg-blue-20"></div>
                {index > 0 && (
                  <button type="button" onClick={() => remove(index)}>
                    <TrashIcon width="24" height="24" />
                  </button>
                )}
              </div>
              <div className="flex flex-col gap-7 md:flex-row md:gap-3">
                <div className="w-full">
                  <Input
                    error={Boolean(fieldError?.name)}
                    hint={fieldError?.name?.message}
                    label="Full name"
                    placeholder="Child's full name"
                    {...register(`childrenInfo.${index}.name` as const, {
                      validate: (value) =>
                        handleValidation({
                          value,
                          inputName: "fullName",
                          nameValidationErrorMessage,
                        }),
                    })}
                  />
                </div>
                <div className="w-full">
                  <Input
                    error={Boolean(fieldError?.age)}
                    hint={fieldError?.age?.message}
                    label="Age"
                    placeholder="Child's current age"
                    {...register(`childrenInfo.${index}.age` as const, {
                      validate: (value) =>
                        handleValidation({
                          value,
                          inputName: "age",
                          ageValidationErrorMessage,
                        }),
                    })}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {fields.length < 10 && (
        <button
          className="mt-9 flex items-center gap-4"
          type="button"
          onClick={addChildren}
        >
          <div className="rounded bg-green-20 px-2 py-1 text-green-100">
            <PlusIcon width="24" height="24" />
          </div>
          <Text color="green" variant="text-2">
            Add another minor child
          </Text>
        </button>
      )}
    </>
  );
}

Builder.registerComponent(ChildrenInfo, {
  name: FUNNEL_CHILDREN_INFO,
  inputs: [
    {
      name: "nameValidationErrorMessage",
      type: "string",
      defaultValue: "Enter your child full name",
      helperText: "Error message to display when name validation fails",
    },
    {
      name: "ageValidationErrorMessage",
      type: "string",
      defaultValue: "Enter your minor child's current age (below 18)",
      helperText: "Error message to display when age validation fails",
    },
  ],
});

import { useEffect, useState } from "react";
import { useBookit, useBookSlot } from "@marbletech/bookit-core";
import { captureException } from "@sentry/nextjs";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import type {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripePaymentRequestButtonElementOptions,
} from "@stripe/stripe-js";
import type { StripeError } from "@stripe/stripe-js";
import { isEqual } from "date-fns";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";
import { useAddPaymentMutation } from "@/generated/graphql.old_backend";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useFunnelMeta } from "@/modules/v2/funnel/hooks/useFunnelMeta";
import { BiEventsNames } from "@/services/analytics/event-names";

import { meetingType } from "../consts";

import type { LssPricesKeys } from "./LssCheckout.consts";
import { CHECKOUT_STATUS, lssPrices } from "./LssCheckout.consts";

const WalletTypes = {
  APPLE: "apple",
  GOOGLE: "google",
} as const;

type WalletType = (typeof WalletTypes)[keyof typeof WalletTypes];

interface WalletPayProps {
  clientSecret: string;
  leadId: string;
  setIsSubmittingPayment: (isSubmitting: boolean) => void;
  triggerExternalFormSubmit: () => void;
}

const style: StripePaymentRequestButtonElementOptions["style"] = {
  paymentRequestButton: {
    type: "default",
    theme: "dark",
    height: "56px",
  },
};

export function WalletPay({
  clientSecret,
  leadId,
  triggerExternalFormSubmit,
  setIsSubmittingPayment,
}: WalletPayProps) {
  const stripe = useStripe();
  const router = useRouter();
  const { funnelAnswers } = useFunnelAnswers();

  const { getFunnelMeta, setFunnelMeta } = useFunnelMeta();
  const { funnelActionId, lssStartTime } = funnelAnswers || {};
  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(
    null,
  );
  const [walletType, setWalletType] = useState<null | WalletType>(null);
  const practice = router.query.practice as string;
  const step = router.query.step as string;
  const [addPaymentMethodMutation] = useAddPaymentMutation();
  const { mutateAsync: bookSlot } = useBookSlot();
  const { meeting } = useBookit();

  useEffect(() => {
    // Initialize the Stripe PaymentRequest API for Wallet payments
    if (stripe) {
      const pr: PaymentRequest = stripe?.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Lss Payment",
          amount: lssPrices[practice.toUpperCase() as LssPricesKeys] * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check if the PaymentRequest API is available on the current device
      pr?.canMakePayment().then((result) => {
        if (result) {
          const { applePay, googlePay } = result;
          if (applePay || googlePay) {
            setWalletType(applePay ? WalletTypes.APPLE : WalletTypes.GOOGLE);
          }
          setPaymentRequest(pr);
        }
      });
    }
  }, [practice, stripe]);

  useEffect(() => {
    if (!stripe || !paymentRequest) {
      console.error("Stripe or paymentRequest is not properly initialized.");
      return;
    }

    if (!lssStartTime) {
      console.error("LSS start time is not available");
      return;
    }

    const handlePayment = async (e: PaymentRequestPaymentMethodEvent) => {
      setIsSubmittingPayment(true);

      analytics.track(BiEventsNames.WebFunnelPaymentClick, {
        value: walletType,
        type: "wallet",
        payment_type: "lss",
        sf_funnel_action_id: funnelActionId,
      });

      const funnelMeta = getFunnelMeta();

      let retrieveSetupIntent;

      // Setting up a payment method with the intent to charge it later
      try {
        retrieveSetupIntent = await stripe.retrieveSetupIntent(clientSecret);

        if (retrieveSetupIntent.error) {
          throw retrieveSetupIntent.error;
        }
      } catch (error) {
        const stripeError = error as StripeError;
        analytics.track(BiEventsNames.WebFunnelPaymentDeclined, {
          current_step_key: step,
          practice,
          ...stripeError,
        });

        captureException(error, {
          extra: {
            action: "retrieveSetupIntent",
          },
        });

        setFunnelMeta({
          ...funnelMeta,
          [CHECKOUT_STATUS]: "payment-declined",
        });

        triggerExternalFormSubmit();

        return;
      }

      const slot = {
        start: new Date(lssStartTime),
      };

      // If the selected slot is already booked for the user, skip the booking
      if (isEqual(slot.start, new Date(meeting?.dateTime || ""))) {
        analytics.track(BiEventsNames.WebCalendarSkip, {
          slot: slot.start,
          meeting_type: meetingType.lss,
          sf_funnel_action_id: funnelActionId,
        });
      } else {
        // Execute bookit submit meeting
        try {
          await bookSlot(slot);

          analytics.track(BiEventsNames.WebCalendarSuccess, {
            slot: slot.start,
            meeting_type: meetingType.lss,
            email_hash: funnelMeta?.hashedEmail,
            phone_hash: funnelMeta?.hashedPhone,
            email_hash_unsalted: funnelMeta?.unsaltedSanitizedEmail,
            phone_hash_unsalted: funnelMeta?.unsaltedSanitizedPhone,
            sf_funnel_action_id: funnelActionId,
          });
        } catch (error) {
          analytics.track(BiEventsNames.WebFunnelError, {
            current_step_key: step,
            error_type: "book_slot",
            error_message:
              error instanceof Error ? error.message : "bookSlot unknown error",
            practice,
          });

          captureException(error, {
            extra: {
              action: "bookSlot",
            },
          });

          setFunnelMeta({
            ...funnelMeta,
            [CHECKOUT_STATUS]: "slot-is-taken",
          });

          e.complete("fail");

          triggerExternalFormSubmit();

          return;
        }
      }

      try {
        // Execute the payment
        await addPaymentMethodMutation({
          variables: {
            input: {
              opportunityId: leadId,
              setupIntentId: retrieveSetupIntent.setupIntent.id,
              paymentMethodId: e.paymentMethod.id,
            },
          },
        });
      } catch (error) {
        analytics.track(BiEventsNames.WebFunnelError, {
          current_step_key: step,
          error_type: "add_payment",
          error_message:
            error instanceof Error ? error.message : "addPayment unknown error",
          practice,
        });

        setFunnelMeta({
          ...funnelMeta,
          [CHECKOUT_STATUS]: "payment-declined",
        });

        e.complete("fail");

        triggerExternalFormSubmit();

        return;
      }

      // Payment successful
      analytics.track(BiEventsNames.WebFunnelPaymentSuccess, {
        payment_type: "lss",
        sf_funnel_action_id: funnelActionId,
      });
      setFunnelMeta({
        ...funnelMeta,
        [CHECKOUT_STATUS]: "lss-confirmation",
      });

      e.complete("success");

      triggerExternalFormSubmit();
    };

    paymentRequest.on("paymentmethod", handlePayment);

    return () => {
      paymentRequest.off("paymentmethod", handlePayment);
    };
  }, [
    stripe,
    paymentRequest,
    clientSecret,
    addPaymentMethodMutation,
    leadId,
    bookSlot,
    funnelActionId,
    lssStartTime,
    triggerExternalFormSubmit,
    getFunnelMeta,
    setFunnelMeta,
    step,
    practice,
    setIsSubmittingPayment,
    walletType,
    meeting?.dateTime,
  ]);

  return (
    paymentRequest && (
      <div className="flex flex-col gap-5">
        <Heading variant="h5" className="font-sans font-medium">
          Express checkout
        </Heading>
        <PaymentRequestButtonElement
          className="overflow-hidden rounded-lg"
          options={{ style, paymentRequest }}
        />
        <div className="flex items-center justify-center gap-2">
          <div className="grow border-t border-blue-80"></div>
          <Text variant="text-5" className="text-blue-80">
            or
          </Text>
          <div className="grow border-t border-blue-80"></div>
        </div>
      </div>
    )
  );
}

import { Textarea } from "@marbletech/core";
import styled from "styled-components";

export const StyledTextarea = styled(Textarea)`
  background-color: #f1f0ef;
  border: none;
  > textarea {
    height: 200px;
  }
`;

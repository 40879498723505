/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: any; output: any; }
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: any; output: any; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any; }
  Upload: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type AccountForAch = {
  __typename?: 'AccountForAch';
  accountId: Scalars['String']['output'];
  current?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  accountNumber?: Maybe<Scalars['String']['output']>;
  amountAvailable: Scalars['Int']['output'];
  amountUsed: Scalars['Int']['output'];
  lender: Scalars['String']['output'];
};

export type AccountsForAchInput = {
  externalId: Scalars['String']['input'];
};

export type AchData = PaymentMethod & {
  __typename?: 'AchData';
  bankName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export enum ActionType {
  Idle = 'IDLE',
  Navigation = 'NAVIGATION'
}

export type ActivateLoanInput = {
  accountId: Scalars['String']['input'];
  loanId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type AddCaseEventInput = {
  arbitraryLocation?: InputMaybe<Scalars['String']['input']>;
  caseId: Scalars['ID']['input'];
  eventSubtype?: InputMaybe<CaseEventSubtype>;
  eventType: CaseEventType;
  externalId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<CaseEventLocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  occursAt: Scalars['DateTime']['input'];
  occursAtTime?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<CaseEventSource>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  virtualLocation?: InputMaybe<Scalars['URL']['input']>;
};

export type AddCaseTaskInput = {
  caseId: Scalars['String']['input'];
  caseStage: StageEnum;
  dueDate: Scalars['Date']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerType: CaseTaskOwnerType;
  status: CaseTaskStatusInput;
  title: Scalars['String']['input'];
};

export type AddDocumentClassificationPayload = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  documentClassificationName: Scalars['String']['input'];
  newCategoryName?: InputMaybe<Scalars['String']['input']>;
};

export type AddPaymentInput = {
  amountDue?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  setupIntentId: Scalars['String']['input'];
  tryChargeLss?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddRequiredDocumentPayload = {
  classificationId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  legalServiceExternalId?: InputMaybe<Scalars['String']['input']>;
};

export type Addendum = PayoutItem & {
  __typename?: 'Addendum';
  amount: Scalars['Float']['output'];
  amountTotal: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  numOfPayments: Scalars['Int']['output'];
  opportunityExternalId: Scalars['String']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type Adjustment = PayoutItem & {
  __typename?: 'Adjustment';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type Admin = {
  __typename?: 'Admin';
  alternativeEmails?: Maybe<Array<Maybe<Scalars['EmailAddress']['output']>>>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  name: Name;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  unreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
};


export type AdminNotificationsArgs = {
  filter: CustomerNotificationInput;
  orderBy?: InputMaybe<NotificationsOrderByInput>;
};

export enum AdminOnboardingStatus {
  Converted = 'CONVERTED',
  Pending = 'PENDING'
}

export type AffirmProviderEventInput = {
  accountFirstName?: InputMaybe<Scalars['String']['input']>;
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  agentPhone?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  checkoutId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  requestedAmount?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type: AffirmType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum AffirmType {
  Cancel = 'cancel',
  CheckoutCreated = 'checkout_created'
}

export enum App {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export enum AppClients {
  MyMarbleApp = 'myMarbleApp'
}

export type AppConfigurations = {
  __typename?: 'AppConfigurations';
  client?: Maybe<Scalars['String']['output']>;
  configurations?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  platform: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AppInstallation = {
  __typename?: 'AppInstallation';
  appBundleId: Scalars['String']['output'];
  appVersion: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deviceBrand: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  deviceModel: Scalars['String']['output'];
  deviceOS: Scalars['String']['output'];
  deviceOSVersion: Scalars['String']['output'];
  deviceType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notificationsAllowed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type AppInstallationInput = {
  appBundleId: Scalars['String']['input'];
  appVersion: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  deviceBrand: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  deviceModel: Scalars['String']['input'];
  deviceOS: Scalars['String']['input'];
  deviceOSVersion: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Attorney = {
  __typename?: 'Attorney';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  attorneyType?: Maybe<AttorneyType>;
  barLicenseExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  barLicenseId?: Maybe<Scalars['String']['output']>;
  barNumber?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cafNumber?: Maybe<Scalars['String']['output']>;
  calendlyEmail?: Maybe<Scalars['EmailAddress']['output']>;
  calendlyLink?: Maybe<Scalars['String']['output']>;
  case?: Maybe<Case>;
  caseCapacity: Scalars['Int']['output'];
  cases?: Maybe<Array<Maybe<Case>>>;
  casesOffLimits?: Maybe<Array<Maybe<CasesOffLimits>>>;
  casesOffLimitsOther?: Maybe<Scalars['String']['output']>;
  conservatorship: Scalars['Boolean']['output'];
  contestedDivorce: Scalars['Boolean']['output'];
  contractLink?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  earning?: Maybe<Earning>;
  earnings: Array<Maybe<Earning>>;
  education?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['EmailAddress']['output'];
  familyCourtNY?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Gender>;
  guardianship: Scalars['Boolean']['output'];
  handlesCases: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['URL']['output']>;
  inHouse: Scalars['Boolean']['output'];
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  juvenileCrime: Scalars['Boolean']['output'];
  languages: Array<Language>;
  licensingJurisdiction?: Maybe<Scalars['String']['output']>;
  litigation: Scalars['Boolean']['output'];
  locations?: Maybe<Scalars['JSON']['output']>;
  lssCase?: Maybe<Case>;
  lssCases?: Maybe<Array<Maybe<Case>>>;
  lssCasesByView?: Maybe<Array<Maybe<Case>>>;
  marbleId: Scalars['String']['output'];
  matrimonialCourtNY?: Maybe<Scalars['Boolean']['output']>;
  name: Name;
  numberOfActiveCases?: Maybe<Scalars['Int']['output']>;
  numberOfAssociates?: Maybe<Scalars['Int']['output']>;
  numberOfLegalSecretaries?: Maybe<Scalars['Int']['output']>;
  numberOfParalegals?: Maybe<Scalars['Int']['output']>;
  pendingEarnings?: Maybe<Earning>;
  performsLSS: Scalars['Boolean']['output'];
  phones: Phones;
  practiceAreas: Array<PracticeArea>;
  prenup: Scalars['Boolean']['output'];
  ptin?: Maybe<Scalars['String']['output']>;
  salesforceId: Scalars['String']['output'];
  shortDeadline: Scalars['Boolean']['output'];
  toggleHandlesCasesAt?: Maybe<Scalars['Date']['output']>;
  togglePerformsLSSAt?: Maybe<Scalars['Date']['output']>;
  totalEarnings: Scalars['Float']['output'];
};


export type AttorneyCaseArgs = {
  id: Scalars['ID']['input'];
};


export type AttorneyEarningArgs = {
  billingDate: Scalars['Date']['input'];
};


export type AttorneyLssCaseArgs = {
  id: Scalars['ID']['input'];
};


export type AttorneyLssCasesByViewArgs = {
  input: LssCasesByViewInput;
};

export enum AttorneyType {
  CaseAttorney = 'CASE_ATTORNEY',
  LegalPartner = 'LEGAL_PARTNER',
  LssAttorney = 'LSS_ATTORNEY',
  ManagingAttorney = 'MANAGING_ATTORNEY'
}

export type AuthorizeTransactionPayload = {
  checkoutId: Scalars['String']['input'];
};

export type Autopay = {
  __typename?: 'Autopay';
  agreementDocumentId: Scalars['String']['output'];
  paymentInstrumentId: Scalars['String']['output'];
};

export type BatchCreatePreAuthLinkResponse = {
  __typename?: 'BatchCreatePreAuthLinkResponse';
  customer?: Maybe<Customer>;
  link?: Maybe<Scalars['String']['output']>;
};

export type BatchDeletePactsafeContractsInput = {
  apiKey: Scalars['String']['input'];
  contractIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type BillingCycle = {
  everyTwoWeeks?: InputMaybe<EveryTwoWeeks>;
  monthly?: InputMaybe<Scalars['Int']['input']>;
  singleTerm?: InputMaybe<Scalars['String']['input']>;
  twiceMonthly?: InputMaybe<TwiceMonthly>;
  type: PaymentFrequency;
  weekly?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingCycleOutput = {
  __typename?: 'BillingCycleOutput';
  everyTwoWeeks?: Maybe<EveryTwoWeeksOutput>;
  monthly?: Maybe<Scalars['Int']['output']>;
  singleTerm?: Maybe<Scalars['String']['output']>;
  twiceMonthly?: Maybe<TwiceMonthlyOutput>;
  type: PaymentFrequency;
  weekly?: Maybe<Scalars['Int']['output']>;
};

export type Bonus = PayoutItem & {
  __typename?: 'Bonus';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type BraintreeAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type BraintreeCurrencyAmount = {
  currency?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BrazeCustomerInput = {
  properties: BrazeProperties;
  userId: Scalars['String']['input'];
};

export type BrazeMigrationInput = {
  pageSize: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type BrazeMigrationResponse = {
  __typename?: 'BrazeMigrationResponse';
  count: Scalars['Int']['output'];
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export enum BrazeNotification {
  BrazeCutomerLogin = 'BRAZE_CUTOMER_LOGIN',
  BrazeDiyWelcomeEvent = 'BRAZE_DIY_WELCOME_EVENT',
  BrazeLpNewCaseNotification = 'BRAZE_LP_NEW_CASE_NOTIFICATION',
  BrazePactsafeCustomerSignatureRequest = 'BRAZE_PACTSAFE_CUSTOMER_SIGNATURE_REQUEST',
  BrazePactsafeCustomerSigned = 'BRAZE_PACTSAFE_CUSTOMER_SIGNED',
  BrazePactsafeLpSignatureRequest = 'BRAZE_PACTSAFE_LP_SIGNATURE_REQUEST',
  BrazePactsafeLpSigned = 'BRAZE_PACTSAFE_LP_SIGNED',
  BrazePactsafeMarbleSignatureRequest = 'BRAZE_PACTSAFE_MARBLE_SIGNATURE_REQUEST',
  BrazePactsafeMarbleSigned = 'BRAZE_PACTSAFE_MARBLE_SIGNED',
  BrazePaymentReminder = 'BRAZE_PAYMENT_REMINDER',
  BrazeVerificationCode = 'BRAZE_VERIFICATION_CODE',
  CaseUpdate = 'CASE_UPDATE',
  CheddarFlareRicWelcome = 'CHEDDAR_FLARE_RIC_WELCOME',
  ClosedWonEvent = 'CLOSED_WON_EVENT',
  CourtDateCreated = 'COURT_DATE_CREATED',
  CourtDateReminder = 'COURT_DATE_REMINDER',
  LssMeetingDoneEvent = 'LSS_MEETING_DONE_EVENT',
  MeetYourLegalTeam = 'MEET_YOUR_LEGAL_TEAM',
  OnboardingReminder = 'ONBOARDING_REMINDER'
}

export type BrazeProperties = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type CalendarAvailability = {
  __typename?: 'CalendarAvailability';
  eventType: Scalars['String']['output'];
  freeSlotsToday?: Maybe<Scalars['Int']['output']>;
  hoursThreshold?: Maybe<Scalars['Int']['output']>;
  isWithinThreshold: Scalars['Boolean']['output'];
  nextSpot: Scalars['String']['output'];
  timeLeft: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CalendlyEvent = {
  eventType: Scalars['String']['input'];
  hoursThreshold?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CallLog = {
  __typename?: 'CallLog';
  caseId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: CallLogCreatedBy;
  /** @deprecated Use utcDate instead */
  date?: Maybe<Scalars['Date']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notes: Scalars['String']['output'];
  outcome: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
  recordingUrl?: Maybe<Scalars['String']['output']>;
  rep: Scalars['String']['output'];
  /** @deprecated Use utcDate instead */
  time?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  touchpoint: TouchpointOptions;
  type: Scalars['String']['output'];
  utcDate: Scalars['DateTime']['output'];
};

export type CallLogCreatedBy = {
  __typename?: 'CallLogCreatedBy';
  adminId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CallLogInput = {
  caseId: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  outcome: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  recordingUrl?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  touchpoint: TouchpointOptions;
  type: Scalars['String']['input'];
  utcDate: Scalars['DateTime']['input'];
};

export enum CallLogSource {
  Os = 'OS'
}

export type CancelAutopayRequest = {
  paymentPlanId: Scalars['String']['input'];
};

export type CancelEventInput = {
  orderId: Scalars['String']['input'];
};

export type CancelLoanApplicationInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type CancelPaymentOnHoldInput = {
  paymentId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CancelScheduledEventInput = {
  eventId: Scalars['String']['input'];
  options?: InputMaybe<CancelScheduledEventsOptions>;
};

export type CancelScheduledEventsInput = {
  eventIds: Array<Scalars['String']['input']>;
  options?: InputMaybe<CancelScheduledEventsOptions>;
};

export type CancelScheduledEventsOptions = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
};

export type CancelScheduledEventsResponse = {
  __typename?: 'CancelScheduledEventsResponse';
  cancelledEvents?: Maybe<Array<Maybe<CancelledEvent>>>;
};

export type CancelledEvent = {
  __typename?: 'CancelledEvent';
  cancelled: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
};

export type CapturePaymentOnHoldInput = {
  paymentId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CaptureTransactionResponse = {
  __typename?: 'CaptureTransactionResponse';
  type?: Maybe<Scalars['String']['output']>;
};

export type Card = PaymentMethod & {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  funding: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  last4: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Case = {
  __typename?: 'Case';
  additionalExternalIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attorneyFee?: Maybe<Scalars['Float']['output']>;
  callLogs?: Maybe<Array<Maybe<CallLog>>>;
  /** @deprecated Not In use anymore, please use caseManagerInfo or caseManagerId instead */
  caseManager?: Maybe<Scalars['String']['output']>;
  caseManagerCalendlyLink?: Maybe<Scalars['String']['output']>;
  caseManagerId?: Maybe<Scalars['String']['output']>;
  caseManagerInfo?: Maybe<Admin>;
  /** @deprecated Not In use anymore, please use caseParalegal or caseParalegalId instead */
  caseParalegal?: Maybe<Scalars['String']['output']>;
  caseParalegalId?: Maybe<Scalars['String']['output']>;
  caseParalegalInfo?: Maybe<Admin>;
  caseReviewMeetingTime?: Maybe<Scalars['String']['output']>;
  changeLogs?: Maybe<Array<Maybe<ChangeLog>>>;
  clientPrice?: Maybe<Scalars['Float']['output']>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  courtInfo?: Maybe<CourtInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  criticalDates?: Maybe<Array<CaseOfferCriticalDate>>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']['output']>;
  dispatchAgent?: Maybe<Admin>;
  documents: Array<Maybe<CaseDocument>>;
  engagementLetterSignUrl?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Maybe<CaseEvent>>>;
  externalCreatedAt?: Maybe<Scalars['Date']['output']>;
  externalId: Scalars['String']['output'];
  formFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  intakeMeeting?: Maybe<IntakeMeeting>;
  isAttorneyCompletedStage: Scalars['Boolean']['output'];
  isIntroductionCallStage?: Maybe<Scalars['Boolean']['output']>;
  isLssAttorneyCompletedSummary?: Maybe<Scalars['Boolean']['output']>;
  isLssRejectedPcNoShow?: Maybe<Scalars['Boolean']['output']>;
  isUrgentDispatch?: Maybe<Scalars['Boolean']['output']>;
  lastCustomerContact?: Maybe<Scalars['Date']['output']>;
  latestLssSummary?: Maybe<LssSummary>;
  legalCircleMeetingTime?: Maybe<Scalars['String']['output']>;
  legalProductIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** @deprecated Replaced by legalServices field */
  legalProducts?: Maybe<Array<Maybe<CaseLegalProduct>>>;
  legalServices?: Maybe<Array<Maybe<CaseLegalService>>>;
  location?: Maybe<CaseLocation>;
  lssAttorney?: Maybe<Attorney>;
  lssAttorneyId?: Maybe<Scalars['String']['output']>;
  lssSummaries?: Maybe<Array<LssSummary>>;
  milestone?: Maybe<CaseMilestone>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<CaseNote>>>;
  opposingParty: OpposingParty;
  optionalServices?: Maybe<Array<Maybe<CaseService>>>;
  practiceArea?: Maybe<PracticeArea>;
  progress?: Maybe<Scalars['Int']['output']>;
  requiredDocuments: Array<Maybe<RequiredDocument>>;
  requiredDocumentsData?: Maybe<CaseRequiredDocumentsData>;
  responsibleAttorney?: Maybe<Attorney>;
  responsibleAttorneyId?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<Stage>;
  stageTasksInfo?: Maybe<CaseTasksInfo>;
  status?: Maybe<CaseStatus>;
  tasks?: Maybe<Array<Maybe<CaseTask>>>;
  type?: Maybe<CaseType>;
  updates?: Maybe<Array<Maybe<CaseUpdate>>>;
  whatIsNext?: Maybe<WhatIsNext>;
};


export type CaseEventsArgs = {
  filter?: InputMaybe<SpecificCaseEventsFilterInput>;
};

export type CaseCostTicketData = {
  __typename?: 'CaseCostTicketData';
  amount?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  typeOfFee?: Maybe<TypeOfFee>;
};

export type CaseDocument = ICaseDocument & {
  __typename?: 'CaseDocument';
  case?: Maybe<Case>;
  category: CaseDocumentCategory;
  classification?: Maybe<DocumentClassification>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  sharingOptions?: Maybe<CaseDocumentSharingOptions>;
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
};

export enum CaseDocumentCategory {
  AutoFilled = 'AUTO_FILLED',
  Classified = 'CLASSIFIED',
  EngagementLetter = 'ENGAGEMENT_LETTER',
  Evidence = 'EVIDENCE',
  IntakeForm = 'INTAKE_FORM',
  LegalDoc = 'LEGAL_DOC',
  LpPack = 'LP_PACK'
}

export type CaseDocumentFilterInput = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<CaseDocumentCategory>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  status?: InputMaybe<CaseDocumentStatus>;
};

export type CaseDocumentForm = ICaseDocument & {
  __typename?: 'CaseDocumentForm';
  case?: Maybe<Case>;
  classification?: Maybe<DocumentClassification>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  fields?: Maybe<Array<Maybe<CaseDocumentFormField>>>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sharingOptions?: Maybe<CaseDocumentSharingOptions>;
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
  template: FormTemplate;
  templateId?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
};

export type CaseDocumentFormField = IFormTemplateField & {
  __typename?: 'CaseDocumentFormField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CaseDocumentFormFieldsUpdateInput = {
  name: Scalars['String']['input'];
  type?: InputMaybe<FormTemplateFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CaseDocumentInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<CaseDocumentStatus>;
};

export type CaseDocumentSharingOptions = {
  __typename?: 'CaseDocumentSharingOptions';
  attorney: Scalars['Boolean']['output'];
  client: Scalars['Boolean']['output'];
};

export enum CaseDocumentSource {
  Customer = 'CUSTOMER',
  Os = 'OS'
}

export enum CaseDocumentStatus {
  Archived = 'ARCHIVED',
  New = 'NEW',
  ReadyForCase = 'READY_FOR_CASE',
  ReadyToSign = 'READY_TO_SIGN',
  RecycleBin = 'RECYCLE_BIN',
  Restored = 'RESTORED',
  UploadedToClio = 'UPLOADED_TO_CLIO',
  WaitForAResponseClient = 'WAIT_FOR_A_RESPONSE_CLIENT',
  WaitForAResponseLp = 'WAIT_FOR_A_RESPONSE_LP',
  WaitForAResponsePl = 'WAIT_FOR_A_RESPONSE_PL'
}

export enum CaseDocumentType {
  File = 'FILE',
  Form = 'FORM'
}

export type CaseEvent = {
  __typename?: 'CaseEvent';
  arbitraryLocation?: Maybe<Scalars['String']['output']>;
  case?: Maybe<Case>;
  caseId: Scalars['ID']['output'];
  clioId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<CaseEventCreatedBy>;
  eventSubtype?: Maybe<CaseEventSubtype>;
  eventType: CaseEventType;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  location?: Maybe<CaseEventLocation>;
  notes?: Maybe<Scalars['String']['output']>;
  occursAt: Scalars['Date']['output'];
  occursAtTime?: Maybe<Scalars['Time']['output']>;
  source: CaseEventSource;
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  virtualLocation?: Maybe<Scalars['URL']['output']>;
};

export type CaseEventCreatedBy = {
  __typename?: 'CaseEventCreatedBy';
  adminId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CaseEventLocation = {
  __typename?: 'CaseEventLocation';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fullAddress: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type CaseEventLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fullAddress: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export enum CaseEventSource {
  Clio = 'CLIO',
  Marble = 'MARBLE',
  Salesforce = 'SALESFORCE'
}

export enum CaseEventSubtype {
  Arraignment = 'ARRAIGNMENT',
  BenchWarrantRecall = 'BENCH_WARRANT_RECALL',
  CaseStatusConf = 'CASE_STATUS_CONF',
  DiscoveryHearing = 'DISCOVERY_HEARING',
  Hearing = 'HEARING',
  Mediation = 'MEDIATION',
  Other = 'OTHER',
  Plea = 'PLEA',
  PleaseNegotiation = 'PLEASE_NEGOTIATION',
  Prelim = 'PRELIM',
  PrePrelim = 'PRE_PRELIM',
  PreTrial = 'PRE_TRIAL',
  Proof = 'PROOF',
  SecondArraignment = 'SECOND_ARRAIGNMENT',
  Sentencing = 'SENTENCING',
  SettlementConf = 'SETTLEMENT_CONF',
  Trial = 'TRIAL',
  TrialSettingConf = 'TRIAL_SETTING_CONF',
  WarrantRecall = 'WARRANT_RECALL',
  WithClient = 'WITH_CLIENT',
  WithOpposingCounsel = 'WITH_OPPOSING_COUNSEL',
  WithOpposingParty = 'WITH_OPPOSING_PARTY'
}

export enum CaseEventType {
  /** @deprecated Not in use */
  Action = 'ACTION',
  CourtDate = 'COURT_DATE',
  Deadline = 'DEADLINE',
  Meeting = 'MEETING',
  /** @deprecated Not in use */
  Other = 'OTHER',
  /** @deprecated Not in use */
  Petitioner = 'PETITIONER',
  /** @deprecated Not in use */
  Response = 'RESPONSE'
}

export type CaseEventsFilterInput = {
  caseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<CaseEventType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CaseFilters = {
  __typename?: 'CaseFilters';
  legalServicesFilters?: Maybe<Array<Maybe<LegalServiceSection>>>;
  lpFilters?: Maybe<Array<Maybe<FilterVal>>>;
  managersFilters?: Maybe<Array<Maybe<FilterVal>>>;
  milestoneFilters?: Maybe<Array<Maybe<CaseMilestone>>>;
  paralegalsFilters?: Maybe<Array<Maybe<FilterVal>>>;
  scopeFilters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CaseLegalProduct = {
  __typename?: 'CaseLegalProduct';
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type CaseLegalService = {
  __typename?: 'CaseLegalService';
  createdAt?: Maybe<Scalars['Date']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downgrade?: Maybe<Scalars['Boolean']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  legalService?: Maybe<LegalService>;
  legalServiceExternalId: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type CaseLocation = {
  __typename?: 'CaseLocation';
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CaseMeeting = {
  __typename?: 'CaseMeeting';
  body?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CaseMilestone {
  CaseDelivery = 'CASE_DELIVERY',
  IntroductionCall = 'INTRODUCTION_CALL',
  NextScope = 'NEXT_SCOPE',
  Onboarding = 'ONBOARDING',
  ScopeCompletion = 'SCOPE_COMPLETION'
}

export type CaseNote = {
  __typename?: 'CaseNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CaseNoteInput = {
  caseId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CaseOfferCriticalDate = {
  __typename?: 'CaseOfferCriticalDate';
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: CaseOfferCriticalDateType;
};

export enum CaseOfferCriticalDateType {
  Action = 'ACTION',
  CourtDate = 'COURT_DATE',
  CriticalDate = 'CRITICAL_DATE',
  Deadline = 'DEADLINE',
  Meeting = 'MEETING',
  Other = 'OTHER',
  Petitioner = 'PETITIONER',
  Response = 'RESPONSE',
  ResponseDue = 'RESPONSE_DUE'
}

export type CaseRequiredDocumentsData = {
  __typename?: 'CaseRequiredDocumentsData';
  ignoredIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  requiredIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type CaseService = {
  __typename?: 'CaseService';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CaseStatus {
  AwaitingReview = 'AWAITING_REVIEW',
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Closed = 'CLOSED',
  Dispatch = 'DISPATCH',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN'
}

export type CaseSubentityFailedObject = {
  __typename?: 'CaseSubentityFailedObject';
  error: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CaseSubentityMigratedObject = {
  __typename?: 'CaseSubentityMigratedObject';
  flareId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CaseSubentityMigrationResponse = {
  __typename?: 'CaseSubentityMigrationResponse';
  failed: Array<Maybe<CaseSubentityFailedObject>>;
  migrated: Array<Maybe<CaseSubentityMigratedObject>>;
  skipped: Array<Maybe<Scalars['String']['output']>>;
  total: Scalars['Int']['output'];
};

export type CaseTask = {
  __typename?: 'CaseTask';
  caseId: Scalars['String']['output'];
  caseStage: StageEnum;
  dueDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerType: CaseTaskOwnerType;
  status: CaseTaskStatus;
  title: Scalars['String']['output'];
};

export enum CaseTaskOwnerType {
  CaseManager = 'CASE_MANAGER',
  Client = 'CLIENT',
  LegalPartner = 'LEGAL_PARTNER',
  Paralegal = 'PARALEGAL'
}

export type CaseTaskStatus = {
  __typename?: 'CaseTaskStatus';
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: CaseTaskStatusValue;
};

export type CaseTaskStatusInput = {
  value: CaseTaskStatusValue;
};

export enum CaseTaskStatusValue {
  Completed = 'COMPLETED',
  ToDo = 'TO_DO'
}

export type CaseTasksInfo = {
  __typename?: 'CaseTasksInfo';
  completed: Scalars['Int']['output'];
  currentTask?: Maybe<CaseTask>;
  total: Scalars['Int']['output'];
};

export enum CaseType {
  ExistingBusiness = 'EXISTING_BUSINESS',
  NewBusiness = 'NEW_BUSINESS'
}

export type CaseUpdate = {
  __typename?: 'CaseUpdate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isUrgent?: Maybe<Scalars['Boolean']['output']>;
  occursAt?: Maybe<Scalars['DateTime']['output']>;
  sharingOptions?: Maybe<CaseUpdateSharingOptions>;
  source: CaseUpdateSources;
  title?: Maybe<Scalars['String']['output']>;
  userRead?: Maybe<Scalars['DateTime']['output']>;
};

export type CaseUpdateInput = {
  caseId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  sharingOptions?: InputMaybe<CaseUpdateSharingOptionsInput>;
  source: CaseUpdateSources;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title: Scalars['String']['input'];
};

export type CaseUpdateInputWithTags = {
  caseId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  sharingOptions?: InputMaybe<CaseUpdateSharingOptionsInput>;
  source: CaseUpdateSources;
  tagTitles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CaseUpdateSharingOptions = {
  __typename?: 'CaseUpdateSharingOptions';
  attorney: Scalars['Boolean']['output'];
  client: Scalars['Boolean']['output'];
};

export type CaseUpdateSharingOptionsInput = {
  attorney: Scalars['Boolean']['input'];
  client: Scalars['Boolean']['input'];
};

export enum CaseUpdateSources {
  Cem = 'CEM',
  CemPayments = 'CEM_PAYMENTS',
  Clio = 'CLIO',
  Lp = 'LP',
  Salesforce = 'SALESFORCE'
}

export type CasesFilterInput = {
  flareCaseId?: InputMaybe<Scalars['String']['input']>;
  hasNewDocs?: InputMaybe<Scalars['Boolean']['input']>;
  legalServices?: InputMaybe<Array<InputMaybe<LegalServiceInput>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  managers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  milestones?: InputMaybe<Array<InputMaybe<CaseMilestone>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paralegals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  status?: InputMaybe<CaseStatus>;
};

export enum CasesOffLimits {
  CasesWithDrugs = 'CASES_WITH_DRUGS',
  CasesWithViolence = 'CASES_WITH_VIOLENCE',
  Divorce = 'DIVORCE',
  RestrainingOrders = 'RESTRAINING_ORDERS'
}

export type CasesResults = {
  __typename?: 'CasesResults';
  cases?: Maybe<Array<Maybe<Case>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type ChangeLog = {
  __typename?: 'ChangeLog';
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  entityType: ChangeLogEntityTypes;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<Admin>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum ChangeLogEntityTypes {
  Case = 'CASE'
}

export type CheckCalendarsAvailabilityResponse = {
  __typename?: 'CheckCalendarsAvailabilityResponse';
  failed: Array<Maybe<Scalars['String']['output']>>;
  succeeded: Array<Maybe<CalendarAvailability>>;
};

export type Clawback = PayoutItem & {
  __typename?: 'Clawback';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type ClosedCase = {
  __typename?: 'ClosedCase';
  driveCreationTime?: Maybe<Scalars['Date']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
};

export type CodeVerificationForEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  userType: UserType;
};

export type CodeVerificationInput = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  userType: UserType;
};

export type CodeVerificationResponse = {
  __typename?: 'CodeVerificationResponse';
  attempts?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CollectOverdueBalanceInput = {
  accountHash: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type ContactUsInput = {
  email: Scalars['EmailAddress']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['PhoneNumber']['input'];
  request?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
};

export enum ContractType {
  MarbleAgreement = 'MARBLE_AGREEMENT',
  PaymentAgreement = 'PAYMENT_AGREEMENT'
}

export type ConvertPendingToPaidInput = {
  all: Scalars['Boolean']['input'];
  externalAttorneyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CourtInfo = {
  __typename?: 'CourtInfo';
  caseNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CoveredCarePrequalData = {
  __typename?: 'CoveredCarePrequalData';
  applicationStatus?: Maybe<Scalars['String']['output']>;
  incompletePrequal?: Maybe<Scalars['Boolean']['output']>;
  offers?: Maybe<Array<Maybe<CoveredCarePrequalOffer>>>;
  startUrl?: Maybe<Scalars['String']['output']>;
  takenLoanAmount?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CoveredCarePrequalLink = {
  __typename?: 'CoveredCarePrequalLink';
  url: Scalars['String']['output'];
};

export type CoveredCarePrequalOffer = {
  __typename?: 'CoveredCarePrequalOffer';
  LoanGradeDescription?: Maybe<Scalars['String']['output']>;
  downPayment: Scalars['Float']['output'];
  downPaymentMinPct: Scalars['Float']['output'];
  downPaymentResult?: Maybe<Scalars['String']['output']>;
  estMonthlyPaymentAmt: Scalars['Float']['output'];
  expireDate: Scalars['String']['output'];
  loanAmount: Scalars['Float']['output'];
  loanInterest: Scalars['Float']['output'];
  marketingMessage?: Maybe<Scalars['String']['output']>;
  matrixGUID: Scalars['String']['output'];
  maxDownPaymentAmt: Scalars['Float']['output'];
  minDownPaymentAmt: Scalars['Float']['output'];
  minPaymentAmt: Scalars['Float']['output'];
  offerGUID: Scalars['String']['output'];
  offerOrder: Scalars['Int']['output'];
  startURL: Scalars['String']['output'];
  term: Scalars['Int']['output'];
};

export type CoveredCarePrequalResult = {
  __typename?: 'CoveredCarePrequalResult';
  status: Scalars['Boolean']['output'];
};

export type CreateAchInput = {
  accountId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
};

export type CreateAdminInput = {
  alternativeEmails?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  email: Scalars['EmailAddress']['input'];
  name: NameInput;
  password: Scalars['String']['input'];
  repassword: Scalars['String']['input'];
  teams?: InputMaybe<Array<InputMaybe<Team>>>;
};

export type CreateAttorneyCodeVerificationResponse = {
  __typename?: 'CreateAttorneyCodeVerificationResponse';
  countdown: Scalars['BigInt']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateAttorneyInput = {
  address?: InputMaybe<AddressInput>;
  attorneyType?: InputMaybe<AttorneyType>;
  barLicenseExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  barLicenseId?: InputMaybe<Scalars['String']['input']>;
  barNumber?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  cafNumber?: InputMaybe<Scalars['String']['input']>;
  calendlyEmail: Scalars['EmailAddress']['input'];
  calendlyLink?: InputMaybe<Scalars['String']['input']>;
  caseCapacity?: InputMaybe<Scalars['Int']['input']>;
  casesOffLimits?: InputMaybe<Array<InputMaybe<CasesOffLimits>>>;
  casesOffLimitsOther?: InputMaybe<Scalars['String']['input']>;
  conservatorship?: InputMaybe<Scalars['Boolean']['input']>;
  contestedDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  contractLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['EmailAddress']['input'];
  familyCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  guardianship?: InputMaybe<Scalars['Boolean']['input']>;
  handlesCases: Scalars['Boolean']['input'];
  inHouse: Scalars['Boolean']['input'];
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  juvenileCrime?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Language>;
  licensingJurisdiction?: InputMaybe<Scalars['String']['input']>;
  litigation?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Scalars['JSON']['input']>;
  marbleId?: InputMaybe<Scalars['String']['input']>;
  matrimonialCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  name: NameInput;
  numberOfActiveCases?: InputMaybe<Scalars['Int']['input']>;
  numberOfAssociates?: InputMaybe<Scalars['Int']['input']>;
  numberOfLegalSecretaries?: InputMaybe<Scalars['Int']['input']>;
  numberOfParalegals?: InputMaybe<Scalars['Int']['input']>;
  performsLSS: Scalars['Boolean']['input'];
  phones: PhonesInput;
  practiceAreas: Array<PracticeArea>;
  prenup?: InputMaybe<Scalars['Boolean']['input']>;
  ptin?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  shortDeadline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCaseDocumentFormInput = {
  caseId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type CreateCodeVerificationResponse = {
  __typename?: 'CreateCodeVerificationResponse';
  countdown: Scalars['BigInt']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateCustomerInput = {
  email: Scalars['EmailAddress']['input'];
  name: NameInput;
  password: Scalars['String']['input'];
  repassword: Scalars['String']['input'];
};

export type CreateFilingFeePaymentPlanComponentInput = {
  amount: Scalars['String']['input'];
  caseId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type CreateLegalProductInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLinkTokenInput = {
  externalId: Scalars['String']['input'];
};

export type CreatePayInFullPaymentPlanComponentInput = {
  amount: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type CreatePaymentOnHoldInput = {
  amountInDollars: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CreatePaymentPlanComponentInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  achPayment?: InputMaybe<Scalars['Boolean']['input']>;
  amountInDollars: Scalars['String']['input'];
  autopayAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  billingCycle?: InputMaybe<BillingCycle>;
  billingDate: Scalars['Date']['input'];
  downPayments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  financeCompany?: InputMaybe<Scalars['String']['input']>;
  installments?: InputMaybe<Scalars['Int']['input']>;
  last4DigitsForReporting?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentPlanProvider: PaymentPlanProvider;
  reportButDoNotCharge?: InputMaybe<Scalars['Boolean']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  stripePaymentIntentId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type: PaymentPlanSetupPaymentPlanType;
  updateSalesforce?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePayoutItemBulkInput = {
  payouts: Array<CreatePayoutItemInput>;
};

export type CreatePayoutItemBulkResponse = {
  __typename?: 'CreatePayoutItemBulkResponse';
  responses: Array<CreatePayoutItemResponse>;
};

export type CreatePayoutItemError = {
  __typename?: 'CreatePayoutItemError';
  message: Scalars['String']['output'];
};

export type CreatePayoutItemInput = {
  amount: Scalars['Float']['input'];
  amountTotal?: InputMaybe<Scalars['Float']['input']>;
  billingDate: Scalars['Date']['input'];
  externalAttorneyId?: InputMaybe<Scalars['String']['input']>;
  externalCaseId?: InputMaybe<Scalars['String']['input']>;
  paymentNumber: Scalars['Int']['input'];
  pending?: InputMaybe<Scalars['Boolean']['input']>;
  type: PayoutItemType;
};

export type CreatePayoutItemResponse = {
  __typename?: 'CreatePayoutItemResponse';
  data?: Maybe<PayoutItem>;
  errors?: Maybe<Array<CreatePayoutItemError>>;
};

export type CreateRejectedCustomerInput = {
  customerRejectionType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  practiceArea?: InputMaybe<PracticeArea>;
};

export type CreateShortLivedTokenOutput = {
  __typename?: 'CreateShortLivedTokenOutput';
  token?: Maybe<Scalars['String']['output']>;
};

export type CreateThirdPartyUserInput = {
  address: UwAddressInput;
  dateOfBirth: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber: Scalars['String']['input'];
  payingForOpportunityId: Scalars['String']['input'];
  payingForOtherByExternalId: Scalars['String']['input'];
};

export type CreateWebLeadInput = {
  AdditionalQuestion__c?: InputMaybe<Scalars['String']['input']>;
  Concurrence__c?: InputMaybe<Scalars['Boolean']['input']>;
  County__c?: InputMaybe<Scalars['String']['input']>;
  Current_Situation__c?: InputMaybe<Scalars['String']['input']>;
  Email__c?: InputMaybe<Scalars['String']['input']>;
  First_name__c?: InputMaybe<Scalars['String']['input']>;
  Funnel_Parameters__c?: InputMaybe<Scalars['String']['input']>;
  Last_name__c?: InputMaybe<Scalars['String']['input']>;
  Phone__c?: InputMaybe<Scalars['String']['input']>;
  Postal_code__c?: InputMaybe<Scalars['String']['input']>;
  Practice__c?: InputMaybe<Scalars['String']['input']>;
  State__c?: InputMaybe<Scalars['String']['input']>;
  Test_Group__c?: InputMaybe<Scalars['String']['input']>;
  Test_Name__c?: InputMaybe<Scalars['String']['input']>;
  WhatMatterRelated__c?: InputMaybe<Scalars['String']['input']>;
  affilate_id__c?: InputMaybe<Scalars['String']['input']>;
  anon_id__c?: InputMaybe<Scalars['String']['input']>;
  fbclid__c?: InputMaybe<Scalars['String']['input']>;
  gclid__c?: InputMaybe<Scalars['String']['input']>;
  match_location__c?: InputMaybe<Scalars['String']['input']>;
  match_type__c?: InputMaybe<Scalars['String']['input']>;
  url_refferal__c?: InputMaybe<Scalars['String']['input']>;
  utm_campaign__c?: InputMaybe<Scalars['String']['input']>;
  utm_content__c?: InputMaybe<Scalars['String']['input']>;
  utm_medium__c?: InputMaybe<Scalars['String']['input']>;
  utm_search_network__c?: InputMaybe<Scalars['String']['input']>;
  utm_source__c?: InputMaybe<Scalars['String']['input']>;
  utm_sub_campaign__c?: InputMaybe<Scalars['String']['input']>;
  utm_term__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWisetackLoanLinkInput = {
  purchaseId: Scalars['String']['input'];
  transactionAmount: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type CreditCardPaymentInput = {
  cardNumber: Scalars['String']['input'];
  cvc: Scalars['String']['input'];
  expirationDate: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCheckAddressInput = {
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export enum CreditCheckErrorCode {
  StateCodeLength = 'STATE_CODE_LENGTH',
  Unknown = 'UNKNOWN',
  ZipCodeLength = 'ZIP_CODE_LENGTH'
}

export type CreditFinancingOffered = {
  cartAmountImmutable?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyPayment?: InputMaybe<BraintreeCurrencyAmount>;
  payerAcceptance?: InputMaybe<Scalars['Boolean']['input']>;
  term?: InputMaybe<Scalars['Float']['input']>;
  totalCost?: InputMaybe<BraintreeCurrencyAmount>;
  totalInterest?: InputMaybe<BraintreeCurrencyAmount>;
};

export type Customer = {
  __typename?: 'Customer';
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerState?: Maybe<CustomerState>;
  diyFunnelData?: Maybe<DiyFunnelData>;
  email?: Maybe<Scalars['String']['output']>;
  externalCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  formData?: Maybe<FormData>;
  id: Scalars['ID']['output'];
  marbleId?: Maybe<Scalars['String']['output']>;
  name: Name;
  payment?: Maybe<Payment>;
  paymentAccount?: Maybe<PaymentAccount>;
  phone?: Maybe<Scalars['String']['output']>;
  stripeData?: Maybe<StripeData>;
  voucher?: Maybe<VoucherDiscount>;
};

export type CustomerDetails = {
  address?: InputMaybe<PaymentCustomerAddress>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFinancingSummary = {
  __typename?: 'CustomerFinancingSummary';
  amountToFinance: Scalars['Int']['output'];
  downPaymentsPaidAmount: Scalars['Int']['output'];
  paidLssAmount: Scalars['Int']['output'];
  thirdPartyFinancedAmount: Scalars['Int']['output'];
  totalBalance: Scalars['Int']['output'];
  totalDiscount: Scalars['Int']['output'];
  totalPaid: Scalars['Int']['output'];
};

export type CustomerInput = {
  email: Scalars['String']['input'];
  name: NameInput;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerLeadInput = {
  email: Scalars['String']['input'];
  hashVersion?: InputMaybe<Scalars['String']['input']>;
  marbleUserId?: InputMaybe<Scalars['String']['input']>;
  name: NameInput;
  phone: Scalars['String']['input'];
};

export enum CustomerLoginType {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type CustomerNotificationInput = {
  excludeArrived?: InputMaybe<Scalars['Boolean']['input']>;
  excludeOpened?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSent?: InputMaybe<Scalars['Boolean']['input']>;
  excludeViewed?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerState = {
  __typename?: 'CustomerState';
  blockAllCommunications?: Maybe<Scalars['Boolean']['output']>;
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  firstEmailSent?: Maybe<Scalars['Boolean']['output']>;
  postBookingShown?: Maybe<Scalars['Boolean']['output']>;
  submittedForms?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerStripeData = {
  __typename?: 'CustomerStripeData';
  stripeCustomerId: Scalars['String']['output'];
  stripeCustomerSecretKey: Scalars['String']['output'];
};

export type CustomerUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<NameInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteDownPaymentInput = {
  componentId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type DeletePactsafeContractInput = {
  apiKey: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type DeleteThirdPartyPaymentInput = {
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type Details = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  creditFinancingOffered?: InputMaybe<CreditFinancingOffered>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<BraintreeAddress>;
};

export type DevVerificationCodeInput = {
  phone: Scalars['String']['input'];
  userType: UserType;
};

export type DiyFunnelData = {
  __typename?: 'DiyFunnelData';
  createdAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isAfterOnboarding?: Maybe<Scalars['Boolean']['output']>;
  isAlreadyViewed?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<DiyFunnelLocation>;
  selectedService?: Maybe<Scalars['String']['output']>;
  stepsData?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DiyFunnelLocation = {
  __typename?: 'DiyFunnelLocation';
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type DocumentClassification = {
  __typename?: 'DocumentClassification';
  category?: Maybe<DocumentClassificationCategory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentClassificationCategory = {
  __typename?: 'DocumentClassificationCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentClassificationsWithCategory = {
  __typename?: 'DocumentClassificationsWithCategory';
  category: DocumentClassificationCategory;
  classifications?: Maybe<Array<Maybe<DocumentClassification>>>;
  topClassifications?: Maybe<Array<Maybe<DocumentClassification>>>;
};

export type DownPayment = {
  __typename?: 'DownPayment';
  percentage: Scalars['Float']['output'];
};

export type DownPaymentsPaidAmount = {
  __typename?: 'DownPaymentsPaidAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export type DriveDocument = {
  __typename?: 'DriveDocument';
  documentId: Scalars['ID']['output'];
  driveCreationTime?: Maybe<Scalars['Date']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
};

export type ElOverrides = {
  lpAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  marbleAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  redispatchAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  redispatchPayoutAmount?: InputMaybe<Scalars['Float']['input']>;
  redispatchServiceIds?: InputMaybe<Scalars['String']['input']>;
};

export enum ElTenant {
  Kindred = 'KINDRED',
  Marble = 'MARBLE'
}

export enum ElType {
  AdditionalServicesEl = 'ADDITIONAL_SERVICES_EL',
  El = 'EL',
  Pa = 'PA',
  RedispatchEl = 'REDISPATCH_EL',
  TaxPoa = 'TAX_POA',
  X3PartyPayor = 'X3_PARTY_PAYOR'
}

export type Earning = {
  __typename?: 'Earning';
  amount: Scalars['Float']['output'];
  billingDate: Scalars['Date']['output'];
  caseCount: Scalars['Int']['output'];
  payoutItems: Array<PayoutItem>;
};

export type EditRecurringPaymentInput = {
  accountId: Scalars['String']['input'];
  amountInDollars: Scalars['String']['input'];
  billingDate: Scalars['Date']['input'];
  installments: Scalars['Int']['input'];
  newOpportunityId: Scalars['String']['input'];
  oldComponentId: Scalars['String']['input'];
  oldOpportunityId: Scalars['String']['input'];
  oldSubscriptionId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type EditThirdPartyPaymentInput = {
  amountInDollars: Scalars['String']['input'];
  billingDate: Scalars['Date']['input'];
  financeCompany?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type EnableAutopayRequest = {
  paymentMethodId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
};

export type EndRecurringPaymentInput = {
  componentId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentPlanProvider?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export enum Environments {
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export type EveryTwoWeeks = {
  date?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
};

export type EveryTwoWeeksOutput = {
  __typename?: 'EveryTwoWeeksOutput';
  date?: Maybe<Scalars['String']['output']>;
  day: Scalars['Int']['output'];
};

export type ExchangePublicTokenInput = {
  externalId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Float']['output']>;
  paymentDate?: Maybe<Scalars['String']['output']>;
};

export type FetchTransactionDataForCustomerInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type FilterVal = {
  __typename?: 'FilterVal';
  id: Scalars['String']['output'];
  name: Name;
};

export type FinancingOption = {
  __typename?: 'FinancingOption';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<DownPayment>;
  financingTermsOptions?: Maybe<Array<FinancingTerms>>;
  installment?: Maybe<Scalars['Float']['output']>;
  provider: FinancingOptionProvider;
  status: Scalars['String']['output'];
};

export enum FinancingOptionProvider {
  Affirm = 'AFFIRM',
  Covered = 'COVERED',
  Genesis = 'GENESIS',
  InHouse = 'IN_HOUSE',
  InHouseViola = 'IN_HOUSE_VIOLA',
  Klarna = 'KLARNA',
  None = 'NONE',
  Paypal = 'PAYPAL',
  Wisetack = 'WISETACK'
}

export type FinancingTerms = {
  __typename?: 'FinancingTerms';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<DownPayment>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installment?: Maybe<Scalars['Float']['output']>;
  interestRate?: Maybe<Scalars['Float']['output']>;
};

export enum FinancingType {
  Pay = 'PAY',
  Ric = 'RIC'
}

export type Form = {
  __typename?: 'Form';
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<FormField>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type FormData = {
  __typename?: 'FormData';
  formData?: Maybe<Scalars['JSON']['output']>;
};

export type FormDataInput = {
  formData?: InputMaybe<Scalars['JSON']['input']>;
};

export type FormField = {
  __typename?: 'FormField';
  name?: Maybe<Scalars['String']['output']>;
};

export type FormFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FormInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<FormFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  county?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<FormTemplateField>>>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<PracticeArea>;
  state?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FormTemplateField = IFormTemplateField & {
  __typename?: 'FormTemplateField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FormTemplateFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type?: InputMaybe<FormTemplateFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FormTemplateFieldType {
  Btn = 'Btn',
  Sig = 'Sig',
  Tx = 'Tx'
}

export type FormTemplatesFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  practices?: InputMaybe<Array<InputMaybe<PracticeArea>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FreeTaxGeneratorPayload = {
  address: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  irsFormName: Scalars['String']['input'];
  reasoning?: InputMaybe<Scalars['String']['input']>;
  taxPeriod: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenerateElInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  elType?: InputMaybe<Array<InputMaybe<ElType>>>;
  opportunityId: Scalars['String']['input'];
  overrides?: InputMaybe<ElOverrides>;
  payInFull?: InputMaybe<Scalars['Boolean']['input']>;
  selfServe?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<ElTenant>;
};

export type GenerateLeadInput = {
  category: Scalars['String']['input'];
  customer: CustomerLeadInput;
  entryUrl: Scalars['String']['input'];
  funnelQuestions: Scalars['JSON']['input'];
  meetingDetails?: InputMaybe<MeetingDetailsLeadInput>;
  practiceArea: PracticeArea;
  sessionPageType?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  utm?: InputMaybe<UtmInput>;
};

export type GenerateLoanApplicationLinkInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  transactionPurpose: Scalars['String']['input'];
};

export type GeneratePaymentAgreementResponse = {
  __typename?: 'GeneratePaymentAgreementResponse';
  contractURL: Scalars['String']['output'];
};

export type GeneratePrequalLinkInput = {
  accountId: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  transactionPurpose: Scalars['String']['input'];
};

export type GenesisLoanAccount = {
  __typename?: 'GenesisLoanAccount';
  accountNumber: Scalars['String']['output'];
  availableCredit: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type GenesisLoanApplication = {
  __typename?: 'GenesisLoanApplication';
  approvedAmount: Scalars['Int']['output'];
  bank: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type GenesisLoanTerms = {
  __typename?: 'GenesisLoanTerms';
  base64Html: Scalars['String']['output'];
};

export type GenesisPrequalApplication = {
  __typename?: 'GenesisPrequalApplication';
  approvedAmount: Scalars['Int']['output'];
  bank: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  requestedAmount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type GenesisStartPrequalInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature?: InputMaybe<Scalars['String']['input']>;
};

export type GetAppConfigurationsInput = {
  platform: PlatformConfigurationsType;
};

export type GetAppPrivateConfigurationsInput = {
  client: AppClients;
  platform: PlatformConfigurationsType;
};

export type GetAppPublicConfigurationsInput = {
  client: AppClients;
  platform: PlatformConfigurationsType;
};

export type GetCalendarsAvailabilityByUrlOptions = {
  hoursThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCalendarsAvailabilityInput = {
  events: Array<InputMaybe<CalendlyEvent>>;
};

export type GetCustomerRiskLevelInput = {
  userId: Scalars['String']['input'];
};

export type GetCustomerRiskLevelResult = {
  __typename?: 'GetCustomerRiskLevelResult';
  riskLevel: Scalars['String']['output'];
};

export type GetLegalServicesOptions = {
  filterByText?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  serviceType: LegalServiceType;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<LegalServicesSortOptions>;
};

export type GetLegalServicesResult = {
  __typename?: 'GetLegalServicesResult';
  legalServices: Array<Maybe<LegalService>>;
  total: Scalars['Int']['output'];
};

export type GetOnboardingProgressInput = {
  customerId: Scalars['String']['input'];
};

export type GetOpportunityThirdPartyPayerFinancingOptionsInput = {
  opportunityId: Scalars['String']['input'];
};

export type GetOrCreatePaymentPlanAccountInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentPlanProvider?: InputMaybe<PaymentPlanProvider>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type GetOrCreatePaymentPlanAccountResponse = {
  __typename?: 'GetOrCreatePaymentPlanAccountResponse';
  accountId: Scalars['String']['output'];
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
};

export type GetPaginatedDiyUsersParams = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedDiyUsersResponse = {
  __typename?: 'GetPaginatedDiyUsersResponse';
  results?: Maybe<Array<Maybe<DiyFunnelData>>>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export type GetPaidDownPaymentsAmountInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type GetPaidLssAmountInput = {
  sfAccountId: Scalars['String']['input'];
};

export type GetPayoutItemsInput = {
  attorneyId?: InputMaybe<Scalars['String']['input']>;
  billingDate?: InputMaybe<Scalars['Date']['input']>;
  externalCaseId?: InputMaybe<Scalars['String']['input']>;
  pending?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetPendingSigningRequestDetailsInput = {
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractType;
  opportunityId: Scalars['String']['input'];
  tenant?: InputMaybe<ElTenant>;
};

export type GetRefundTicketByIdInput = {
  ticketId: Scalars['String']['input'];
};

export type GetRefundTicketByIdResponse = {
  __typename?: 'GetRefundTicketByIdResponse';
  accountName: Scalars['String']['output'];
  additionalInfo?: Maybe<Scalars['String']['output']>;
  amountPaid: Scalars['Int']['output'];
  opportunityId: Scalars['ID']['output'];
  refundAmount: Scalars['Int']['output'];
  refundReason: RefundReason;
  services: Array<ServiceOnOpportunity>;
  status: Scalars['String']['output'];
  ticketId: Scalars['String']['output'];
};

export type GetRequiredDocumentsOptions = {
  legalServicesExternalIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GetSuggestedThirdPartyProviderInput = {
  accountId: Scalars['String']['input'];
  enabledCovered?: InputMaybe<Scalars['Boolean']['input']>;
  signature: Scalars['String']['input'];
};

export type GetThirdPartyFinancedAmountInput = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type GetUnderwritingFinancingInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserByExternalIdInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum GqlPrequalApplicationStatus {
  Approved = 'approved',
  Canceled = 'canceled',
  Created = 'created',
  Declined = 'declined',
  Expired = 'expired',
  Failed = 'failed',
  Incomplete = 'incomplete',
  NotStarted = 'not_started',
  Started = 'started'
}

export enum HashAlgorithm {
  Md5 = 'MD5',
  Sha256 = 'SHA256',
  Sha512 = 'SHA512'
}

export type HashOptions = {
  algorithm?: InputMaybe<HashAlgorithm>;
  useSalt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HashResponse = {
  __typename?: 'HashResponse';
  algorithm?: Maybe<HashAlgorithm>;
  hashed?: Maybe<Scalars['JSON']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type ICaseDocument = {
  case?: Maybe<Case>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
};

export type IFormTemplateField = {
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IdentityVerificationInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type IdentityVerificationOutput = {
  __typename?: 'IdentityVerificationOutput';
  externalId: Scalars['String']['output'];
  idvRequestId: Scalars['String']['output'];
  status: IdentityVerificationStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export enum IdentityVerificationStatus {
  Active = 'active',
  AdditionalInformation = 'additional_information',
  Canceled = 'canceled',
  Expired = 'expired',
  Failed = 'failed',
  PendingReview = 'pending_review',
  Success = 'success'
}

export type ImpersonateByApiKeyInput = {
  adminEmail: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userType: UserType;
};

export type InHousePrequalApplication = {
  __typename?: 'InHousePrequalApplication';
  borrowerId?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<InHousePrequalApplicationMetadata>;
  offers?: Maybe<Array<Maybe<InHousePrequalApplicationOffer>>>;
};

export type InHousePrequalApplicationMetadata = {
  __typename?: 'InHousePrequalApplicationMetadata';
  minDpAmount?: Maybe<Scalars['Float']['output']>;
  minDpPercent?: Maybe<Scalars['Float']['output']>;
};

export type InHousePrequalApplicationOffer = {
  __typename?: 'InHousePrequalApplicationOffer';
  amount?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<Scalars['Float']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installments?: Maybe<Scalars['Float']['output']>;
  interestRate?: Maybe<Scalars['Float']['output']>;
  minDpPercent?: Maybe<Scalars['Float']['output']>;
};

export type Installment = PayoutItem & {
  __typename?: 'Installment';
  amount: Scalars['Float']['output'];
  amountTotal: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  numOfPayments: Scalars['Int']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type IntakeMeeting = {
  __typename?: 'IntakeMeeting';
  notes?: Maybe<Scalars['String']['output']>;
  notesUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IntakeMeetingSlots = {
  __typename?: 'IntakeMeetingSlots';
  slotTimeSpan: Scalars['Int']['output'];
  slots: Array<Scalars['String']['output']>;
};

export type IntakeMeetingSlotsInput = {
  numberOfDays: Scalars['Int']['input'];
  practice: Scalars['String']['input'];
  rangeStart: Scalars['String']['input'];
};

export enum Language {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type Lead = {
  __typename?: 'Lead';
  id: Scalars['String']['output'];
};

export type LegalProduct = {
  __typename?: 'LegalProduct';
  externalId?: Maybe<Scalars['String']['output']>;
  formIds: Array<Maybe<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type LegalService = {
  __typename?: 'LegalService';
  advancedBilling?: Maybe<Scalars['String']['output']>;
  billingPeriod?: Maybe<Scalars['String']['output']>;
  buyingPrice?: Maybe<Scalars['Int']['output']>;
  chargeScheduling?: Maybe<Scalars['String']['output']>;
  chargeType?: Maybe<Scalars['String']['output']>;
  commitment?: Maybe<Scalars['Int']['output']>;
  earlyTermination?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fromDate?: Maybe<Scalars['Date']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  indexValue?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  itemCategory?: Maybe<Scalars['String']['output']>;
  loanAmount?: Maybe<Scalars['Int']['output']>;
  loanApprovalDate?: Maybe<Scalars['Date']['output']>;
  minimumUnit?: Maybe<Scalars['Int']['output']>;
  pricePlanItem?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  prorationType?: Maybe<Scalars['String']['output']>;
  quantityUnitOfMeasure?: Maybe<Scalars['String']['output']>;
  requiredDocuments: Array<Maybe<RequiredDocument>>;
  requiredDocumentsIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  serviceCategory?: Maybe<Scalars['String']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  serviceDescription?: Maybe<Scalars['String']['output']>;
  serviceDescriptionFormatted?: Maybe<Scalars['String']['output']>;
  serviceFamily?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceSKU?: Maybe<Scalars['String']['output']>;
  serviceType: LegalServiceType;
  status?: Maybe<Scalars['String']['output']>;
  subzeeId?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  timelineDescriptionLink?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['Date']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type LegalServiceInput = {
  serviceCategory?: InputMaybe<Scalars['String']['input']>;
  serviceFamily?: InputMaybe<Scalars['String']['input']>;
};

export type LegalServiceProvider = {
  __typename?: 'LegalServiceProvider';
  bio?: Maybe<Scalars['String']['output']>;
  legalArea?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LegalServiceSection = {
  __typename?: 'LegalServiceSection';
  serviceCategory?: Maybe<Scalars['String']['output']>;
  serviceFamily?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
};

export enum LegalServiceType {
  MarbleService = 'MARBLE_SERVICE',
  PaymentProduct = 'PAYMENT_PRODUCT'
}

export type LegalServicesInput = {
  filterByState: Scalars['String']['input'];
  practiceAreaFilter?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalServicesSortOptions {
  ServiceCode = 'SERVICE_CODE'
}

export enum LenderName {
  Affirm = 'affirm',
  Covered = 'covered',
  Genesis = 'genesis',
  Inhouse = 'inhouse',
  Klarna = 'klarna',
  Paypal = 'paypal',
  Wisetack = 'wisetack'
}

export type LenderOffer = {
  __typename?: 'LenderOffer';
  amount: Scalars['Float']['output'];
  downPayment: Scalars['Float']['output'];
  expirationDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  installments: Scalars['Float']['output'];
  interestRate: Scalars['Float']['output'];
};

export type LinkTokenCreateResponse = {
  __typename?: 'LinkTokenCreateResponse';
  linkToken: Scalars['String']['output'];
};

export enum LoanAmountStatus {
  Approved = 'approved',
  Partial = 'partial'
}

export type LoanProvider = {
  __typename?: 'LoanProvider';
  amount?: Maybe<Scalars['Float']['output']>;
  applicationProcessId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  lender: LenderName;
  lenderRefId?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type LoginByCognitoInput = {
  token: Scalars['String']['input'];
  userType: Scalars['String']['input'];
};

export type LoginByGoogleInput = {
  token: Scalars['String']['input'];
  userType: Scalars['String']['input'];
};

export type LoginByTokenResponse = {
  __typename?: 'LoginByTokenResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['JSON']['output']>;
};

export type LoginDemoUserByGoogleInput = {
  token: Scalars['String']['input'];
  userType: UserType;
};

export enum LssAppearance {
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum LssAttorneyCaseRejectionReason {
  ClientIsNotGoodFit = 'CLIENT_IS_NOT_GOOD_FIT',
  Distance = 'DISTANCE',
  KeyDateConflict = 'KEY_DATE_CONFLICT',
  Other = 'OTHER',
  OutOfCapacity = 'OUT_OF_CAPACITY',
  OutOfScope = 'OUT_OF_SCOPE'
}

export type LssAttorneyResponseInput = {
  attorneyAcceptedCase?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<LssAttorneyCaseRejectionReason>;
  rejectionReasonOther?: InputMaybe<Scalars['String']['input']>;
};

export type LssCancelAndRefundRequestInput = {
  amountPaid: Scalars['Int']['input'];
  calendlyEventId: Scalars['String']['input'];
  sfAccountId?: InputMaybe<Scalars['String']['input']>;
  sfLeadId?: InputMaybe<Scalars['String']['input']>;
  sfOpportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type LssCaseInfo = {
  __typename?: 'LssCaseInfo';
  appearance?: Maybe<LssAppearance>;
  county: Scalars['String']['output'];
  courtCaseId?: Maybe<Scalars['String']['output']>;
  filingOrResponding?: Maybe<LssFilingOrResponding>;
  opposingParty?: Maybe<Name>;
  practiceArea: PracticeArea;
  state: Scalars['String']['output'];
  urgent: Scalars['Boolean']['output'];
};

export type LssCaseInfoInput = {
  appearance?: InputMaybe<LssAppearance>;
  county: Scalars['String']['input'];
  courtCaseId?: InputMaybe<Scalars['String']['input']>;
  filingOrResponding?: InputMaybe<LssFilingOrResponding>;
  opposingParty?: InputMaybe<NameInput>;
  practiceArea: PracticeArea;
  state: Scalars['String']['input'];
  urgent: Scalars['Boolean']['input'];
};

export enum LssCaseView {
  ManagingAttorney = 'MANAGING_ATTORNEY'
}

export type LssCasesByViewInput = {
  filter?: InputMaybe<LssCasesFilterInput>;
  viewType: LssCaseView;
};

export type LssCasesFilterInput = {
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type LssClientDetails = {
  __typename?: 'LssClientDetails';
  birthday?: Maybe<Scalars['Date']['output']>;
  charges?: Maybe<Scalars['String']['output']>;
  gender: LssGender;
  name: Name;
  numberOfChildren?: Maybe<Scalars['Int']['output']>;
  priors?: Maybe<Scalars['String']['output']>;
};

export type LssClientDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  charges?: InputMaybe<Scalars['String']['input']>;
  gender: LssGender;
  name: NameInput;
  numberOfChildren?: InputMaybe<Scalars['Int']['input']>;
  priors?: InputMaybe<Scalars['String']['input']>;
};

export type LssCriticalDate = {
  __typename?: 'LssCriticalDate';
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: LssCriticalDateType;
};

export type LssCriticalDateInput = {
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  type: LssCriticalDateType;
};

export enum LssCriticalDateType {
  CourtDate = 'COURT_DATE',
  CriticalDate = 'CRITICAL_DATE',
  ResponseDue = 'RESPONSE_DUE'
}

export enum LssFilingOrResponding {
  Filing = 'FILING',
  Responding = 'RESPONDING'
}

export enum LssGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type LssRefundRequestInput = {
  amountPaid: Scalars['Int']['input'];
  sfAccountId?: InputMaybe<Scalars['String']['input']>;
  sfLeadId?: InputMaybe<Scalars['String']['input']>;
  sfOpportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type LssRejected = {
  __typename?: 'LssRejected';
  other?: Maybe<Scalars['String']['output']>;
  reason: LssRejectionReason;
};

export enum LssRejectionReason {
  AttorneyNotNeeded = 'ATTORNEY_NOT_NEEDED',
  ClientAlreadyConvicted = 'CLIENT_ALREADY_CONVICTED',
  ClientNotAGoodFit = 'CLIENT_NOT_A_GOOD_FIT',
  ClientRejected = 'CLIENT_REJECTED',
  ConflictOfInterest = 'CONFLICT_OF_INTEREST',
  CpsInvolved = 'CPS_INVOLVED',
  Other = 'OTHER',
  OutOfPracticeArea = 'OUT_OF_PRACTICE_AREA',
  OutOfState = 'OUT_OF_STATE',
  PcNoShow = 'PC_NO_SHOW',
  SignedSeveranceAgreement = 'SIGNED_SEVERANCE_AGREEMENT',
  WrongAssignment = 'WRONG_ASSIGNMENT'
}

export type LssRescheduled = {
  __typename?: 'LssRescheduled';
  reason?: Maybe<Scalars['String']['output']>;
};

export type LssSummary = {
  __typename?: 'LssSummary';
  additionalServices?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use lssAttorneyResponse - currently not exposed on LssSummary */
  attorneyAcceptedCase?: Maybe<Scalars['Boolean']['output']>;
  attorneyId: Scalars['ID']['output'];
  caseInfo?: Maybe<LssCaseInfo>;
  clientDetails?: Maybe<LssClientDetails>;
  clientGoals?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  criticalDates?: Maybe<Array<LssCriticalDate>>;
  executiveSummary?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use recommendedServices */
  externalServicesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  financialInfo?: Maybe<Scalars['String']['output']>;
  impressionOfSelectedClientValue?: Maybe<Scalars['String']['output']>;
  impressionsOfClients?: Maybe<Scalars['String']['output']>;
  meetingTime: Scalars['DateTime']['output'];
  recommendedServices?: Maybe<Array<RecommendedServiceDetails>>;
  rejected?: Maybe<LssRejected>;
  rescheduled?: Maybe<LssRescheduled>;
};

export type LssSummaryInput = {
  additionalServices?: InputMaybe<Scalars['String']['input']>;
  attorneyAcceptedCase?: InputMaybe<Scalars['Boolean']['input']>;
  caseId: Scalars['ID']['input'];
  caseInfo: LssCaseInfoInput;
  clientDetails: LssClientDetailsInput;
  clientGoals?: InputMaybe<Scalars['String']['input']>;
  criticalDates?: InputMaybe<Array<LssCriticalDateInput>>;
  executiveSummary: Scalars['String']['input'];
  externalServicesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  financialInfo?: InputMaybe<Scalars['String']['input']>;
  impressionOfSelectedClientValue?: InputMaybe<Scalars['String']['input']>;
  impressionsOfClients?: InputMaybe<Scalars['String']['input']>;
  lssAttorneyResponse?: InputMaybe<LssAttorneyResponseInput>;
  meetingTime: Scalars['DateTime']['input'];
  recommendedServices?: InputMaybe<Array<RecommendedService>>;
};

export type MakeLoanPaymentInput = {
  amountInCents: Scalars['Int']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type MakeLoanPaymentResponse = {
  __typename?: 'MakeLoanPaymentResponse';
  success: Scalars['Boolean']['output'];
};

export type ManagerCasesFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CaseStatus>;
};

export type MarkAllNotificationsAsReadResult = {
  __typename?: 'MarkAllNotificationsAsReadResult';
  identity?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MeetingBookingInput = {
  email: Scalars['String']['input'];
};

export type MeetingDetailsLeadInput = {
  assignTo: Scalars['String']['input'];
  isApproved: Scalars['Boolean']['input'];
  meetingEnd: Scalars['String']['input'];
  meetingName: Scalars['String']['input'];
  meetingStart: Scalars['String']['input'];
};

export type MigrateCaseDocumentsToVinnyResponse = {
  __typename?: 'MigrateCaseDocumentsToVinnyResponse';
  caseId: Scalars['String']['output'];
  failed: Array<Maybe<Scalars['String']['output']>>;
  flareCaseId: Scalars['String']['output'];
  migrated: Array<Maybe<Scalars['String']['output']>>;
  skipped: Array<Maybe<Scalars['String']['output']>>;
};

export type MigrateToVinnyParams = {
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
};

export type MigrateToVinnyPayload = {
  caseId?: InputMaybe<Scalars['String']['input']>;
  flareCaseId: Scalars['String']['input'];
  flareServiceId: Scalars['String']['input'];
  opportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type MigrateToVinnyResponse = {
  __typename?: 'MigrateToVinnyResponse';
  caseEvents?: Maybe<CaseSubentityMigrationResponse>;
  caseId?: Maybe<Scalars['String']['output']>;
  caseUpdates?: Maybe<CaseSubentityMigrationResponse>;
  error?: Maybe<Scalars['String']['output']>;
  flareCaseId: Scalars['String']['output'];
  flareServiceId: Scalars['String']['output'];
  opportunityId?: Maybe<Scalars['String']['output']>;
};

export type MissedPayment = {
  __typename?: 'MissedPayment';
  amount: Scalars['Int']['output'];
  attempt?: Maybe<Scalars['Int']['output']>;
  billingCycle?: Maybe<Scalars['Boolean']['output']>;
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
  resultDescription?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateLoan: Scalars['Boolean']['output'];
  addAdminAlternativeEmail?: Maybe<Admin>;
  addCallLog?: Maybe<CallLog>;
  addCaseEvent?: Maybe<CaseEvent>;
  addCaseNote?: Maybe<CaseNote>;
  addCaseTask?: Maybe<UpsertCaseTaskResult>;
  addCaseUpdate?: Maybe<CaseUpdate>;
  addCaseUpdateWithTags?: Maybe<CaseUpdate>;
  addDocumentClassification?: Maybe<DocumentClassification>;
  addPayment: Scalars['Boolean']['output'];
  addPaymentMethodToCustomer?: Maybe<Scalars['Boolean']['output']>;
  addRequiredDocument?: Maybe<RequiredDocument>;
  addRequiredDocumentToCase?: Maybe<Scalars['Boolean']['output']>;
  archiveCallLog?: Maybe<Scalars['String']['output']>;
  archiveCaseDocument?: Maybe<CaseDocument>;
  archiveCaseEvent?: Maybe<CaseEvent>;
  archiveFormTemplate?: Maybe<Scalars['Boolean']['output']>;
  authorizeAndCaptureTransaction?: Maybe<CaptureTransactionResponse>;
  batchCreatePreAuthLink?: Maybe<Array<Maybe<BatchCreatePreAuthLinkResponse>>>;
  batchDeletePactsafeContract?: Maybe<Scalars['Boolean']['output']>;
  cancelAutopay?: Maybe<Scalars['Boolean']['output']>;
  cancelLoanApplication?: Maybe<Scalars['Boolean']['output']>;
  cancelPaymentOnHold: PaymentOnHold;
  cancelScheduledEvent?: Maybe<CancelledEvent>;
  cancelScheduledEvents?: Maybe<CancelScheduledEventsResponse>;
  capturePaymentOnHold: PaymentOnHold;
  codeVerification?: Maybe<CodeVerificationResponse>;
  codeVerificationForEmail?: Maybe<CodeVerificationResponse>;
  collectOverdueBalance: Scalars['Boolean']['output'];
  contactUs?: Maybe<Scalars['Boolean']['output']>;
  createAch: Scalars['Boolean']['output'];
  createAdmin?: Maybe<Admin>;
  createAttorney?: Maybe<Attorney>;
  createAttorneyCodeVerification?: Maybe<CreateAttorneyCodeVerificationResponse>;
  createCaseDocumentForm?: Maybe<CaseDocumentForm>;
  createCodeVerification?: Maybe<CreateCodeVerificationResponse>;
  createCodeVerificationWithEmail?: Maybe<CreateCodeVerificationResponse>;
  createCustomer?: Maybe<Customer>;
  createFilingFeePaymentPlanComponent: Scalars['Boolean']['output'];
  createForms?: Maybe<Array<Maybe<Form>>>;
  createIdentityVerification?: Maybe<IdentityVerificationOutput>;
  createLegalProduct?: Maybe<LegalProduct>;
  createLinkToken: LinkTokenCreateResponse;
  createMeetingBookingUrl?: Maybe<Scalars['Boolean']['output']>;
  createPayInFullPaymentPlanComponent: Scalars['Boolean']['output'];
  createPaymentOnHold: PaymentOnHold;
  createPaymentPlanComponent: Scalars['Boolean']['output'];
  createPayoutItemBulk?: Maybe<CreatePayoutItemBulkResponse>;
  createRejectedCustomer?: Maybe<RejectedCustomer>;
  createShortLivedToken?: Maybe<CreateShortLivedTokenOutput>;
  createWebLead: Lead;
  createWisetackLoanLink: WisetackLoanLink;
  createWisetackPrequalLink: WisetackPrequalLink;
  deleteCustomerPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  deleteDevVerificationCode?: Maybe<Scalars['Boolean']['output']>;
  deleteDownPayment: Scalars['Boolean']['output'];
  deletePactsafeContract?: Maybe<Scalars['Boolean']['output']>;
  deletePeachCustomerPaymentMethodByExternalId?: Maybe<Scalars['Boolean']['output']>;
  deleteThirdPartyPayment: Scalars['Boolean']['output'];
  deployPortal?: Maybe<Scalars['Boolean']['output']>;
  disableAllMarketingMessages?: Maybe<Scalars['Boolean']['output']>;
  editRecurringPayment: Scalars['Boolean']['output'];
  editThirdPartyPayment: Scalars['Boolean']['output'];
  enableAutopay?: Maybe<Scalars['Boolean']['output']>;
  endRecurringPayment: Scalars['Boolean']['output'];
  exchangePublicToken: Scalars['Boolean']['output'];
  fetchTransactionsDataForCustomer: Scalars['Boolean']['output'];
  generateEl?: Maybe<Scalars['Boolean']['output']>;
  generateLead?: Maybe<Scalars['Boolean']['output']>;
  generateLeadFromSMS?: Maybe<Scalars['URL']['output']>;
  generateLoanApplicationLink?: Maybe<Scalars['String']['output']>;
  generatePaymentAgreement: GeneratePaymentAgreementResponse;
  generatePowerOfAttorney?: Maybe<Scalars['Boolean']['output']>;
  generatePrequalLink?: Maybe<Scalars['Boolean']['output']>;
  genesisAcceptOffer: Scalars['String']['output'];
  genesisCaptureFunds: Scalars['Boolean']['output'];
  genesisStartPrequal: Scalars['String']['output'];
  getCustomerStripeData: CustomerStripeData;
  getOrCreatePaymentPlanAccount: GetOrCreatePaymentPlanAccountResponse;
  hash?: Maybe<HashResponse>;
  impersonateAdminLogin?: Maybe<Scalars['String']['output']>;
  impersonateByApiKey?: Maybe<Scalars['String']['output']>;
  initiateSMSConversation?: Maybe<Scalars['Boolean']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  loginAsCustomer?: Maybe<Scalars['URL']['output']>;
  loginByCognitoToken?: Maybe<Scalars['String']['output']>;
  loginByGoogleToken?: Maybe<Scalars['String']['output']>;
  loginByMail?: Maybe<Scalars['Boolean']['output']>;
  loginByPassword?: Maybe<Scalars['String']['output']>;
  loginByToken?: Maybe<LoginByTokenResponse>;
  loginDemoUserByGoogle?: Maybe<Scalars['String']['output']>;
  lssCancelAndRefundRequest: Scalars['Boolean']['output'];
  lssRefundRequest: Scalars['Boolean']['output'];
  makeLoanPayment: MakeLoanPaymentResponse;
  markAllNotificationsAsRead?: Maybe<MarkAllNotificationsAsReadResult>;
  migrateAttorneys?: Maybe<Scalars['Boolean']['output']>;
  migrateBrazeProfiles?: Maybe<BrazeMigrationResponse>;
  migrateCaseDocumentsToVinny?: Maybe<MigrateCaseDocumentsToVinnyResponse>;
  migrateOneAttorney?: Maybe<Scalars['Boolean']['output']>;
  migrateToDiy?: Maybe<Scalars['Boolean']['output']>;
  migrateToVinny?: Maybe<Array<Maybe<MigrateToVinnyResponse>>>;
  notificationOpened?: Maybe<Scalars['Boolean']['output']>;
  notificationsViewed?: Maybe<Scalars['Boolean']['output']>;
  payTotalOutstanding?: Maybe<PayTotalOutstandingResponse>;
  paypalCancelPrequalApplication: Scalars['Boolean']['output'];
  paypalCapture: Scalars['Boolean']['output'];
  paypalStartPrequalApplication: PrequalResponse;
  postAffirmEvent?: Maybe<Scalars['String']['output']>;
  postLssForm?: Maybe<Scalars['Boolean']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  refundLoan: RefundLoanOutput;
  refundLoanApplication?: Maybe<Scalars['Boolean']['output']>;
  refundTransactions?: Maybe<Array<RefundTransactionResult>>;
  registerDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  registerLpDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  registerStripeCustomer: RegisterStripeCustomerResponse;
  removeRequiredDocument?: Maybe<Scalars['String']['output']>;
  removeRequiredDocumentFromCase?: Maybe<Scalars['ID']['output']>;
  rescheduleLss?: Maybe<Scalars['Boolean']['output']>;
  rescheduleLssMeeting?: Maybe<LssSummary>;
  restoreCaseDocument?: Maybe<CaseDocument>;
  runCoveredCarePrequal?: Maybe<CoveredCarePrequalResult>;
  runCreditCheck?: Maybe<Scalars['Boolean']['output']>;
  runCreditCheckManualUtility?: Maybe<Scalars['Boolean']['output']>;
  scheduleLssMeetingReminder?: Maybe<Scalars['Boolean']['output']>;
  sendAddServicesRequest?: Maybe<Scalars['Boolean']['output']>;
  sendAttorneyFeedback?: Maybe<Scalars['Boolean']['output']>;
  sendDocsToClio?: Maybe<Array<Maybe<UpdatedCaseDocumentStatus>>>;
  sendDocumentsPack?: Maybe<Scalars['Boolean']['output']>;
  sendLpNotification?: Maybe<Notification>;
  sendNotification?: Maybe<Notification>;
  sendPayoutMails?: Maybe<SendPayoutMailsResponse>;
  sendSMS?: Maybe<Scalars['Boolean']['output']>;
  setCaseDocumentFilename?: Maybe<Scalars['String']['output']>;
  setCaseUpdateStatus?: Maybe<Scalars['Boolean']['output']>;
  setCaseWithFlareId?: Maybe<Scalars['Boolean']['output']>;
  setDevVerificationCode?: Maybe<Scalars['String']['output']>;
  setNotificationsAllowed?: Maybe<Scalars['Boolean']['output']>;
  signupOrLogin?: Maybe<Scalars['String']['output']>;
  startLoanApplication: StartLoanApplicationOutput;
  submitCaseForm?: Maybe<Scalars['Boolean']['output']>;
  submitLssSummary?: Maybe<LssSummary>;
  submitRejectedLssSummary?: Maybe<LssSummary>;
  submitRequestForDpPolicyChange: SubmitRequestForDpPolicyChangeResult;
  syncCaseFromOpportunity?: Maybe<Scalars['Boolean']['output']>;
  syncClosedCaseInDrive?: Maybe<ClosedCase>;
  syncDeviceInfo?: Maybe<SyncDeviceResponse>;
  syncDocumentToDrive?: Maybe<DriveDocument>;
  syncUserToBraze?: Maybe<Scalars['Boolean']['output']>;
  underwritingCreateThirdPartyUser: UwUser;
  underwritingSetUser: UwUser;
  updateAttorney?: Maybe<Attorney>;
  updateCaseCourtInfo?: Maybe<Case>;
  updateCaseDocument?: Maybe<CaseDocument>;
  updateCaseDocumentClassification?: Maybe<CaseDocument>;
  updateCaseDocumentFormClassification?: Maybe<CaseDocumentForm>;
  updateCaseDocumentFormFields?: Maybe<CaseDocumentForm>;
  updateCaseDocumentFormStatus?: Maybe<UpdatedCaseDocumentStatus>;
  updateCaseEvent?: Maybe<CaseEvent>;
  updateCaseStage?: Maybe<Case>;
  updateCaseTask?: Maybe<UpsertCaseTaskResult>;
  updateCustomer?: Maybe<Customer>;
  updateCustomerDefaultPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  updateCustomerState?: Maybe<Scalars['Boolean']['output']>;
  updateDefaultPaymentMethod: Scalars['Boolean']['output'];
  updateDocumentSharingWithClient?: Maybe<CaseDocument>;
  updateFormTemplate?: Maybe<FormTemplate>;
  updateLPOnEl?: Maybe<Scalars['Boolean']['output']>;
  updateLastCustomerContact?: Maybe<Case>;
  updateLegalProduct?: Maybe<LegalProduct>;
  updateSFEvent: Scalars['Boolean']['output'];
  updateSFrefundTicket?: Maybe<GetRefundTicketByIdResponse>;
  updateUnderwritingUser: UwUser;
  updateWebLead: Lead;
  updateWhatIsNext?: Maybe<Case>;
  upsertDiyFunnelData?: Maybe<DiyFunnelData>;
  upsertFormData?: Maybe<FormData>;
};


export type MutationActivateLoanArgs = {
  input: ActivateLoanInput;
};


export type MutationAddAdminAlternativeEmailArgs = {
  alternativeEmails?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  email: Scalars['EmailAddress']['input'];
};


export type MutationAddCallLogArgs = {
  callLog?: InputMaybe<CallLogInput>;
};


export type MutationAddCaseEventArgs = {
  event?: InputMaybe<AddCaseEventInput>;
};


export type MutationAddCaseNoteArgs = {
  caseNote?: InputMaybe<CaseNoteInput>;
};


export type MutationAddCaseTaskArgs = {
  caseTask?: InputMaybe<AddCaseTaskInput>;
};


export type MutationAddCaseUpdateArgs = {
  caseUpdate?: InputMaybe<CaseUpdateInput>;
};


export type MutationAddCaseUpdateWithTagsArgs = {
  caseUpdate?: InputMaybe<CaseUpdateInputWithTags>;
};


export type MutationAddDocumentClassificationArgs = {
  payload: AddDocumentClassificationPayload;
};


export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
};


export type MutationAddPaymentMethodToCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddRequiredDocumentArgs = {
  payload: AddRequiredDocumentPayload;
};


export type MutationAddRequiredDocumentToCaseArgs = {
  caseId: Scalars['ID']['input'];
  requiredDocumentId: Scalars['ID']['input'];
};


export type MutationArchiveCallLogArgs = {
  callLogId: Scalars['String']['input'];
};


export type MutationArchiveCaseDocumentArgs = {
  caseDocumentId: Scalars['ID']['input'];
};


export type MutationArchiveCaseEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAuthorizeAndCaptureTransactionArgs = {
  input: AuthorizeTransactionPayload;
};


export type MutationBatchCreatePreAuthLinkArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationBatchDeletePactsafeContractArgs = {
  input: BatchDeletePactsafeContractsInput;
};


export type MutationCancelAutopayArgs = {
  input?: InputMaybe<CancelAutopayRequest>;
};


export type MutationCancelLoanApplicationArgs = {
  input: CancelLoanApplicationInput;
};


export type MutationCancelPaymentOnHoldArgs = {
  input: CancelPaymentOnHoldInput;
};


export type MutationCancelScheduledEventArgs = {
  input: CancelScheduledEventInput;
};


export type MutationCancelScheduledEventsArgs = {
  input: CancelScheduledEventsInput;
};


export type MutationCapturePaymentOnHoldArgs = {
  input: CapturePaymentOnHoldInput;
};


export type MutationCodeVerificationArgs = {
  input?: InputMaybe<CodeVerificationInput>;
};


export type MutationCodeVerificationForEmailArgs = {
  input?: InputMaybe<CodeVerificationForEmailInput>;
};


export type MutationCollectOverdueBalanceArgs = {
  input: CollectOverdueBalanceInput;
};


export type MutationContactUsArgs = {
  contactUs?: InputMaybe<ContactUsInput>;
};


export type MutationCreateAchArgs = {
  input: CreateAchInput;
};


export type MutationCreateAdminArgs = {
  admin?: InputMaybe<CreateAdminInput>;
};


export type MutationCreateAttorneyArgs = {
  attorney?: InputMaybe<CreateAttorneyInput>;
};


export type MutationCreateAttorneyCodeVerificationArgs = {
  phone: Scalars['PhoneNumber']['input'];
};


export type MutationCreateCaseDocumentFormArgs = {
  payload?: InputMaybe<CreateCaseDocumentFormInput>;
};


export type MutationCreateCodeVerificationArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCodeVerificationWithEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCustomerArgs = {
  customer?: InputMaybe<CreateCustomerInput>;
};


export type MutationCreateFilingFeePaymentPlanComponentArgs = {
  input: CreateFilingFeePaymentPlanComponentInput;
};


export type MutationCreateFormsArgs = {
  forms?: InputMaybe<Array<InputMaybe<FormInput>>>;
};


export type MutationCreateIdentityVerificationArgs = {
  input: IdentityVerificationInput;
};


export type MutationCreateLegalProductArgs = {
  legalProduct?: InputMaybe<CreateLegalProductInput>;
};


export type MutationCreateLinkTokenArgs = {
  input: CreateLinkTokenInput;
};


export type MutationCreateMeetingBookingUrlArgs = {
  input: MeetingBookingInput;
};


export type MutationCreatePayInFullPaymentPlanComponentArgs = {
  input: CreatePayInFullPaymentPlanComponentInput;
};


export type MutationCreatePaymentOnHoldArgs = {
  input: CreatePaymentOnHoldInput;
};


export type MutationCreatePaymentPlanComponentArgs = {
  input: CreatePaymentPlanComponentInput;
};


export type MutationCreatePayoutItemBulkArgs = {
  input: CreatePayoutItemBulkInput;
};


export type MutationCreateRejectedCustomerArgs = {
  rejectedCustomer: CreateRejectedCustomerInput;
};


export type MutationCreateWebLeadArgs = {
  input: CreateWebLeadInput;
};


export type MutationCreateWisetackLoanLinkArgs = {
  input: CreateWisetackLoanLinkInput;
};


export type MutationCreateWisetackPrequalLinkArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteCustomerPaymentMethodArgs = {
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteDevVerificationCodeArgs = {
  input?: InputMaybe<DevVerificationCodeInput>;
};


export type MutationDeleteDownPaymentArgs = {
  input: DeleteDownPaymentInput;
};


export type MutationDeletePactsafeContractArgs = {
  input: DeletePactsafeContractInput;
};


export type MutationDeletePeachCustomerPaymentMethodByExternalIdArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
  signature?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteThirdPartyPaymentArgs = {
  input: DeleteThirdPartyPaymentInput;
};


export type MutationDeployPortalArgs = {
  environment: Environments;
};


export type MutationDisableAllMarketingMessagesArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationEditRecurringPaymentArgs = {
  input: EditRecurringPaymentInput;
};


export type MutationEditThirdPartyPaymentArgs = {
  input: EditThirdPartyPaymentInput;
};


export type MutationEnableAutopayArgs = {
  input?: InputMaybe<EnableAutopayRequest>;
};


export type MutationEndRecurringPaymentArgs = {
  input: EndRecurringPaymentInput;
};


export type MutationExchangePublicTokenArgs = {
  input: ExchangePublicTokenInput;
};


export type MutationFetchTransactionsDataForCustomerArgs = {
  input: CreateLinkTokenInput;
};


export type MutationGenerateElArgs = {
  input: GenerateElInput;
};


export type MutationGenerateLeadArgs = {
  lead: GenerateLeadInput;
};


export type MutationGenerateLeadFromSmsArgs = {
  lead: GenerateLeadInput;
};


export type MutationGenerateLoanApplicationLinkArgs = {
  input: GenerateLoanApplicationLinkInput;
};


export type MutationGeneratePaymentAgreementArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type MutationGeneratePowerOfAttorneyArgs = {
  input: GenerateElInput;
};


export type MutationGeneratePrequalLinkArgs = {
  input: GeneratePrequalLinkInput;
};


export type MutationGenesisAcceptOfferArgs = {
  userId: Scalars['String']['input'];
};


export type MutationGenesisCaptureFundsArgs = {
  amount: Scalars['Int']['input'];
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationGenesisStartPrequalArgs = {
  input: GenesisStartPrequalInput;
};


export type MutationGetOrCreatePaymentPlanAccountArgs = {
  input: GetOrCreatePaymentPlanAccountInput;
};


export type MutationHashArgs = {
  hashOptions?: InputMaybe<HashOptions>;
  input: Scalars['JSON']['input'];
};


export type MutationImpersonateAdminLoginArgs = {
  email: Scalars['String']['input'];
};


export type MutationImpersonateByApiKeyArgs = {
  input: ImpersonateByApiKeyInput;
};


export type MutationInitiateSmsConversationArgs = {
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
};


export type MutationLoginArgs = {
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginAsCustomerArgs = {
  email: Scalars['EmailAddress']['input'];
  loginType?: InputMaybe<CustomerLoginType>;
};


export type MutationLoginByCognitoTokenArgs = {
  input: LoginByCognitoInput;
};


export type MutationLoginByGoogleTokenArgs = {
  input?: InputMaybe<LoginByGoogleInput>;
};


export type MutationLoginByMailArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginByPasswordArgs = {
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginByTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginDemoUserByGoogleArgs = {
  input?: InputMaybe<LoginDemoUserByGoogleInput>;
};


export type MutationLssCancelAndRefundRequestArgs = {
  input: LssCancelAndRefundRequestInput;
};


export type MutationLssRefundRequestArgs = {
  input: LssRefundRequestInput;
};


export type MutationMakeLoanPaymentArgs = {
  input: MakeLoanPaymentInput;
};


export type MutationMigrateBrazeProfilesArgs = {
  params?: InputMaybe<BrazeMigrationInput>;
};


export type MutationMigrateCaseDocumentsToVinnyArgs = {
  caseId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationMigrateOneAttorneyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMigrateToDiyArgs = {
  opportunityIds: Array<Scalars['String']['input']>;
};


export type MutationMigrateToVinnyArgs = {
  params?: InputMaybe<MigrateToVinnyParams>;
  payload: Array<InputMaybe<MigrateToVinnyPayload>>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationNotificationOpenedArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationNotificationsViewedArgs = {
  notificationIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationPayTotalOutstandingArgs = {
  input?: InputMaybe<PayTotalOutstandingRequest>;
};


export type MutationPaypalCancelPrequalApplicationArgs = {
  input?: InputMaybe<CancelEventInput>;
};


export type MutationPaypalCaptureArgs = {
  checkout: PaypalCheckout;
};


export type MutationPaypalStartPrequalApplicationArgs = {
  input: PrequalInput;
};


export type MutationPostAffirmEventArgs = {
  input: AffirmProviderEventInput;
};


export type MutationPostLssFormArgs = {
  input: PostLssFormInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRefundLoanArgs = {
  input?: InputMaybe<RefundLoanInput>;
};


export type MutationRefundLoanApplicationArgs = {
  input: RefundLoanApplicationInput;
};


export type MutationRefundTransactionsArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  reason: TransactionRefundReason;
  signature: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
  transactions: Array<RefundTransaction>;
};


export type MutationRegisterDeviceTokenArgs = {
  app: App;
  platform: PlatformType;
  token: Scalars['String']['input'];
};


export type MutationRegisterLpDeviceTokenArgs = {
  platform: PlatformType;
  token: Scalars['String']['input'];
};


export type MutationRegisterStripeCustomerArgs = {
  input: RegisterStripeCustomerInput;
};


export type MutationRemoveRequiredDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveRequiredDocumentFromCaseArgs = {
  caseId: Scalars['ID']['input'];
  requiredDocumentId: Scalars['ID']['input'];
};


export type MutationRescheduleLssArgs = {
  input: RescheduleLssInput;
};


export type MutationRescheduleLssMeetingArgs = {
  input: RescheduleLssMeetingInput;
};


export type MutationRestoreCaseDocumentArgs = {
  caseDocumentId: Scalars['ID']['input'];
};


export type MutationRunCoveredCarePrequalArgs = {
  input?: InputMaybe<RunCoveredCarePrequalInput>;
};


export type MutationRunCreditCheckArgs = {
  input: RunCreditCheckInput;
};


export type MutationRunCreditCheckManualUtilityArgs = {
  input?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationScheduleLssMeetingReminderArgs = {
  input: ScheduleLssMeetingReminderInput;
};


export type MutationSendAddServicesRequestArgs = {
  sendAddServicesRequest?: InputMaybe<SendAddServicesRequestInput>;
};


export type MutationSendAttorneyFeedbackArgs = {
  sendAttorneyFeedback?: InputMaybe<SendAttorneyFeedbackInput>;
};


export type MutationSendDocsToClioArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  docList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationSendDocumentsPackArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSendLpNotificationArgs = {
  pushNotificationInput: NotificationInput;
};


export type MutationSendNotificationArgs = {
  pushNotificationInput: NotificationInput;
};


export type MutationSendPayoutMailsArgs = {
  input?: InputMaybe<SendPayoutMailsInput>;
};


export type MutationSendSmsArgs = {
  SMSContent: Scalars['String']['input'];
  toNumbers: Array<InputMaybe<Scalars['PhoneNumber']['input']>>;
  userId: Scalars['String']['input'];
};


export type MutationSetCaseDocumentFilenameArgs = {
  caseDocumentType?: InputMaybe<CaseDocumentType>;
  filename: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationSetCaseUpdateStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetCaseWithFlareIdArgs = {
  caseId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetDevVerificationCodeArgs = {
  input?: InputMaybe<DevVerificationCodeInput>;
};


export type MutationSetNotificationsAllowedArgs = {
  setNotificationsAllowedInput: SetNotificationsAllowedInput;
};


export type MutationSignupOrLoginArgs = {
  apiKey: Scalars['String']['input'];
  customer: CustomerInput;
};


export type MutationStartLoanApplicationArgs = {
  input?: InputMaybe<StartLoanApplicationInput>;
};


export type MutationSubmitCaseFormArgs = {
  caseId: Scalars['ID']['input'];
  questionList?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationSubmitLssSummaryArgs = {
  input: LssSummaryInput;
};


export type MutationSubmitRejectedLssSummaryArgs = {
  input: RejectedLssSummaryInput;
};


export type MutationSubmitRequestForDpPolicyChangeArgs = {
  input: SubmitRequestForDpPolicyChangeInput;
};


export type MutationSyncCaseFromOpportunityArgs = {
  input: SyncCaseFromOpportunityInput;
};


export type MutationSyncClosedCaseInDriveArgs = {
  closedCase?: InputMaybe<SyncClosedCaseInput>;
};


export type MutationSyncDeviceInfoArgs = {
  appInstallationInput: AppInstallationInput;
};


export type MutationSyncDocumentToDriveArgs = {
  document?: InputMaybe<SyncDocumentToDriveInput>;
};


export type MutationSyncUserToBrazeArgs = {
  customer?: InputMaybe<BrazeCustomerInput>;
};


export type MutationUnderwritingCreateThirdPartyUserArgs = {
  input: CreateThirdPartyUserInput;
};


export type MutationUnderwritingSetUserArgs = {
  input: SetUserInput;
};


export type MutationUpdateAttorneyArgs = {
  attorney?: InputMaybe<UpdateAttorneyInput>;
};


export type MutationUpdateCaseCourtInfoArgs = {
  courtInfo?: InputMaybe<UpdateCaseCourtInfoInput>;
};


export type MutationUpdateCaseDocumentArgs = {
  caseDocument?: InputMaybe<CaseDocumentInput>;
};


export type MutationUpdateCaseDocumentClassificationArgs = {
  payload?: InputMaybe<UpdateCaseDocumentTypeInput>;
};


export type MutationUpdateCaseDocumentFormClassificationArgs = {
  payload?: InputMaybe<UpdateCaseDocumentTypeInput>;
};


export type MutationUpdateCaseDocumentFormFieldsArgs = {
  payload?: InputMaybe<UpdateCaseDocumentFormFieldsInput>;
};


export type MutationUpdateCaseDocumentFormStatusArgs = {
  payload?: InputMaybe<UpdateCaseDocumentFormStatusInput>;
};


export type MutationUpdateCaseEventArgs = {
  event?: InputMaybe<UpdateCaseEventInput>;
};


export type MutationUpdateCaseStageArgs = {
  caseId: Scalars['ID']['input'];
  newStage: StageEnum;
};


export type MutationUpdateCaseTaskArgs = {
  caseTaskUpdate?: InputMaybe<UpdateCaseTaskInput>;
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerUpdateInput;
};


export type MutationUpdateCustomerDefaultPaymentMethodArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCustomerStateArgs = {
  key: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateDefaultPaymentMethodArgs = {
  input: UpdateDefaultPaymentMethodInput;
};


export type MutationUpdateDocumentSharingWithClientArgs = {
  payload?: InputMaybe<UpdateDocumentSharingWithClientPayload>;
};


export type MutationUpdateFormTemplateArgs = {
  payload?: InputMaybe<UpdateFormTemplateInput>;
};


export type MutationUpdateLpOnElArgs = {
  input: GenerateElInput;
};


export type MutationUpdateLastCustomerContactArgs = {
  caseId: Scalars['ID']['input'];
};


export type MutationUpdateLegalProductArgs = {
  legalProductInput?: InputMaybe<UpdateLegalProductInput>;
};


export type MutationUpdateSfEventArgs = {
  input: SfUpdateEventInput;
};


export type MutationUpdateSFrefundTicketArgs = {
  input: UpdateSfRefundTicketInput;
};


export type MutationUpdateUnderwritingUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWebLeadArgs = {
  input: UpdateWebLeadInput;
};


export type MutationUpdateWhatIsNextArgs = {
  whatIsNext?: InputMaybe<UpdateWhatIsNextInput>;
};


export type MutationUpsertDiyFunnelDataArgs = {
  payload: UpsertDiyFunnelData;
};


export type MutationUpsertFormDataArgs = {
  formData?: InputMaybe<FormDataInput>;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  middle?: Maybe<Scalars['String']['output']>;
};

export type NameInput = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type NextPayment = {
  __typename?: 'NextPayment';
  amount: Scalars['Int']['output'];
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  additionalData?: Maybe<Scalars['JSON']['output']>;
  body: Scalars['String']['output'];
  category?: Maybe<NotificationCategory>;
  createdAt: Scalars['Date']['output'];
  data?: Maybe<NotificationData>;
  id: Scalars['ID']['output'];
  identity: Scalars['String']['output'];
  notificationStatus: NotificationStatus;
  notificationType: Scalars['String']['output'];
  sound: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum NotificationCategory {
  AttorneyCx = 'ATTORNEY_CX',
  CaseClosed = 'CASE_CLOSED',
  CaseUpdate = 'CASE_UPDATE',
  Documents = 'DOCUMENTS',
  Event = 'EVENT',
  Generic = 'GENERIC',
  NewCaseAdded = 'NEW_CASE_ADDED',
  Payments = 'PAYMENTS',
  Scheduling = 'SCHEDULING',
  UpcomingCourtDate_10D = 'UPCOMING_COURT_DATE_10D'
}

export type NotificationData = {
  __typename?: 'NotificationData';
  actionData?: Maybe<Scalars['JSON']['output']>;
  actionType?: Maybe<ActionType>;
};

export type NotificationDataInput = {
  actionData?: InputMaybe<Scalars['JSON']['input']>;
  actionType?: InputMaybe<ActionType>;
};

export type NotificationInput = {
  additionalData?: InputMaybe<Scalars['JSON']['input']>;
  body: Scalars['String']['input'];
  category?: InputMaybe<NotificationCategory>;
  data: NotificationDataInput;
  identity: Scalars['String']['input'];
  notificationType: NotificationType;
  sound?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum NotificationStatus {
  Arrived = 'ARRIVED',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Viewed = 'VIEWED'
}

export enum NotificationType {
  AppOnly = 'APP_ONLY',
  Push = 'PUSH'
}

export type NotificationsOrderByInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  engagementLetterSigned?: Maybe<Scalars['Boolean']['output']>;
  fee?: Maybe<Fee>;
  lssMeeting?: Maybe<ScheduledMeeting>;
};

export type OnboardingProgress = {
  __typename?: 'OnboardingProgress';
  step?: Maybe<OnboardingStepProgress>;
};

export enum OnboardingStepId {
  Ach = 'ACH',
  End = 'END',
  Fico = 'FICO',
  PaymentMethod = 'PAYMENT_METHOD'
}

export type OnboardingStepProgress = {
  __typename?: 'OnboardingStepProgress';
  stepId: OnboardingStepId;
};

export type OneTimePlan = PaymentPlanComponent & {
  __typename?: 'OneTimePlan';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  paymentFrequency?: Maybe<PaymentFrequency>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
};

export type OpportunityLinkFields = {
  __typename?: 'OpportunityLinkFields';
  accountFirstName: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  agentId: Scalars['String']['output'];
  agentPhone?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
};

export type OpportunityLinkFieldsByCaseIdInput = {
  caseId: Scalars['String']['input'];
};

export type OpportunityLinkFieldsInput = {
  repeatId: Scalars['String']['input'];
};

export type OpportunityPaymentData = {
  __typename?: 'OpportunityPaymentData';
  action: Scalars['String']['output'];
  action_text: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  ccAuthorized: Scalars['Boolean']['output'];
  deal_id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  stripeAccount: Scalars['String']['output'];
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type OpportunityStage = {
  __typename?: 'OpportunityStage';
  stage: Scalars['String']['output'];
};

export type OpposingParty = {
  __typename?: 'OpposingParty';
  name: PartialName;
};

export type Owner = Admin | Attorney | Customer;

export type Page = {
  __typename?: 'Page';
  email: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PaidLssAmount = {
  __typename?: 'PaidLssAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export type PartialAttorney = {
  __typename?: 'PartialAttorney';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  adminOnboardingStatus: AdminOnboardingStatus;
  attorneyType?: Maybe<AttorneyType>;
  barNumber?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cafNumber?: Maybe<Scalars['String']['output']>;
  calendlyLink?: Maybe<Scalars['String']['output']>;
  casesOffLimits?: Maybe<Array<Maybe<CasesOffLimits>>>;
  casesOffLimitsOther?: Maybe<Scalars['String']['output']>;
  conservatorship?: Maybe<Scalars['Boolean']['output']>;
  contestedDivorce?: Maybe<Scalars['Boolean']['output']>;
  contractLink?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['EmailAddress']['output'];
  familyCourtNY?: Maybe<Scalars['Boolean']['output']>;
  guardianship?: Maybe<Scalars['Boolean']['output']>;
  handlesCases?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['URL']['output']>;
  inHouse?: Maybe<Scalars['Boolean']['output']>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  juvenileCrime?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Language>>;
  licensingJurisdiction?: Maybe<Scalars['String']['output']>;
  litigation?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Scalars['JSON']['output']>;
  matrimonialCourtNY?: Maybe<Scalars['Boolean']['output']>;
  name: Name;
  performsLSS?: Maybe<Scalars['Boolean']['output']>;
  phones?: Maybe<Phones>;
  practiceAreas?: Maybe<Array<PracticeArea>>;
  prenup?: Maybe<Scalars['Boolean']['output']>;
  ptin?: Maybe<Scalars['String']['output']>;
  salesforceId: Scalars['String']['output'];
  shortDeadline?: Maybe<Scalars['Boolean']['output']>;
};

export type PartialName = {
  __typename?: 'PartialName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  middle?: Maybe<Scalars['String']['output']>;
};

export type PartialPaymentAccount = {
  __typename?: 'PartialPaymentAccount';
  nextPayment?: Maybe<NextPayment>;
  totalDebt?: Maybe<Scalars['Int']['output']>;
};

export type PastPayment = {
  __typename?: 'PastPayment';
  amount: Scalars['Int']['output'];
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  loanId?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  p_type?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentStatus>;
};

export type PastTransaction = {
  __typename?: 'PastTransaction';
  amount: Scalars['Float']['output'];
  dueDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  loanId?: Maybe<Scalars['String']['output']>;
  method?: Maybe<TransactionPaymentMethod>;
  paymentProvider: TransactionPaymentProvider;
  provider: TransactionsProvider;
  refundAmount: Scalars['Float']['output'];
  status: TransactionsStatus;
  type: TransactionsType;
};

export type PayTotalOutstandingRequest = {
  accountHash: Scalars['String']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type PayTotalOutstandingResponse = {
  __typename?: 'PayTotalOutstandingResponse';
  accountHash: Scalars['String']['output'];
  amountPaid: Scalars['Int']['output'];
  paymentMethodMask: Scalars['String']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
};

export type PayerFinancingOptions = {
  __typename?: 'PayerFinancingOptions';
  financingOptions?: Maybe<Array<FinancingOption>>;
  payer?: Maybe<UwUser>;
};

export type Payment = {
  __typename?: 'Payment';
  methods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  accountId?: Maybe<Scalars['String']['output']>;
  financingType?: Maybe<FinancingType>;
  hash: Scalars['String']['output'];
  isEligibleForFillingFees?: Maybe<Scalars['Boolean']['output']>;
  missedPayments?: Maybe<Array<MissedPayment>>;
  nextPayment?: Maybe<NextPayment>;
  pastPayments?: Maybe<Array<PastPayment>>;
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
  paymentStatus?: Maybe<PaymentStatus>;
  plans?: Maybe<Array<PaymentPlan>>;
  totalAmount: Scalars['Int']['output'];
  totalBookings: Scalars['Int']['output'];
  totalDebt: Scalars['Int']['output'];
  totalOutstanding: Scalars['Int']['output'];
  totalOutstandingAttempted: Scalars['Int']['output'];
  totalPaid: Scalars['Int']['output'];
  unacknowledgedOutstandingBalancePayment?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentAgreementResponse = {
  __typename?: 'PaymentAgreementResponse';
  paSigned: Scalars['Boolean']['output'];
  paURL?: Maybe<Scalars['String']['output']>;
};

export type PaymentCustomerAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentFrequency {
  EveryTwoWeeks = 'everyTwoWeeks',
  Monthly = 'monthly',
  SingleTerm = 'singleTerm',
  TwiceMonthly = 'twiceMonthly',
  Weekly = 'weekly'
}

export type PaymentInput = {
  opportunityId: Scalars['String']['input'];
  requestAction: Scalars['String']['input'];
};

export type PaymentMethod = {
  id: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PaymentOnHold = {
  __typename?: 'PaymentOnHold';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  customerId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opportunityId: Scalars['String']['output'];
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  stripePaymentIntentId: Scalars['String']['output'];
  stripePaymentStatus: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  caseExternalId?: Maybe<Scalars['String']['output']>;
  components: Array<PaymentPlanComponent>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  startedAt: Scalars['Date']['output'];
  totalPrice: Scalars['Int']['output'];
};

export type PaymentPlanComponent = {
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paymentFrequency?: Maybe<PaymentFrequency>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
};

export type PaymentPlanConfig = {
  __typename?: 'PaymentPlanConfig';
  minDownpaymentRatio: Scalars['Float']['output'];
  thirdPartyLoanAllowed: Scalars['Boolean']['output'];
};

export type PaymentPlanData = {
  __typename?: 'PaymentPlanData';
  paymentPlans?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlan>>>;
};

export enum PaymentPlanItem {
  DownPayment = 'DOWN_PAYMENT',
  Lss = 'LSS',
  MonthlyPayment = 'MONTHLY_PAYMENT'
}

export enum PaymentPlanProvider {
  Peach = 'peach',
  Subzee = 'subzee'
}

export type PaymentPlanSetupAccountData = {
  __typename?: 'PaymentPlanSetupAccountData';
  accountHash?: Maybe<Scalars['String']['output']>;
  accountId?: Maybe<Scalars['String']['output']>;
  achData?: Maybe<AchData>;
  customerFirstName?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  financingType?: Maybe<FinancingType>;
  opportunities?: Maybe<Array<Maybe<PaymentPlanSetupOpportunity>>>;
  paymentMethods?: Maybe<Array<Maybe<Card>>>;
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
  paymentPlans?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlan>>>;
  payments?: Maybe<Array<Maybe<PastPayment>>>;
  paymentsOnHold?: Maybe<Array<PaymentOnHold>>;
  totalBalance: Scalars['Int']['output'];
  totalBookings: Scalars['Int']['output'];
  totalOutstanding: Scalars['Int']['output'];
  totalPaid?: Maybe<Scalars['Int']['output']>;
};

export type PaymentPlanSetupOpportunity = {
  __typename?: 'PaymentPlanSetupOpportunity';
  actualTotalCost?: Maybe<Scalars['String']['output']>;
  legalCaseStatus?: Maybe<Scalars['String']['output']>;
  opportunityAmount?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
  opportunityName: Scalars['String']['output'];
  opportunityOwner: Scalars['String']['output'];
  opportunityRecordType?: Maybe<Scalars['String']['output']>;
  servicesWithFilingFees: Scalars['Int']['output'];
  stageName: Scalars['String']['output'];
  thirdPartyFinanceAmount?: Maybe<Scalars['String']['output']>;
  thirdPartyFinanceApprovalDate?: Maybe<Scalars['String']['output']>;
};

export type PaymentPlanSetupPaymentPlan = {
  __typename?: 'PaymentPlanSetupPaymentPlan';
  components?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlanComponent>>>;
  opportunityId: Scalars['String']['output'];
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type PaymentPlanSetupPaymentPlanComponent = {
  __typename?: 'PaymentPlanSetupPaymentPlanComponent';
  amount: Scalars['Int']['output'];
  autopay?: Maybe<Autopay>;
  autopayAccepted?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<BillingCycleOutput>;
  description: Scalars['String']['output'];
  downPayments?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['Date']['output']>;
  financeCompany?: Maybe<ThirdPartyProviderType>;
  id: Scalars['String']['output'];
  installments: Scalars['Int']['output'];
  overdueBalance?: Maybe<Scalars['Int']['output']>;
  paidBalance?: Maybe<Scalars['Int']['output']>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  servicer?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Int']['output'];
  type: PaymentPlanSetupPaymentPlanType;
};

export enum PaymentPlanSetupPaymentPlanType {
  Credit = 'Credit',
  DownPayment = 'DownPayment',
  FilingFee = 'FilingFee',
  Lss = 'LSS',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  PayInFull = 'PayInFull',
  Refund = 'Refund',
  ThirdParty = 'ThirdParty'
}

export type PaymentPlansPaymentMethods = {
  __typename?: 'PaymentPlansPaymentMethods';
  paymentMethods?: Maybe<Array<Maybe<Card>>>;
};

export enum PaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PayoutItem = {
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export enum PayoutItemType {
  Addendum = 'Addendum',
  Adjustment = 'Adjustment',
  Bonus = 'Bonus',
  Clawback = 'Clawback',
  Installment = 'Installment'
}

export type PaypalCheckout = {
  dealId: Scalars['String']['input'];
  details: Details;
  loanApplicationId: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type PaypalClientTokenResponse = {
  __typename?: 'PaypalClientTokenResponse';
  clientToken: Scalars['String']['output'];
};

export type PendingOnboardingAttorney = {
  __typename?: 'PendingOnboardingAttorney';
  attorney: PartialAttorney;
  id: Scalars['ID']['output'];
  isOnboarded: Scalars['Boolean']['output'];
};

export type Phones = {
  __typename?: 'Phones';
  external?: Maybe<Array<Maybe<Scalars['PhoneNumber']['output']>>>;
  fax?: Maybe<Array<Maybe<Scalars['PhoneNumber']['output']>>>;
  personal: Array<Maybe<Scalars['PhoneNumber']['output']>>;
};

export type PhonesInput = {
  external?: InputMaybe<Array<InputMaybe<Scalars['PhoneNumber']['input']>>>;
  fax?: InputMaybe<Array<InputMaybe<Scalars['PhoneNumber']['input']>>>;
  personal: Array<InputMaybe<Scalars['PhoneNumber']['input']>>;
};

export enum PlatformConfigurationsType {
  Mobile = 'mobile',
  Web = 'web'
}

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type PostLssFormInput = {
  clientServedDate?: InputMaybe<Scalars['Date']['input']>;
  deadLineToRespondDate?: InputMaybe<Scalars['Date']['input']>;
  lssNotes?: InputMaybe<Scalars['String']['input']>;
  lssOutcome: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  otherDate?: InputMaybe<Scalars['Date']['input']>;
  otherText?: InputMaybe<Scalars['String']['input']>;
  outOfScopeReason?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  scheduledHearingDate?: InputMaybe<Scalars['Date']['input']>;
  statueToLimitationDeadlineDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum PracticeArea {
  Bankruptcy = 'BANKRUPTCY',
  Criminal = 'CRIMINAL',
  Dui = 'DUI',
  Employment = 'EMPLOYMENT',
  EstatePlanning = 'ESTATE_PLANNING',
  Family = 'FAMILY',
  Immigration = 'IMMIGRATION',
  Tax = 'TAX',
  Unknown = 'UNKNOWN'
}

export type PrequalApplicationOutput = {
  __typename?: 'PrequalApplicationOutput';
  amount?: Maybe<Scalars['Float']['output']>;
  applicationProcessId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  lender: LenderName;
  lenderRefId?: Maybe<Scalars['String']['output']>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<GqlPrequalApplicationStatus>;
  updatedAt: Scalars['Date']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type PrequalInput = {
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  requestedAmount: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type PrequalResponse = {
  __typename?: 'PrequalResponse';
  loanApplicationId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  allAttorneys?: Maybe<Array<Maybe<Attorney>>>;
  checkAttonreysWithSlots?: Maybe<Scalars['JSON']['output']>;
  checkCalendarsAvailability?: Maybe<CheckCalendarsAvailabilityResponse>;
  genesisGetAccount?: Maybe<GenesisLoanAccount>;
  genesisGetLoanApplication?: Maybe<GenesisLoanApplication>;
  genesisGetLoanTerms?: Maybe<GenesisLoanTerms>;
  genesisGetPrequalApplication?: Maybe<GenesisPrequalApplication>;
  getAccountStatuses: Array<AccountStatus>;
  getAccountsForAch?: Maybe<Array<AccountForAch>>;
  getActiveCasesFilters?: Maybe<CaseFilters>;
  getAdmin?: Maybe<Admin>;
  getAdminNotifications?: Maybe<Array<Maybe<Notification>>>;
  getAdmins: Array<Maybe<Admin>>;
  getAdminsByTeams: Array<Admin>;
  getAllBillingDates: Array<Maybe<Scalars['Date']['output']>>;
  getAllFinancingOptions: Array<UserFinancingOptionOrNull>;
  getAppConfigurations?: Maybe<AppConfigurations>;
  getAppInstallationsInfo?: Maybe<AppInstallation>;
  getAppPrivateConfigurations?: Maybe<AppConfigurations>;
  getAppPublicConfigurations?: Maybe<AppConfigurations>;
  getAttorney?: Maybe<Attorney>;
  getAttorneys?: Maybe<Array<Maybe<Attorney>>>;
  getCalendarsAvailability?: Maybe<Array<Maybe<CalendarAvailability>>>;
  getCalendarsAvailabilityByUrl?: Maybe<Array<Maybe<CalendarAvailability>>>;
  getCase?: Maybe<Case>;
  getCaseByExternalId?: Maybe<Case>;
  getCaseCostTicketDataById?: Maybe<CaseCostTicketData>;
  getCaseDocument?: Maybe<CaseDocument>;
  getCaseDocumentFormById?: Maybe<CaseDocumentForm>;
  /** @deprecated Use getCase.documents instead */
  getCaseDocuments: Array<Maybe<CaseDocument>>;
  getCaseDocumentsByCaseId?: Maybe<Array<Maybe<CaseDocument>>>;
  getCaseDocumentsWithForms: Array<Maybe<ICaseDocument>>;
  getCaseEventById?: Maybe<CaseEvent>;
  getCaseEvents?: Maybe<Array<Maybe<CaseEvent>>>;
  getCases?: Maybe<CasesResults>;
  getChatHistory?: Maybe<Array<Maybe<Ticket>>>;
  getCoveredCarePrequalData?: Maybe<Array<CoveredCarePrequalData>>;
  getCoveredCarePrequalLink: CoveredCarePrequalLink;
  getCurrent?: Maybe<Customer>;
  getCurrentAdmin?: Maybe<Admin>;
  getCurrentAttorney?: Maybe<Attorney>;
  getCustomerCases?: Maybe<Array<Maybe<Case>>>;
  getCustomerFinancingSummary?: Maybe<CustomerFinancingSummary>;
  getCustomerNotifications?: Maybe<Array<Maybe<Notification>>>;
  getCustomerPaymentPlanData?: Maybe<PaymentPlanData>;
  getCustomerRiskLevel: GetCustomerRiskLevelResult;
  getCustomerUnreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
  getDecryptedPhone?: Maybe<Scalars['String']['output']>;
  getDocument?: Maybe<Scalars['String']['output']>;
  getDocumentClassificationsWithCategories?: Maybe<Array<Maybe<DocumentClassificationsWithCategory>>>;
  getFormTemplate?: Maybe<FormTemplate>;
  getFormTemplates: Array<Maybe<FormTemplate>>;
  getForms?: Maybe<Array<Maybe<Form>>>;
  getFreeTaxFunnelPDF?: Maybe<Scalars['String']['output']>;
  getIdentityVerification?: Maybe<IdentityVerificationOutput>;
  getInHouseFinancingType?: Maybe<FinancingType>;
  getIntakeMeetingSlots: IntakeMeetingSlots;
  getIsReadyForCaseStatus: Scalars['Boolean']['output'];
  getLeadIdByFunnelActionId?: Maybe<Lead>;
  getLegalProducts?: Maybe<Array<Maybe<LegalProduct>>>;
  getLegalServices: GetLegalServicesResult;
  getLssAttorneyCases?: Maybe<Array<Maybe<Case>>>;
  getManagerCases?: Maybe<Array<Maybe<Case>>>;
  getNotification?: Maybe<Notification>;
  getOpportunityLinkFields: OpportunityLinkFields;
  getOpportunityLinkFieldsByCaseId: OpportunityLinkFields;
  getOpportunityPaymentData: OpportunityPaymentData;
  getOpportunityStage?: Maybe<OpportunityStage>;
  getOpportunityThirdPartyPayerFinancingOptions: Array<PayerFinancingOptions>;
  getPaginatedDiyUsers?: Maybe<GetPaginatedDiyUsersResponse>;
  getPaidDownPaymentAmount: DownPaymentsPaidAmount;
  getPaidLssAmount: PaidLssAmount;
  getPastTransactions?: Maybe<Array<PastTransaction>>;
  getPaymentAccount?: Maybe<PaymentAccount>;
  getPaymentAgreement: PaymentAgreementResponse;
  getPaymentPlanBalanceData?: Maybe<PartialPaymentAccount>;
  getPaymentPlanSetupAccountData?: Maybe<PaymentPlanSetupAccountData>;
  getPaymentsOnHold?: Maybe<Array<PaymentOnHold>>;
  getPayoutItems: Array<Maybe<PayoutItem>>;
  getPendingOnboardingAttorneys?: Maybe<Array<Maybe<PendingOnboardingAttorney>>>;
  getPendingSigningRequestDetails?: Maybe<SigningRequestDetails>;
  getPrequalApplications: Array<PrequalApplicationOutput>;
  getRefundAmountByReason: Scalars['Float']['output'];
  getRefundTicketById: GetRefundTicketByIdResponse;
  getRejectedCustomers?: Maybe<Array<Maybe<RejectedCustomer>>>;
  getRequiredDocuments?: Maybe<Array<Maybe<RequiredDocument>>>;
  getSFAttorneyEvents: Array<SfEvent>;
  getSFIntakeData?: Maybe<SfIntakeData>;
  getSFLegalServices?: Maybe<Array<Maybe<SfLegalService>>>;
  getSFServices: Array<SfService>;
  getSchedulingPageForUser?: Maybe<Array<Maybe<Page>>>;
  getServicesPaymentDataForOpportunity: ServicesPaymentDataForOpportunity;
  getSuggestedThirdPartyProvider: SuggestedThirdPartyProviderResponse;
  getThirdPartyFinancedAmount: ThirdPartyFinancedAmount;
  getTopDocumentClassifications?: Maybe<Array<Maybe<DocumentClassification>>>;
  getTransactionLoanAmountStatusByOpportunityId: WisetackLoanAmountStatus;
  getUserRoleName?: Maybe<UserRoleName>;
  inHouseGetPrequalApplication?: Maybe<InHousePrequalApplication>;
  isBitlyLoanApplicationSent?: Maybe<Scalars['Boolean']['output']>;
  loanProviderByExternalId: Array<LoanProvider>;
  onboardingProgress?: Maybe<OnboardingProgress>;
  paypalGenerateClientToken: PaypalClientTokenResponse;
  renderContract: RenderContractResponse;
  underwritingFinancingOption?: Maybe<FinancingOption>;
  underwritingFinancingOptionV2?: Maybe<FinancingOption>;
  underwritingGetUserByExternalId?: Maybe<UwUser>;
  underwritingUser?: Maybe<UwUser>;
};


export type QueryAllAttorneysArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryCheckCalendarsAvailabilityArgs = {
  alertAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGenesisGetAccountArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetLoanApplicationArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetLoanTermsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetPrequalApplicationArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetAccountStatusesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetAccountsForAchArgs = {
  input: AccountsForAchInput;
};


export type QueryGetActiveCasesFiltersArgs = {
  activeFilters?: InputMaybe<CasesFilterInput>;
};


export type QueryGetAdminArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetAdminNotificationsArgs = {
  filter: CustomerNotificationInput;
  orderBy?: InputMaybe<NotificationsOrderByInput>;
};


export type QueryGetAdminsByTeamsArgs = {
  teams: Array<InputMaybe<Team>>;
};


export type QueryGetAllFinancingOptionsArgs = {
  input: GetUnderwritingFinancingInput;
};


export type QueryGetAppConfigurationsArgs = {
  input: GetAppConfigurationsInput;
};


export type QueryGetAppInstallationsInfoArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetAppPrivateConfigurationsArgs = {
  input: GetAppPrivateConfigurationsInput;
};


export type QueryGetAppPublicConfigurationsArgs = {
  input: GetAppPublicConfigurationsInput;
};


export type QueryGetAttorneyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCalendarsAvailabilityArgs = {
  input: GetCalendarsAvailabilityInput;
};


export type QueryGetCalendarsAvailabilityByUrlArgs = {
  input: Array<InputMaybe<Scalars['String']['input']>>;
  options?: InputMaybe<GetCalendarsAvailabilityByUrlOptions>;
};


export type QueryGetCaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCaseByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryGetCaseCostTicketDataByIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetCaseDocumentArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryGetCaseDocumentFormByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetCaseDocumentsArgs = {
  filter: CaseDocumentFilterInput;
};


export type QueryGetCaseDocumentsByCaseIdArgs = {
  caseId: Scalars['ID']['input'];
};


export type QueryGetCaseDocumentsWithFormsArgs = {
  filter: CaseDocumentFilterInput;
};


export type QueryGetCaseEventByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCaseEventsArgs = {
  filter?: InputMaybe<CaseEventsFilterInput>;
};


export type QueryGetCasesArgs = {
  filter: CasesFilterInput;
};


export type QueryGetCoveredCarePrequalDataArgs = {
  input?: InputMaybe<SignedInput>;
};


export type QueryGetCoveredCarePrequalLinkArgs = {
  amountToFinance?: InputMaybe<Scalars['Int']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetCustomerFinancingSummaryArgs = {
  externalId: Scalars['ID']['input'];
  opportunityId: Scalars['ID']['input'];
};


export type QueryGetCustomerNotificationsArgs = {
  filter: CustomerNotificationInput;
};


export type QueryGetCustomerRiskLevelArgs = {
  input: GetCustomerRiskLevelInput;
};


export type QueryGetDecryptedPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type QueryGetFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetFormTemplatesArgs = {
  filter?: InputMaybe<FormTemplatesFilterInput>;
};


export type QueryGetFreeTaxFunnelPdfArgs = {
  isAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  payload: FreeTaxGeneratorPayload;
};


export type QueryGetIdentityVerificationArgs = {
  input: IdentityVerificationInput;
};


export type QueryGetIntakeMeetingSlotsArgs = {
  input: IntakeMeetingSlotsInput;
};


export type QueryGetIsReadyForCaseStatusArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetLeadIdByFunnelActionIdArgs = {
  funnelActionId: Scalars['String']['input'];
};


export type QueryGetLegalServicesArgs = {
  options: GetLegalServicesOptions;
};


export type QueryGetManagerCasesArgs = {
  filter: ManagerCasesFilterInput;
};


export type QueryGetNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOpportunityLinkFieldsArgs = {
  input: OpportunityLinkFieldsInput;
};


export type QueryGetOpportunityLinkFieldsByCaseIdArgs = {
  input: OpportunityLinkFieldsByCaseIdInput;
};


export type QueryGetOpportunityPaymentDataArgs = {
  input: PaymentInput;
};


export type QueryGetOpportunityStageArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetOpportunityThirdPartyPayerFinancingOptionsArgs = {
  input: GetOpportunityThirdPartyPayerFinancingOptionsInput;
};


export type QueryGetPaginatedDiyUsersArgs = {
  options?: InputMaybe<GetPaginatedDiyUsersParams>;
};


export type QueryGetPaidDownPaymentAmountArgs = {
  input: GetPaidDownPaymentsAmountInput;
};


export type QueryGetPaidLssAmountArgs = {
  input: GetPaidLssAmountInput;
};


export type QueryGetPastTransactionsArgs = {
  accountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPaymentAgreementArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentPlanSetupAccountDataArgs = {
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentsOnHoldArgs = {
  accountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPayoutItemsArgs = {
  input?: InputMaybe<GetPayoutItemsInput>;
};


export type QueryGetPendingSigningRequestDetailsArgs = {
  input: GetPendingSigningRequestDetailsInput;
};


export type QueryGetPrequalApplicationsArgs = {
  lenderName?: InputMaybe<LenderName>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetRefundAmountByReasonArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  reason: TransactionRefundReason;
  signature: Scalars['String']['input'];
};


export type QueryGetRefundTicketByIdArgs = {
  input: GetRefundTicketByIdInput;
};


export type QueryGetRequiredDocumentsArgs = {
  options: GetRequiredDocumentsOptions;
};


export type QueryGetSfAttorneyEventsArgs = {
  attorneyId: Scalars['ID']['input'];
};


export type QueryGetSfIntakeDataArgs = {
  opportunityId: Scalars['ID']['input'];
};


export type QueryGetSfLegalServicesArgs = {
  input: LegalServicesInput;
};


export type QueryGetSfServicesArgs = {
  input: SfServicesInput;
};


export type QueryGetSchedulingPageForUserArgs = {
  input: Scalars['String']['input'];
};


export type QueryGetServicesPaymentDataForOpportunityArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetSuggestedThirdPartyProviderArgs = {
  input: GetSuggestedThirdPartyProviderInput;
};


export type QueryGetThirdPartyFinancedAmountArgs = {
  input: GetThirdPartyFinancedAmountInput;
};


export type QueryGetTransactionLoanAmountStatusByOpportunityIdArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetUserRoleNameArgs = {
  salesforceId: Scalars['String']['input'];
};


export type QueryInHouseGetPrequalApplicationArgs = {
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryIsBitlyLoanApplicationSentArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryLoanProviderByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryOnboardingProgressArgs = {
  input: GetOnboardingProgressInput;
};


export type QueryRenderContractArgs = {
  input: RenderContractInput;
};


export type QueryUnderwritingFinancingOptionArgs = {
  input: UnderwritingFinancingOptionInput;
};


export type QueryUnderwritingFinancingOptionV2Args = {
  input: UnderwritingFinancingOptionInput;
};


export type QueryUnderwritingGetUserByExternalIdArgs = {
  input: GetUserByExternalIdInput;
};


export type QueryUnderwritingUserArgs = {
  input: GetUserInput;
};

export type RecommendedService = {
  details?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type RecommendedServiceDetails = {
  __typename?: 'RecommendedServiceDetails';
  details?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
};

export type RecurringPlan = PaymentPlanComponent & {
  __typename?: 'RecurringPlan';
  amount: Scalars['Int']['output'];
  autopay?: Maybe<Autopay>;
  autopayAccepted?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<BillingCycleOutput>;
  description: Scalars['String']['output'];
  downPayments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  numOfPayments: Scalars['Int']['output'];
  overdueBalance?: Maybe<Scalars['Int']['output']>;
  paidBalance?: Maybe<Scalars['Int']['output']>;
  paymentFrequency: PaymentFrequency;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
  servicer?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  toDate: Scalars['Date']['output'];
  totalAmount: Scalars['Int']['output'];
};

export type RefundLoanApplicationInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  refundAmount: Scalars['Float']['input'];
};

export type RefundLoanInput = {
  amountInCents?: InputMaybe<Scalars['Int']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  lenderName: LenderName;
  loanId: Scalars['String']['input'];
};

export type RefundLoanOutput = {
  __typename?: 'RefundLoanOutput';
  success: Scalars['Boolean']['output'];
};

export enum RefundReason {
  CloseLostOpp = 'Close_Lost_Opp',
  DisengagementRequest = 'Disengagement_Request',
  DowngradeOrScopeSwap = 'Downgrade_Or_Scope_Swap',
  Downpayment = 'Downpayment',
  Escalation = 'Escalation',
  LssFee = 'LSS_Fee',
  NegativeReviewOrEscalation = 'Negative_Review_Or_Escalation',
  PaymentDateIssue = 'Payment_Date_Issue',
  Subzee = 'Subzee'
}

export type RefundTransaction = {
  amountToRefund: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  loanId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider: TransactionPaymentProvider;
  status: TransactionsStatus;
};

export type RefundTransactionResult = {
  __typename?: 'RefundTransactionResult';
  id: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RegisterStripeCustomerInput = {
  amountDue?: InputMaybe<Scalars['String']['input']>;
  customerDetails: CustomerDetails;
  description?: InputMaybe<StripeDescription>;
  metadata?: InputMaybe<StripeMetadata>;
};

export type RegisterStripeCustomerResponse = {
  __typename?: 'RegisterStripeCustomerResponse';
  clientSecret: Scalars['String']['output'];
};

export type RejectedCustomer = {
  __typename?: 'RejectedCustomer';
  customerRejectionType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  practiceArea?: Maybe<PracticeArea>;
};

export type RejectedLssSummaryInput = {
  caseId: Scalars['ID']['input'];
  meetingTime: Scalars['DateTime']['input'];
  other?: InputMaybe<Scalars['String']['input']>;
  reason: LssRejectionReason;
};

export type RenderContractInput = {
  contractType: ContractType;
  opportunityId: Scalars['String']['input'];
  tenant?: InputMaybe<ElTenant>;
};

export type RenderContractResponse = {
  __typename?: 'RenderContractResponse';
  contractHtml: Scalars['String']['output'];
};

export type RequiredDocument = {
  __typename?: 'RequiredDocument';
  classification: DocumentClassification;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalServiceExternalId?: Maybe<Scalars['String']['output']>;
};

export type RescheduleLssInput = {
  caseId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type RescheduleLssMeetingInput = {
  caseId: Scalars['ID']['input'];
  meetingTime: Scalars['DateTime']['input'];
  reason: Scalars['String']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export type RunCoveredCarePrequalInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type RunCreditCheckInput = {
  address: CreditCheckAddressInput;
  dateOfBirth: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SfEvent = {
  __typename?: 'SFEvent';
  assignedAttorney: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type SfIntakeData = {
  __typename?: 'SFIntakeData';
  charges?: Maybe<Scalars['String']['output']>;
  clientFirstName?: Maybe<Scalars['String']['output']>;
  clientLastName: Scalars['String']['output'];
  county: Scalars['String']['output'];
  courtCaseId?: Maybe<Scalars['String']['output']>;
  courtDate?: Maybe<Scalars['String']['output']>;
  criticalDates?: Maybe<Array<SfIntakeDataCriticalDate>>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  inTakeAgentName?: Maybe<Scalars['String']['output']>;
  inTakeDate?: Maybe<Scalars['String']['output']>;
  inTakeNotes?: Maybe<Scalars['String']['output']>;
  opposingPartyFirstName?: Maybe<Scalars['String']['output']>;
  opposingPartyLastName?: Maybe<Scalars['String']['output']>;
  practice: Scalars['String']['output'];
  priors?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type SfIntakeDataCriticalDate = {
  __typename?: 'SFIntakeDataCriticalDate';
  criticalDate: Scalars['String']['output'];
  criticalDateType: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type SfLegalService = {
  __typename?: 'SFLegalService';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SfService = {
  __typename?: 'SFService';
  addendumProduct?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serviceCode?: Maybe<Scalars['String']['output']>;
};

export type SfServicesInput = {
  practice: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type SfUpdateEventInput = {
  eventId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type ScheduleLssMeetingReminderInput = {
  caseId: Scalars['ID']['input'];
};

export type ScheduledMeeting = {
  __typename?: 'ScheduledMeeting';
  createdAt?: Maybe<Scalars['String']['output']>;
  meetingTime?: Maybe<Scalars['String']['output']>;
};

export type SendAddServicesRequestInput = {
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['PhoneNumber']['input'];
};

export type SendAttorneyFeedbackInput = {
  feedback: Scalars['String']['input'];
};

export type SendPayoutMailResult = {
  __typename?: 'SendPayoutMailResult';
  attorneyId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SendPayoutMailsInput = {
  billingDate: Scalars['Date']['input'];
};

export type SendPayoutMailsResponse = {
  __typename?: 'SendPayoutMailsResponse';
  billingDate: Scalars['Date']['output'];
  results: Array<Maybe<SendPayoutMailResult>>;
};

export type ServiceOnOpportunity = {
  __typename?: 'ServiceOnOpportunity';
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priceAfterDiscountInCents: Scalars['Int']['output'];
  priceInCents: Scalars['Int']['output'];
};

export type ServicePaymentData = {
  __typename?: 'ServicePaymentData';
  downgrade: Scalars['Boolean']['output'];
  serviceName: Scalars['String']['output'];
  servicePrice: Scalars['String']['output'];
  servicePriceAfterDiscount: Scalars['String']['output'];
};

export type ServicesPaymentDataForOpportunity = {
  __typename?: 'ServicesPaymentDataForOpportunity';
  opportunityId: Scalars['String']['output'];
  servicesPaymentData?: Maybe<Array<Maybe<ServicePaymentData>>>;
  totalBalance?: Maybe<Scalars['Int']['output']>;
  totalDiscount?: Maybe<Scalars['Int']['output']>;
};

export type SetNotificationsAllowedInput = {
  appBundleId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  isAllowed: Scalars['Boolean']['input'];
};

export type SetUserInput = {
  address?: InputMaybe<UwAddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
};

export type SignedInput = {
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type SigningRequestDetails = {
  __typename?: 'SigningRequestDetails';
  completedBy: Array<Maybe<Scalars['String']['output']>>;
  contractId: Scalars['String']['output'];
  contractTemplateName: Scalars['String']['output'];
  contractVersion: Scalars['String']['output'];
  downloadLink: Scalars['String']['output'];
  renderData: Scalars['String']['output'];
  signingId: Scalars['String']['output'];
  signingUrl: Scalars['String']['output'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortInput = {
  direction?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export type SpecificCaseEventsFilterInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Stage = {
  __typename?: 'Stage';
  /** @deprecated Not in use */
  date?: Maybe<Scalars['Date']['output']>;
  value: StageEnum;
};

export enum StageEnum {
  /** @deprecated Replaced with new enums */
  AttDraftFormPrep = 'ATT_DRAFT_FORM_PREP',
  /** @deprecated Replaced with new enums */
  CaseClosure = 'CASE_CLOSURE',
  /** @deprecated Replaced with new enums */
  CaseCompleted = 'CASE_COMPLETED',
  /** @deprecated Replaced with new enums */
  CaseOnboarding = 'CASE_ONBOARDING',
  /** @deprecated Replaced with new enums */
  CompleteScope = 'COMPLETE_SCOPE',
  /** @deprecated Replaced with new enums */
  CourtHearing = 'COURT_HEARING',
  /** @deprecated Replaced with new enums */
  CourtPrep = 'COURT_PREP',
  /** @deprecated Replaced with new enums */
  Disengaged = 'DISENGAGED',
  /** @deprecated Replaced with new enums */
  DocsFiling = 'DOCS_FILING',
  /** @deprecated Replaced with new enums */
  DocumentService = 'DOCUMENT_SERVICE',
  /** @deprecated Replaced with new enums */
  FilePetitionToCourt = 'FILE_PETITION_TO_COURT',
  /** @deprecated Replaced with new enums */
  FileProofToCourt = 'FILE_PROOF_TO_COURT',
  /** @deprecated Replaced with new enums */
  FormPrep = 'FORM_PREP',
  /** @deprecated Replaced with new enums */
  HearingPrep = 'HEARING_PREP',
  IntroductionCall = 'INTRODUCTION_CALL',
  /** @deprecated Replaced with new enums */
  LpPacketReady = 'LP_PACKET_READY',
  /** @deprecated Replaced with new enums */
  LpPacketSent = 'LP_PACKET_SENT',
  NotStarted = 'NOT_STARTED',
  /** @deprecated Replaced with new enums */
  ObtainConformedCopies = 'OBTAIN_CONFORMED_COPIES',
  /** @deprecated Replaced with new enums */
  Offboarding = 'OFFBOARDING',
  Onboarding = 'ONBOARDING',
  /** @deprecated Replaced with new enums */
  OnboardingCall = 'ONBOARDING_CALL',
  /** @deprecated Replaced with new enums */
  OnboardingClientForms = 'ONBOARDING_CLIENT_FORMS',
  /** @deprecated Replaced with new enums */
  OnboardingLegalForms = 'ONBOARDING_LEGAL_FORMS',
  /** @deprecated Replaced with new enums */
  PreOnboarding = 'PRE_ONBOARDING',
  Rejected = 'REJECTED',
  /** @deprecated Replaced with new enums */
  ServeToOpposingParty = 'SERVE_TO_OPPOSING_PARTY',
  ServiceDelivery = 'SERVICE_DELIVERY',
  WrappingUp = 'WRAPPING_UP'
}

export type StartLoanApplicationInput = {
  amountInCents?: InputMaybe<Scalars['Int']['input']>;
  lenderName: LenderName;
  opportunityId: Scalars['String']['input'];
  prequalApplicationId?: InputMaybe<Scalars['String']['input']>;
};

export type StartLoanApplicationOutput = {
  __typename?: 'StartLoanApplicationOutput';
  id: Scalars['String']['output'];
  lender: LenderName;
  uri: Scalars['String']['output'];
};

export type StripeData = {
  __typename?: 'StripeData';
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type StripeDescription = {
  deal_id?: InputMaybe<Scalars['String']['input']>;
  practiceArea?: InputMaybe<Scalars['String']['input']>;
};

export type StripeMetadata = {
  deal_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  hubspot_link?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitRequestForDpPolicyChangeInput = {
  cardType: Scalars['String']['input'];
  minDpAmount: Scalars['Float']['input'];
  minDpPercent: Scalars['Float']['input'];
  opportunityId: Scalars['String']['input'];
  policyMinDpPercent: Scalars['Float']['input'];
  reason: Scalars['String']['input'];
  totalBookings: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type SubmitRequestForDpPolicyChangeResult = {
  __typename?: 'SubmitRequestForDpPolicyChangeResult';
  approved: Scalars['Boolean']['output'];
};

export type SuggestedThirdPartyProviderResponse = {
  __typename?: 'SuggestedThirdPartyProviderResponse';
  creditCheckErrorCode?: Maybe<CreditCheckErrorCode>;
  provider?: Maybe<ThirdPartyProviderType>;
};

export type SyncCaseFromOpportunityInput = {
  externalId: Scalars['String']['input'];
};

export type SyncClosedCaseInput = {
  caseId: Scalars['ID']['input'];
};

export type SyncDeviceResponse = {
  __typename?: 'SyncDeviceResponse';
  isSupported?: Maybe<Scalars['Boolean']['output']>;
};

export type SyncDocumentToDriveInput = {
  documentId: Scalars['ID']['input'];
  options?: InputMaybe<SyncDocumentToDriveOptions>;
};

export type SyncDocumentToDriveOptions = {
  isNotifyAttorneyOnAttorneyFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyAttorneyOnCaseFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyParalegalOnAttorneyFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyParalegalOnCaseFolder?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Team {
  Payments = 'PAYMENTS'
}

export type ThirdPartyFinancedAmount = {
  __typename?: 'ThirdPartyFinancedAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export enum ThirdPartyProviderType {
  Affirm = 'Affirm',
  Concora = 'Concora',
  Covered = 'Covered',
  Genesis = 'Genesis',
  InHouse = 'InHouse',
  Klarna = 'Klarna',
  Paypal = 'Paypal',
  Wisetack = 'Wisetack'
}

export type Ticket = {
  __typename?: 'Ticket';
  comments: Array<TicketComment>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
};

export type TicketComment = {
  __typename?: 'TicketComment';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sentAt: Scalars['String']['output'];
  sentByUser: Scalars['Boolean']['output'];
};

export enum TouchpointOptions {
  Call = 'CALL',
  Email = 'EMAIL',
  Slack = 'SLACK',
  Sms = 'SMS'
}

export type TransactionPaymentMethod = {
  __typename?: 'TransactionPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  type: TransactionPaymentMethodType;
};

export enum TransactionPaymentMethodType {
  Ach = 'Ach',
  Bank = 'Bank',
  Card = 'Card',
  Credit = 'Credit',
  Unknown = 'Unknown',
  Wire = 'Wire'
}

export enum TransactionPaymentProvider {
  Affirm = 'Affirm',
  Covered = 'Covered',
  Genesis = 'Genesis',
  Klarna = 'Klarna',
  Paypal = 'Paypal',
  Peach = 'Peach',
  Stripe = 'Stripe',
  Subzee = 'Subzee',
  Wisetack = 'Wisetack'
}

export enum TransactionRefundReason {
  CloseLostOpp = 'Close_Lost_Opp',
  DisengagementRequest = 'Disengagement_Request',
  DowngradeOrScopeSwap = 'Downgrade_Or_Scope_Swap',
  Downpayment = 'Downpayment',
  Escalation = 'Escalation',
  LssFee = 'LSS_Fee',
  NegativeReviewOrEscalation = 'Negative_Review_Or_Escalation',
  PaymentDateIssue = 'Payment_Date_Issue',
  Subzee = 'Subzee'
}

export enum TransactionsProvider {
  Affirm = 'Affirm',
  Covered = 'Covered',
  Genesis = 'Genesis',
  Klarna = 'Klarna',
  Marble = 'MARBLE',
  Pay = 'PAY',
  Paypal = 'Paypal',
  Ric = 'RIC',
  Wisetack = 'Wisetack'
}

export enum TransactionsStatus {
  Completed = 'Completed',
  Disputed = 'Disputed',
  Failed = 'Failed',
  FullRefund = 'FullRefund',
  PartialRefund = 'PartialRefund',
  Pending = 'Pending'
}

export enum TransactionsType {
  Credit = 'Credit',
  DownPayment = 'DownPayment',
  FilingServingFee = 'FilingServingFee',
  Lss = 'Lss',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  OneTime = 'OneTime'
}

export type TwiceMonthly = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type TwiceMonthlyOutput = {
  __typename?: 'TwiceMonthlyOutput';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export enum TypeOfFee {
  Filing = 'FILING',
  FilingAndServing = 'FILING_AND_SERVING',
  Serving = 'SERVING'
}

export type UtmInput = {
  adgroupId?: InputMaybe<Scalars['String']['input']>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marbleCategory?: InputMaybe<Scalars['String']['input']>;
  matchLocation?: InputMaybe<Scalars['String']['input']>;
  matchType?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  textAdgroupId?: InputMaybe<Scalars['String']['input']>;
  textCampaignId?: InputMaybe<Scalars['String']['input']>;
  ttclid?: InputMaybe<Scalars['String']['input']>;
};

export type UwAddress = {
  __typename?: 'UWAddress';
  apartment?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type UwAddressInput = {
  apartment?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UwUser = {
  __typename?: 'UWUser';
  address?: Maybe<UwAddress>;
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  monthlyIncome?: Maybe<Scalars['Int']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  thirdPartyPayerForExternalId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnderwritingFinancingOptionInput = {
  agentId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UpdateAttorneyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<AddressInput>;
  attorneyType?: InputMaybe<AttorneyType>;
  barLicenseExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  barLicenseId?: InputMaybe<Scalars['String']['input']>;
  barNumber?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  cafNumber?: InputMaybe<Scalars['String']['input']>;
  calendlyEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  calendlyLink?: InputMaybe<Scalars['String']['input']>;
  caseCapacity?: InputMaybe<Scalars['Int']['input']>;
  casesOffLimits?: InputMaybe<Array<InputMaybe<CasesOffLimits>>>;
  casesOffLimitsOther?: InputMaybe<Scalars['String']['input']>;
  conservatorship?: InputMaybe<Scalars['Boolean']['input']>;
  contestedDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  contractLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  familyCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  guardianship?: InputMaybe<Scalars['Boolean']['input']>;
  handlesCases?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inHouse?: InputMaybe<Scalars['Boolean']['input']>;
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  juvenileCrime?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  licensingJurisdiction?: InputMaybe<Scalars['String']['input']>;
  litigation?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Scalars['JSON']['input']>;
  marbleId?: InputMaybe<Scalars['String']['input']>;
  matrimonialCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<NameInput>;
  numberOfActiveCases?: InputMaybe<Scalars['Int']['input']>;
  numberOfAssociates?: InputMaybe<Scalars['Int']['input']>;
  numberOfLegalSecretaries?: InputMaybe<Scalars['Int']['input']>;
  numberOfParalegals?: InputMaybe<Scalars['Int']['input']>;
  performsLSS?: InputMaybe<Scalars['Boolean']['input']>;
  phones?: InputMaybe<PhonesInput>;
  practiceAreas?: InputMaybe<Array<PracticeArea>>;
  prenup?: InputMaybe<Scalars['Boolean']['input']>;
  ptin?: InputMaybe<Scalars['String']['input']>;
  shortDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  toggleHandlesCasesAt?: InputMaybe<Scalars['Date']['input']>;
  togglePerformsLSSAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateCaseCourtInfoInput = {
  caseId: Scalars['ID']['input'];
  caseNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCaseDocumentFormFieldsInput = {
  fields: Array<CaseDocumentFormFieldsUpdateInput>;
  id: Scalars['ID']['input'];
};

export type UpdateCaseDocumentFormStatusInput = {
  id: Scalars['ID']['input'];
  newStatus: CaseDocumentStatus;
};

export type UpdateCaseDocumentTypeInput = {
  classificationId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};

export type UpdateCaseEventInput = {
  arbitraryLocation?: InputMaybe<Scalars['String']['input']>;
  caseId?: InputMaybe<Scalars['ID']['input']>;
  eventSubtype?: InputMaybe<CaseEventSubtype>;
  eventType?: InputMaybe<CaseEventType>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<CaseEventLocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  occursAtTime?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<CaseEventSource>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  virtualLocation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCaseTaskInput = {
  id: Scalars['String']['input'];
  status: CaseTaskStatusInput;
};

export type UpdateDefaultPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type UpdateDocumentSharingWithClientPayload = {
  id: Scalars['ID']['input'];
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFormTemplateInput = {
  county?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<PracticeArea>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLegalProductInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSfRefundTicketInput = {
  accountId: Scalars['String']['input'];
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  refundAmountInDollars?: InputMaybe<Scalars['Float']['input']>;
  refundReason?: InputMaybe<RefundReason>;
  signature: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
};

export type UpdateUserInput = {
  address?: InputMaybe<UwAddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWebLeadInput = {
  AdditionalQuestion__c?: InputMaybe<Scalars['String']['input']>;
  Funnel_Parameters__c?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateWhatIsNextInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  value: WhatIsNextEnum;
};

export type UpdatedCaseDocumentStatus = {
  __typename?: 'UpdatedCaseDocumentStatus';
  id: Scalars['ID']['output'];
  status: CaseDocumentStatus;
};

export type UpsertCaseTaskResult = {
  __typename?: 'UpsertCaseTaskResult';
  case?: Maybe<Case>;
  caseTask: CaseTask;
};

export type UpsertDiyFunnelData = {
  customerId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAfterOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  isAlreadyViewed?: InputMaybe<Scalars['Boolean']['input']>;
  selectedService?: InputMaybe<Scalars['String']['input']>;
  stepsData?: InputMaybe<Scalars['JSON']['input']>;
};

export type UserFinancingOptionOrNull = {
  __typename?: 'UserFinancingOptionOrNull';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  downPayment?: Maybe<DownPayment>;
  installment?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<FinancingOptionProvider>;
  status: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserRoleName = {
  __typename?: 'UserRoleName';
  name: Scalars['String']['output'];
};

export enum UserType {
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export type UsersWithCalendarIssues = {
  __typename?: 'UsersWithCalendarIssues';
  calendarsNotConfigured: Array<Maybe<Scalars['String']['output']>>;
  calendarsToReconnect: Array<Maybe<Scalars['String']['output']>>;
};

export enum VerificationIdentifierType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type VoucherDiscount = {
  __typename?: 'VoucherDiscount';
  code?: Maybe<Scalars['String']['output']>;
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type WhatIsNext = {
  __typename?: 'WhatIsNext';
  date?: Maybe<Scalars['Date']['output']>;
  value: WhatIsNextEnum;
};

export enum WhatIsNextEnum {
  CaseOnboarding = 'CASE_ONBOARDING',
  CourtHearing = 'COURT_HEARING',
  DocumentSignature = 'DOCUMENT_SIGNATURE',
  DocumentUpload = 'DOCUMENT_UPLOAD',
  EmptyState = 'EMPTY_STATE',
  MeetingWithAnAttorney = 'MEETING_WITH_AN_ATTORNEY',
  MeetingWithTheCaseManager = 'MEETING_WITH_THE_CASE_MANAGER',
  PendingOnboardingDocVerification = 'PENDING_ONBOARDING_DOC_VERIFICATION'
}

export type WireTransferMethod = PaymentMethod & {
  __typename?: 'WireTransferMethod';
  bankName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type WisetackLoanAmountStatus = {
  __typename?: 'WisetackLoanAmountStatus';
  status: LoanAmountStatus;
};

export type WisetackLoanLink = {
  __typename?: 'WisetackLoanLink';
  url: Scalars['String']['output'];
};

export type WisetackPrequalLink = {
  __typename?: 'WisetackPrequalLink';
  url: Scalars['String']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Owner: ( Admin ) | ( Omit<Attorney, 'address' | 'case' | 'cases' | 'earning' | 'earnings' | 'lssCase' | 'lssCases' | 'lssCasesByView' | 'pendingEarnings'> & { address?: Maybe<_RefType['Address']>, case?: Maybe<_RefType['Case']>, cases?: Maybe<Array<Maybe<_RefType['Case']>>>, earning?: Maybe<_RefType['Earning']>, earnings: Array<Maybe<_RefType['Earning']>>, lssCase?: Maybe<_RefType['Case']>, lssCases?: Maybe<Array<Maybe<_RefType['Case']>>>, lssCasesByView?: Maybe<Array<Maybe<_RefType['Case']>>>, pendingEarnings?: Maybe<_RefType['Earning']> } ) | ( Omit<Customer, 'address' | 'customerState' | 'payment' | 'paymentAccount'> & { address?: Maybe<_RefType['Address']>, customerState?: Maybe<_RefType['CustomerState']>, payment?: Maybe<_RefType['Payment']>, paymentAccount?: Maybe<_RefType['PaymentAccount']> } );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  ICaseDocument: ( Omit<CaseDocument, 'case'> & { case?: Maybe<_RefType['Case']> } ) | ( Omit<CaseDocumentForm, 'case'> & { case?: Maybe<_RefType['Case']> } );
  IFormTemplateField: ( CaseDocumentFormField ) | ( FormTemplateField );
  PaymentMethod: ( AchData ) | ( Card ) | ( WireTransferMethod );
  PaymentPlanComponent: ( OneTimePlan ) | ( RecurringPlan );
  PayoutItem: ( Omit<Addendum, 'attorney' | 'case'> & { attorney?: Maybe<_RefType['Attorney']>, case?: Maybe<_RefType['Case']> } ) | ( Omit<Adjustment, 'attorney' | 'case'> & { attorney?: Maybe<_RefType['Attorney']>, case?: Maybe<_RefType['Case']> } ) | ( Omit<Bonus, 'attorney'> & { attorney?: Maybe<_RefType['Attorney']> } ) | ( Omit<Clawback, 'attorney' | 'case'> & { attorney?: Maybe<_RefType['Attorney']>, case?: Maybe<_RefType['Case']> } ) | ( Omit<Installment, 'attorney' | 'case'> & { attorney?: Maybe<_RefType['Attorney']>, case?: Maybe<_RefType['Case']> } );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountForAch: ResolverTypeWrapper<AccountForAch>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  AccountStatus: ResolverTypeWrapper<AccountStatus>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  AccountsForAchInput: AccountsForAchInput;
  AchData: ResolverTypeWrapper<AchData>;
  ActionType: ActionType;
  ActivateLoanInput: ActivateLoanInput;
  AddCaseEventInput: AddCaseEventInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  AddCaseTaskInput: AddCaseTaskInput;
  AddDocumentClassificationPayload: AddDocumentClassificationPayload;
  AddPaymentInput: AddPaymentInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  AddRequiredDocumentPayload: AddRequiredDocumentPayload;
  Addendum: ResolverTypeWrapper<Omit<Addendum, 'attorney' | 'case'> & { attorney?: Maybe<ResolversTypes['Attorney']>, case?: Maybe<ResolversTypes['Case']> }>;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  Adjustment: ResolverTypeWrapper<Omit<Adjustment, 'attorney' | 'case'> & { attorney?: Maybe<ResolversTypes['Attorney']>, case?: Maybe<ResolversTypes['Case']> }>;
  Admin: ResolverTypeWrapper<Admin>;
  AdminOnboardingStatus: AdminOnboardingStatus;
  AffirmProviderEventInput: AffirmProviderEventInput;
  AffirmType: AffirmType;
  App: App;
  AppClients: AppClients;
  AppConfigurations: ResolverTypeWrapper<AppConfigurations>;
  AppInstallation: ResolverTypeWrapper<AppInstallation>;
  AppInstallationInput: AppInstallationInput;
  Attorney: ResolverTypeWrapper<Omit<Attorney, 'address' | 'case' | 'cases' | 'earning' | 'earnings' | 'lssCase' | 'lssCases' | 'lssCasesByView' | 'pendingEarnings'> & { address?: Maybe<ResolversTypes['Address']>, case?: Maybe<ResolversTypes['Case']>, cases?: Maybe<Array<Maybe<ResolversTypes['Case']>>>, earning?: Maybe<ResolversTypes['Earning']>, earnings: Array<Maybe<ResolversTypes['Earning']>>, lssCase?: Maybe<ResolversTypes['Case']>, lssCases?: Maybe<Array<Maybe<ResolversTypes['Case']>>>, lssCasesByView?: Maybe<Array<Maybe<ResolversTypes['Case']>>>, pendingEarnings?: Maybe<ResolversTypes['Earning']> }>;
  AttorneyType: AttorneyType;
  AuthorizeTransactionPayload: AuthorizeTransactionPayload;
  Autopay: ResolverTypeWrapper<Autopay>;
  BatchCreatePreAuthLinkResponse: ResolverTypeWrapper<Omit<BatchCreatePreAuthLinkResponse, 'customer'> & { customer?: Maybe<ResolversTypes['Customer']> }>;
  BatchDeletePactsafeContractsInput: BatchDeletePactsafeContractsInput;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  BillingCycle: BillingCycle;
  BillingCycleOutput: ResolverTypeWrapper<BillingCycleOutput>;
  Bonus: ResolverTypeWrapper<Omit<Bonus, 'attorney'> & { attorney?: Maybe<ResolversTypes['Attorney']> }>;
  BraintreeAddress: BraintreeAddress;
  BraintreeCurrencyAmount: BraintreeCurrencyAmount;
  BrazeCustomerInput: BrazeCustomerInput;
  BrazeMigrationInput: BrazeMigrationInput;
  BrazeMigrationResponse: ResolverTypeWrapper<BrazeMigrationResponse>;
  BrazeNotification: BrazeNotification;
  BrazeProperties: BrazeProperties;
  CalendarAvailability: ResolverTypeWrapper<CalendarAvailability>;
  CalendlyEvent: CalendlyEvent;
  CallLog: ResolverTypeWrapper<CallLog>;
  CallLogCreatedBy: ResolverTypeWrapper<CallLogCreatedBy>;
  CallLogInput: CallLogInput;
  CallLogSource: CallLogSource;
  CancelAutopayRequest: CancelAutopayRequest;
  CancelEventInput: CancelEventInput;
  CancelLoanApplicationInput: CancelLoanApplicationInput;
  CancelPaymentOnHoldInput: CancelPaymentOnHoldInput;
  CancelScheduledEventInput: CancelScheduledEventInput;
  CancelScheduledEventsInput: CancelScheduledEventsInput;
  CancelScheduledEventsOptions: CancelScheduledEventsOptions;
  CancelScheduledEventsResponse: ResolverTypeWrapper<CancelScheduledEventsResponse>;
  CancelledEvent: ResolverTypeWrapper<CancelledEvent>;
  CapturePaymentOnHoldInput: CapturePaymentOnHoldInput;
  CaptureTransactionResponse: ResolverTypeWrapper<CaptureTransactionResponse>;
  Card: ResolverTypeWrapper<Card>;
  Case: ResolverTypeWrapper<Omit<Case, 'changeLogs' | 'customer' | 'documents' | 'events' | 'lssAttorney' | 'responsibleAttorney' | 'stageTasksInfo' | 'tasks'> & { changeLogs?: Maybe<Array<Maybe<ResolversTypes['ChangeLog']>>>, customer?: Maybe<ResolversTypes['Customer']>, documents: Array<Maybe<ResolversTypes['CaseDocument']>>, events?: Maybe<Array<Maybe<ResolversTypes['CaseEvent']>>>, lssAttorney?: Maybe<ResolversTypes['Attorney']>, responsibleAttorney?: Maybe<ResolversTypes['Attorney']>, stageTasksInfo?: Maybe<ResolversTypes['CaseTasksInfo']>, tasks?: Maybe<Array<Maybe<ResolversTypes['CaseTask']>>> }>;
  CaseCostTicketData: ResolverTypeWrapper<CaseCostTicketData>;
  CaseDocument: ResolverTypeWrapper<Omit<CaseDocument, 'case'> & { case?: Maybe<ResolversTypes['Case']> }>;
  CaseDocumentCategory: CaseDocumentCategory;
  CaseDocumentFilterInput: CaseDocumentFilterInput;
  CaseDocumentForm: ResolverTypeWrapper<Omit<CaseDocumentForm, 'case'> & { case?: Maybe<ResolversTypes['Case']> }>;
  CaseDocumentFormField: ResolverTypeWrapper<CaseDocumentFormField>;
  CaseDocumentFormFieldsUpdateInput: CaseDocumentFormFieldsUpdateInput;
  CaseDocumentInput: CaseDocumentInput;
  CaseDocumentSharingOptions: ResolverTypeWrapper<CaseDocumentSharingOptions>;
  CaseDocumentSource: CaseDocumentSource;
  CaseDocumentStatus: CaseDocumentStatus;
  CaseDocumentType: CaseDocumentType;
  CaseEvent: ResolverTypeWrapper<Omit<CaseEvent, 'case'> & { case?: Maybe<ResolversTypes['Case']> }>;
  CaseEventCreatedBy: ResolverTypeWrapper<CaseEventCreatedBy>;
  CaseEventLocation: ResolverTypeWrapper<CaseEventLocation>;
  CaseEventLocationInput: CaseEventLocationInput;
  CaseEventSource: CaseEventSource;
  CaseEventSubtype: CaseEventSubtype;
  CaseEventType: CaseEventType;
  CaseEventsFilterInput: CaseEventsFilterInput;
  CaseFilters: ResolverTypeWrapper<CaseFilters>;
  CaseLegalProduct: ResolverTypeWrapper<CaseLegalProduct>;
  CaseLegalService: ResolverTypeWrapper<CaseLegalService>;
  CaseLocation: ResolverTypeWrapper<CaseLocation>;
  CaseMeeting: ResolverTypeWrapper<CaseMeeting>;
  CaseMilestone: CaseMilestone;
  CaseNote: ResolverTypeWrapper<CaseNote>;
  CaseNoteInput: CaseNoteInput;
  CaseOfferCriticalDate: ResolverTypeWrapper<CaseOfferCriticalDate>;
  CaseOfferCriticalDateType: CaseOfferCriticalDateType;
  CaseRequiredDocumentsData: ResolverTypeWrapper<CaseRequiredDocumentsData>;
  CaseService: ResolverTypeWrapper<CaseService>;
  CaseStatus: CaseStatus;
  CaseSubentityFailedObject: ResolverTypeWrapper<CaseSubentityFailedObject>;
  CaseSubentityMigratedObject: ResolverTypeWrapper<CaseSubentityMigratedObject>;
  CaseSubentityMigrationResponse: ResolverTypeWrapper<CaseSubentityMigrationResponse>;
  CaseTask: ResolverTypeWrapper<Omit<CaseTask, 'owner'> & { owner?: Maybe<ResolversTypes['Owner']> }>;
  CaseTaskOwnerType: CaseTaskOwnerType;
  CaseTaskStatus: ResolverTypeWrapper<CaseTaskStatus>;
  CaseTaskStatusInput: CaseTaskStatusInput;
  CaseTaskStatusValue: CaseTaskStatusValue;
  CaseTasksInfo: ResolverTypeWrapper<Omit<CaseTasksInfo, 'currentTask'> & { currentTask?: Maybe<ResolversTypes['CaseTask']> }>;
  CaseType: CaseType;
  CaseUpdate: ResolverTypeWrapper<CaseUpdate>;
  CaseUpdateInput: CaseUpdateInput;
  CaseUpdateInputWithTags: CaseUpdateInputWithTags;
  CaseUpdateSharingOptions: ResolverTypeWrapper<CaseUpdateSharingOptions>;
  CaseUpdateSharingOptionsInput: CaseUpdateSharingOptionsInput;
  CaseUpdateSources: CaseUpdateSources;
  CasesFilterInput: CasesFilterInput;
  CasesOffLimits: CasesOffLimits;
  CasesResults: ResolverTypeWrapper<Omit<CasesResults, 'cases'> & { cases?: Maybe<Array<Maybe<ResolversTypes['Case']>>> }>;
  ChangeLog: ResolverTypeWrapper<ChangeLog>;
  ChangeLogEntityTypes: ChangeLogEntityTypes;
  CheckCalendarsAvailabilityResponse: ResolverTypeWrapper<CheckCalendarsAvailabilityResponse>;
  Clawback: ResolverTypeWrapper<Omit<Clawback, 'attorney' | 'case'> & { attorney?: Maybe<ResolversTypes['Attorney']>, case?: Maybe<ResolversTypes['Case']> }>;
  ClosedCase: ResolverTypeWrapper<ClosedCase>;
  CodeVerificationForEmailInput: CodeVerificationForEmailInput;
  CodeVerificationInput: CodeVerificationInput;
  CodeVerificationResponse: ResolverTypeWrapper<CodeVerificationResponse>;
  CollectOverdueBalanceInput: CollectOverdueBalanceInput;
  ContactUsInput: ContactUsInput;
  ContractType: ContractType;
  ConvertPendingToPaidInput: ConvertPendingToPaidInput;
  CourtInfo: ResolverTypeWrapper<CourtInfo>;
  CoveredCarePrequalData: ResolverTypeWrapper<CoveredCarePrequalData>;
  CoveredCarePrequalLink: ResolverTypeWrapper<CoveredCarePrequalLink>;
  CoveredCarePrequalOffer: ResolverTypeWrapper<CoveredCarePrequalOffer>;
  CoveredCarePrequalResult: ResolverTypeWrapper<CoveredCarePrequalResult>;
  CreateAchInput: CreateAchInput;
  CreateAdminInput: CreateAdminInput;
  CreateAttorneyCodeVerificationResponse: ResolverTypeWrapper<CreateAttorneyCodeVerificationResponse>;
  CreateAttorneyInput: CreateAttorneyInput;
  CreateCaseDocumentFormInput: CreateCaseDocumentFormInput;
  CreateCodeVerificationResponse: ResolverTypeWrapper<CreateCodeVerificationResponse>;
  CreateCustomerInput: CreateCustomerInput;
  CreateFilingFeePaymentPlanComponentInput: CreateFilingFeePaymentPlanComponentInput;
  CreateLegalProductInput: CreateLegalProductInput;
  CreateLinkTokenInput: CreateLinkTokenInput;
  CreatePayInFullPaymentPlanComponentInput: CreatePayInFullPaymentPlanComponentInput;
  CreatePaymentOnHoldInput: CreatePaymentOnHoldInput;
  CreatePaymentPlanComponentInput: CreatePaymentPlanComponentInput;
  CreatePayoutItemBulkInput: CreatePayoutItemBulkInput;
  CreatePayoutItemBulkResponse: ResolverTypeWrapper<Omit<CreatePayoutItemBulkResponse, 'responses'> & { responses: Array<ResolversTypes['CreatePayoutItemResponse']> }>;
  CreatePayoutItemError: ResolverTypeWrapper<CreatePayoutItemError>;
  CreatePayoutItemInput: CreatePayoutItemInput;
  CreatePayoutItemResponse: ResolverTypeWrapper<Omit<CreatePayoutItemResponse, 'data'> & { data?: Maybe<ResolversTypes['PayoutItem']> }>;
  CreateRejectedCustomerInput: CreateRejectedCustomerInput;
  CreateShortLivedTokenOutput: ResolverTypeWrapper<CreateShortLivedTokenOutput>;
  CreateThirdPartyUserInput: CreateThirdPartyUserInput;
  CreateWebLeadInput: CreateWebLeadInput;
  CreateWisetackLoanLinkInput: CreateWisetackLoanLinkInput;
  CreditCardPaymentInput: CreditCardPaymentInput;
  CreditCheckAddressInput: CreditCheckAddressInput;
  CreditCheckErrorCode: CreditCheckErrorCode;
  CreditFinancingOffered: CreditFinancingOffered;
  Customer: ResolverTypeWrapper<Omit<Customer, 'address' | 'customerState' | 'payment' | 'paymentAccount'> & { address?: Maybe<ResolversTypes['Address']>, customerState?: Maybe<ResolversTypes['CustomerState']>, payment?: Maybe<ResolversTypes['Payment']>, paymentAccount?: Maybe<ResolversTypes['PaymentAccount']> }>;
  CustomerDetails: CustomerDetails;
  CustomerFinancingSummary: ResolverTypeWrapper<CustomerFinancingSummary>;
  CustomerInput: CustomerInput;
  CustomerLeadInput: CustomerLeadInput;
  CustomerLoginType: CustomerLoginType;
  CustomerNotificationInput: CustomerNotificationInput;
  CustomerState: ResolverTypeWrapper<CustomerState>;
  CustomerStripeData: ResolverTypeWrapper<CustomerStripeData>;
  CustomerUpdateInput: CustomerUpdateInput;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeleteDownPaymentInput: DeleteDownPaymentInput;
  DeletePactsafeContractInput: DeletePactsafeContractInput;
  DeleteThirdPartyPaymentInput: DeleteThirdPartyPaymentInput;
  Details: Details;
  DevVerificationCodeInput: DevVerificationCodeInput;
  DiyFunnelData: ResolverTypeWrapper<Omit<DiyFunnelData, 'customer'> & { customer?: Maybe<ResolversTypes['Customer']> }>;
  DiyFunnelLocation: ResolverTypeWrapper<DiyFunnelLocation>;
  DocumentClassification: ResolverTypeWrapper<DocumentClassification>;
  DocumentClassificationCategory: ResolverTypeWrapper<DocumentClassificationCategory>;
  DocumentClassificationsWithCategory: ResolverTypeWrapper<DocumentClassificationsWithCategory>;
  DownPayment: ResolverTypeWrapper<DownPayment>;
  DownPaymentsPaidAmount: ResolverTypeWrapper<DownPaymentsPaidAmount>;
  DriveDocument: ResolverTypeWrapper<DriveDocument>;
  ELOverrides: ElOverrides;
  ELTenant: ElTenant;
  ELType: ElType;
  Earning: ResolverTypeWrapper<Omit<Earning, 'payoutItems'> & { payoutItems: Array<ResolversTypes['PayoutItem']> }>;
  EditRecurringPaymentInput: EditRecurringPaymentInput;
  EditThirdPartyPaymentInput: EditThirdPartyPaymentInput;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']['output']>;
  EnableAutopayRequest: EnableAutopayRequest;
  EndRecurringPaymentInput: EndRecurringPaymentInput;
  Environments: Environments;
  EveryTwoWeeks: EveryTwoWeeks;
  EveryTwoWeeksOutput: ResolverTypeWrapper<EveryTwoWeeksOutput>;
  ExchangePublicTokenInput: ExchangePublicTokenInput;
  Fee: ResolverTypeWrapper<Fee>;
  FetchTransactionDataForCustomerInput: FetchTransactionDataForCustomerInput;
  FilterVal: ResolverTypeWrapper<FilterVal>;
  FinancingOption: ResolverTypeWrapper<FinancingOption>;
  FinancingOptionProvider: FinancingOptionProvider;
  FinancingTerms: ResolverTypeWrapper<FinancingTerms>;
  FinancingType: FinancingType;
  Form: ResolverTypeWrapper<Form>;
  FormData: ResolverTypeWrapper<FormData>;
  FormDataInput: FormDataInput;
  FormField: ResolverTypeWrapper<FormField>;
  FormFieldInput: FormFieldInput;
  FormInput: FormInput;
  FormTemplate: ResolverTypeWrapper<FormTemplate>;
  FormTemplateField: ResolverTypeWrapper<FormTemplateField>;
  FormTemplateFieldInput: FormTemplateFieldInput;
  FormTemplateFieldType: FormTemplateFieldType;
  FormTemplatesFilterInput: FormTemplatesFilterInput;
  FreeTaxGeneratorPayload: FreeTaxGeneratorPayload;
  Gender: Gender;
  GenerateELInput: GenerateElInput;
  GenerateLeadInput: GenerateLeadInput;
  GenerateLoanApplicationLinkInput: GenerateLoanApplicationLinkInput;
  GeneratePaymentAgreementResponse: ResolverTypeWrapper<GeneratePaymentAgreementResponse>;
  GeneratePrequalLinkInput: GeneratePrequalLinkInput;
  GenesisLoanAccount: ResolverTypeWrapper<GenesisLoanAccount>;
  GenesisLoanApplication: ResolverTypeWrapper<GenesisLoanApplication>;
  GenesisLoanTerms: ResolverTypeWrapper<GenesisLoanTerms>;
  GenesisPrequalApplication: ResolverTypeWrapper<GenesisPrequalApplication>;
  GenesisStartPrequalInput: GenesisStartPrequalInput;
  GetAppConfigurationsInput: GetAppConfigurationsInput;
  GetAppPrivateConfigurationsInput: GetAppPrivateConfigurationsInput;
  GetAppPublicConfigurationsInput: GetAppPublicConfigurationsInput;
  GetCalendarsAvailabilityByUrlOptions: GetCalendarsAvailabilityByUrlOptions;
  GetCalendarsAvailabilityInput: GetCalendarsAvailabilityInput;
  GetCustomerRiskLevelInput: GetCustomerRiskLevelInput;
  GetCustomerRiskLevelResult: ResolverTypeWrapper<GetCustomerRiskLevelResult>;
  GetLegalServicesOptions: GetLegalServicesOptions;
  GetLegalServicesResult: ResolverTypeWrapper<GetLegalServicesResult>;
  GetOnboardingProgressInput: GetOnboardingProgressInput;
  GetOpportunityThirdPartyPayerFinancingOptionsInput: GetOpportunityThirdPartyPayerFinancingOptionsInput;
  GetOrCreatePaymentPlanAccountInput: GetOrCreatePaymentPlanAccountInput;
  GetOrCreatePaymentPlanAccountResponse: ResolverTypeWrapper<GetOrCreatePaymentPlanAccountResponse>;
  GetPaginatedDiyUsersParams: GetPaginatedDiyUsersParams;
  GetPaginatedDiyUsersResponse: ResolverTypeWrapper<GetPaginatedDiyUsersResponse>;
  GetPaidDownPaymentsAmountInput: GetPaidDownPaymentsAmountInput;
  GetPaidLssAmountInput: GetPaidLssAmountInput;
  GetPayoutItemsInput: GetPayoutItemsInput;
  GetPendingSigningRequestDetailsInput: GetPendingSigningRequestDetailsInput;
  GetRefundTicketByIdInput: GetRefundTicketByIdInput;
  GetRefundTicketByIdResponse: ResolverTypeWrapper<GetRefundTicketByIdResponse>;
  GetRequiredDocumentsOptions: GetRequiredDocumentsOptions;
  GetSuggestedThirdPartyProviderInput: GetSuggestedThirdPartyProviderInput;
  GetThirdPartyFinancedAmountInput: GetThirdPartyFinancedAmountInput;
  GetUnderwritingFinancingInput: GetUnderwritingFinancingInput;
  GetUserByExternalIdInput: GetUserByExternalIdInput;
  GetUserInput: GetUserInput;
  GqlPrequalApplicationStatus: GqlPrequalApplicationStatus;
  HashAlgorithm: HashAlgorithm;
  HashOptions: HashOptions;
  HashResponse: ResolverTypeWrapper<HashResponse>;
  ICaseDocument: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ICaseDocument']>;
  IFormTemplateField: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IFormTemplateField']>;
  IdentityVerificationInput: IdentityVerificationInput;
  IdentityVerificationOutput: ResolverTypeWrapper<IdentityVerificationOutput>;
  IdentityVerificationStatus: IdentityVerificationStatus;
  ImpersonateByApiKeyInput: ImpersonateByApiKeyInput;
  InHousePrequalApplication: ResolverTypeWrapper<InHousePrequalApplication>;
  InHousePrequalApplicationMetadata: ResolverTypeWrapper<InHousePrequalApplicationMetadata>;
  InHousePrequalApplicationOffer: ResolverTypeWrapper<InHousePrequalApplicationOffer>;
  Installment: ResolverTypeWrapper<Omit<Installment, 'attorney' | 'case'> & { attorney?: Maybe<ResolversTypes['Attorney']>, case?: Maybe<ResolversTypes['Case']> }>;
  IntakeMeeting: ResolverTypeWrapper<IntakeMeeting>;
  IntakeMeetingSlots: ResolverTypeWrapper<IntakeMeetingSlots>;
  IntakeMeetingSlotsInput: IntakeMeetingSlotsInput;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  Language: Language;
  Lead: ResolverTypeWrapper<Lead>;
  LegalProduct: ResolverTypeWrapper<LegalProduct>;
  LegalService: ResolverTypeWrapper<LegalService>;
  LegalServiceInput: LegalServiceInput;
  LegalServiceProvider: ResolverTypeWrapper<LegalServiceProvider>;
  LegalServiceSection: ResolverTypeWrapper<LegalServiceSection>;
  LegalServiceType: LegalServiceType;
  LegalServicesInput: LegalServicesInput;
  LegalServicesSortOptions: LegalServicesSortOptions;
  LenderName: LenderName;
  LenderOffer: ResolverTypeWrapper<LenderOffer>;
  LinkTokenCreateResponse: ResolverTypeWrapper<LinkTokenCreateResponse>;
  LoanAmountStatus: LoanAmountStatus;
  LoanProvider: ResolverTypeWrapper<LoanProvider>;
  LoginByCognitoInput: LoginByCognitoInput;
  LoginByGoogleInput: LoginByGoogleInput;
  LoginByTokenResponse: ResolverTypeWrapper<LoginByTokenResponse>;
  LoginDemoUserByGoogleInput: LoginDemoUserByGoogleInput;
  LssAppearance: LssAppearance;
  LssAttorneyCaseRejectionReason: LssAttorneyCaseRejectionReason;
  LssAttorneyResponseInput: LssAttorneyResponseInput;
  LssCancelAndRefundRequestInput: LssCancelAndRefundRequestInput;
  LssCaseInfo: ResolverTypeWrapper<LssCaseInfo>;
  LssCaseInfoInput: LssCaseInfoInput;
  LssCaseView: LssCaseView;
  LssCasesByViewInput: LssCasesByViewInput;
  LssCasesFilterInput: LssCasesFilterInput;
  LssClientDetails: ResolverTypeWrapper<LssClientDetails>;
  LssClientDetailsInput: LssClientDetailsInput;
  LssCriticalDate: ResolverTypeWrapper<LssCriticalDate>;
  LssCriticalDateInput: LssCriticalDateInput;
  LssCriticalDateType: LssCriticalDateType;
  LssFilingOrResponding: LssFilingOrResponding;
  LssGender: LssGender;
  LssRefundRequestInput: LssRefundRequestInput;
  LssRejected: ResolverTypeWrapper<LssRejected>;
  LssRejectionReason: LssRejectionReason;
  LssRescheduled: ResolverTypeWrapper<LssRescheduled>;
  LssSummary: ResolverTypeWrapper<LssSummary>;
  LssSummaryInput: LssSummaryInput;
  MakeLoanPaymentInput: MakeLoanPaymentInput;
  MakeLoanPaymentResponse: ResolverTypeWrapper<MakeLoanPaymentResponse>;
  ManagerCasesFilterInput: ManagerCasesFilterInput;
  MarkAllNotificationsAsReadResult: ResolverTypeWrapper<MarkAllNotificationsAsReadResult>;
  MeetingBookingInput: MeetingBookingInput;
  MeetingDetailsLeadInput: MeetingDetailsLeadInput;
  MigrateCaseDocumentsToVinnyResponse: ResolverTypeWrapper<MigrateCaseDocumentsToVinnyResponse>;
  MigrateToVinnyParams: MigrateToVinnyParams;
  MigrateToVinnyPayload: MigrateToVinnyPayload;
  MigrateToVinnyResponse: ResolverTypeWrapper<MigrateToVinnyResponse>;
  MissedPayment: ResolverTypeWrapper<MissedPayment>;
  Mutation: ResolverTypeWrapper<{}>;
  Name: ResolverTypeWrapper<Name>;
  NameInput: NameInput;
  NextPayment: ResolverTypeWrapper<NextPayment>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationCategory: NotificationCategory;
  NotificationData: ResolverTypeWrapper<NotificationData>;
  NotificationDataInput: NotificationDataInput;
  NotificationInput: NotificationInput;
  NotificationStatus: NotificationStatus;
  NotificationType: NotificationType;
  NotificationsOrderByInput: NotificationsOrderByInput;
  Onboarding: ResolverTypeWrapper<Onboarding>;
  OnboardingProgress: ResolverTypeWrapper<OnboardingProgress>;
  OnboardingStepId: OnboardingStepId;
  OnboardingStepProgress: ResolverTypeWrapper<OnboardingStepProgress>;
  OneTimePlan: ResolverTypeWrapper<OneTimePlan>;
  OpportunityLinkFields: ResolverTypeWrapper<OpportunityLinkFields>;
  OpportunityLinkFieldsByCaseIdInput: OpportunityLinkFieldsByCaseIdInput;
  OpportunityLinkFieldsInput: OpportunityLinkFieldsInput;
  OpportunityPaymentData: ResolverTypeWrapper<OpportunityPaymentData>;
  OpportunityStage: ResolverTypeWrapper<OpportunityStage>;
  OpposingParty: ResolverTypeWrapper<OpposingParty>;
  Owner: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Owner']>;
  Page: ResolverTypeWrapper<Page>;
  PaidLssAmount: ResolverTypeWrapper<PaidLssAmount>;
  PartialAttorney: ResolverTypeWrapper<Omit<PartialAttorney, 'address'> & { address?: Maybe<ResolversTypes['Address']> }>;
  PartialName: ResolverTypeWrapper<PartialName>;
  PartialPaymentAccount: ResolverTypeWrapper<PartialPaymentAccount>;
  PastPayment: ResolverTypeWrapper<PastPayment>;
  PastTransaction: ResolverTypeWrapper<PastTransaction>;
  PayTotalOutstandingRequest: PayTotalOutstandingRequest;
  PayTotalOutstandingResponse: ResolverTypeWrapper<PayTotalOutstandingResponse>;
  PayerFinancingOptions: ResolverTypeWrapper<PayerFinancingOptions>;
  Payment: ResolverTypeWrapper<Omit<Payment, 'methods'> & { methods?: Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>> }>;
  PaymentAccount: ResolverTypeWrapper<Omit<PaymentAccount, 'missedPayments' | 'plans'> & { missedPayments?: Maybe<Array<ResolversTypes['MissedPayment']>>, plans?: Maybe<Array<ResolversTypes['PaymentPlan']>> }>;
  PaymentAgreementResponse: ResolverTypeWrapper<PaymentAgreementResponse>;
  PaymentCustomerAddress: PaymentCustomerAddress;
  PaymentFrequency: PaymentFrequency;
  PaymentInput: PaymentInput;
  PaymentMethod: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['PaymentMethod']>;
  PaymentOnHold: ResolverTypeWrapper<PaymentOnHold>;
  PaymentPlan: ResolverTypeWrapper<Omit<PaymentPlan, 'components'> & { components: Array<ResolversTypes['PaymentPlanComponent']> }>;
  PaymentPlanComponent: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['PaymentPlanComponent']>;
  PaymentPlanConfig: ResolverTypeWrapper<PaymentPlanConfig>;
  PaymentPlanData: ResolverTypeWrapper<PaymentPlanData>;
  PaymentPlanItem: PaymentPlanItem;
  PaymentPlanProvider: PaymentPlanProvider;
  PaymentPlanSetupAccountData: ResolverTypeWrapper<PaymentPlanSetupAccountData>;
  PaymentPlanSetupOpportunity: ResolverTypeWrapper<PaymentPlanSetupOpportunity>;
  PaymentPlanSetupPaymentPlan: ResolverTypeWrapper<PaymentPlanSetupPaymentPlan>;
  PaymentPlanSetupPaymentPlanComponent: ResolverTypeWrapper<PaymentPlanSetupPaymentPlanComponent>;
  PaymentPlanSetupPaymentPlanType: PaymentPlanSetupPaymentPlanType;
  PaymentPlansPaymentMethods: ResolverTypeWrapper<PaymentPlansPaymentMethods>;
  PaymentStatus: PaymentStatus;
  PayoutItem: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['PayoutItem']>;
  PayoutItemType: PayoutItemType;
  PaypalCheckout: PaypalCheckout;
  PaypalClientTokenResponse: ResolverTypeWrapper<PaypalClientTokenResponse>;
  PendingOnboardingAttorney: ResolverTypeWrapper<Omit<PendingOnboardingAttorney, 'attorney'> & { attorney: ResolversTypes['PartialAttorney'] }>;
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']['output']>;
  Phones: ResolverTypeWrapper<Phones>;
  PhonesInput: PhonesInput;
  PlatformConfigurationsType: PlatformConfigurationsType;
  PlatformType: PlatformType;
  PostLssFormInput: PostLssFormInput;
  PracticeArea: PracticeArea;
  PrequalApplicationOutput: ResolverTypeWrapper<PrequalApplicationOutput>;
  PrequalInput: PrequalInput;
  PrequalResponse: ResolverTypeWrapper<PrequalResponse>;
  Query: ResolverTypeWrapper<{}>;
  RecommendedService: RecommendedService;
  RecommendedServiceDetails: ResolverTypeWrapper<RecommendedServiceDetails>;
  RecurringPlan: ResolverTypeWrapper<RecurringPlan>;
  RefundLoanApplicationInput: RefundLoanApplicationInput;
  RefundLoanInput: RefundLoanInput;
  RefundLoanOutput: ResolverTypeWrapper<RefundLoanOutput>;
  RefundReason: RefundReason;
  RefundTransaction: RefundTransaction;
  RefundTransactionResult: ResolverTypeWrapper<RefundTransactionResult>;
  RegisterStripeCustomerInput: RegisterStripeCustomerInput;
  RegisterStripeCustomerResponse: ResolverTypeWrapper<RegisterStripeCustomerResponse>;
  RejectedCustomer: ResolverTypeWrapper<RejectedCustomer>;
  RejectedLssSummaryInput: RejectedLssSummaryInput;
  RenderContractInput: RenderContractInput;
  RenderContractResponse: ResolverTypeWrapper<RenderContractResponse>;
  RequiredDocument: ResolverTypeWrapper<RequiredDocument>;
  RescheduleLssInput: RescheduleLssInput;
  RescheduleLssMeetingInput: RescheduleLssMeetingInput;
  Role: Role;
  RunCoveredCarePrequalInput: RunCoveredCarePrequalInput;
  RunCreditCheckInput: RunCreditCheckInput;
  SFEvent: ResolverTypeWrapper<SfEvent>;
  SFIntakeData: ResolverTypeWrapper<SfIntakeData>;
  SFIntakeDataCriticalDate: ResolverTypeWrapper<SfIntakeDataCriticalDate>;
  SFLegalService: ResolverTypeWrapper<SfLegalService>;
  SFService: ResolverTypeWrapper<SfService>;
  SFServicesInput: SfServicesInput;
  SFUpdateEventInput: SfUpdateEventInput;
  ScheduleLssMeetingReminderInput: ScheduleLssMeetingReminderInput;
  ScheduledMeeting: ResolverTypeWrapper<ScheduledMeeting>;
  SendAddServicesRequestInput: SendAddServicesRequestInput;
  SendAttorneyFeedbackInput: SendAttorneyFeedbackInput;
  SendPayoutMailResult: ResolverTypeWrapper<SendPayoutMailResult>;
  SendPayoutMailsInput: SendPayoutMailsInput;
  SendPayoutMailsResponse: ResolverTypeWrapper<SendPayoutMailsResponse>;
  ServiceOnOpportunity: ResolverTypeWrapper<ServiceOnOpportunity>;
  ServicePaymentData: ResolverTypeWrapper<ServicePaymentData>;
  ServicesPaymentDataForOpportunity: ResolverTypeWrapper<ServicesPaymentDataForOpportunity>;
  SetNotificationsAllowedInput: SetNotificationsAllowedInput;
  SetUserInput: SetUserInput;
  SignedInput: SignedInput;
  SigningRequestDetails: ResolverTypeWrapper<SigningRequestDetails>;
  SortDirection: SortDirection;
  SortInput: SortInput;
  SpecificCaseEventsFilterInput: SpecificCaseEventsFilterInput;
  Stage: ResolverTypeWrapper<Stage>;
  StageEnum: StageEnum;
  StartLoanApplicationInput: StartLoanApplicationInput;
  StartLoanApplicationOutput: ResolverTypeWrapper<StartLoanApplicationOutput>;
  StripeData: ResolverTypeWrapper<StripeData>;
  StripeDescription: StripeDescription;
  StripeMetadata: StripeMetadata;
  SubmitRequestForDpPolicyChangeInput: SubmitRequestForDpPolicyChangeInput;
  SubmitRequestForDpPolicyChangeResult: ResolverTypeWrapper<SubmitRequestForDpPolicyChangeResult>;
  SuggestedThirdPartyProviderResponse: ResolverTypeWrapper<SuggestedThirdPartyProviderResponse>;
  SyncCaseFromOpportunityInput: SyncCaseFromOpportunityInput;
  SyncClosedCaseInput: SyncClosedCaseInput;
  SyncDeviceResponse: ResolverTypeWrapper<SyncDeviceResponse>;
  SyncDocumentToDriveInput: SyncDocumentToDriveInput;
  SyncDocumentToDriveOptions: SyncDocumentToDriveOptions;
  Team: Team;
  ThirdPartyFinancedAmount: ResolverTypeWrapper<ThirdPartyFinancedAmount>;
  ThirdPartyProviderType: ThirdPartyProviderType;
  Ticket: ResolverTypeWrapper<Ticket>;
  TicketComment: ResolverTypeWrapper<TicketComment>;
  Time: ResolverTypeWrapper<Scalars['Time']['output']>;
  TouchpointOptions: TouchpointOptions;
  TransactionPaymentMethod: ResolverTypeWrapper<TransactionPaymentMethod>;
  TransactionPaymentMethodType: TransactionPaymentMethodType;
  TransactionPaymentProvider: TransactionPaymentProvider;
  TransactionRefundReason: TransactionRefundReason;
  TransactionsProvider: TransactionsProvider;
  TransactionsStatus: TransactionsStatus;
  TransactionsType: TransactionsType;
  TwiceMonthly: TwiceMonthly;
  TwiceMonthlyOutput: ResolverTypeWrapper<TwiceMonthlyOutput>;
  TypeOfFee: TypeOfFee;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  UTMInput: UtmInput;
  UWAddress: ResolverTypeWrapper<UwAddress>;
  UWAddressInput: UwAddressInput;
  UWUser: ResolverTypeWrapper<UwUser>;
  UnderwritingFinancingOptionInput: UnderwritingFinancingOptionInput;
  UpdateAttorneyInput: UpdateAttorneyInput;
  UpdateCaseCourtInfoInput: UpdateCaseCourtInfoInput;
  UpdateCaseDocumentFormFieldsInput: UpdateCaseDocumentFormFieldsInput;
  UpdateCaseDocumentFormStatusInput: UpdateCaseDocumentFormStatusInput;
  UpdateCaseDocumentTypeInput: UpdateCaseDocumentTypeInput;
  UpdateCaseEventInput: UpdateCaseEventInput;
  UpdateCaseTaskInput: UpdateCaseTaskInput;
  UpdateDefaultPaymentMethodInput: UpdateDefaultPaymentMethodInput;
  UpdateDocumentSharingWithClientPayload: UpdateDocumentSharingWithClientPayload;
  UpdateFormTemplateInput: UpdateFormTemplateInput;
  UpdateLegalProductInput: UpdateLegalProductInput;
  UpdateSFRefundTicketInput: UpdateSfRefundTicketInput;
  UpdateUserInput: UpdateUserInput;
  UpdateWebLeadInput: UpdateWebLeadInput;
  UpdateWhatIsNextInput: UpdateWhatIsNextInput;
  UpdatedCaseDocumentStatus: ResolverTypeWrapper<UpdatedCaseDocumentStatus>;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  UpsertCaseTaskResult: ResolverTypeWrapper<Omit<UpsertCaseTaskResult, 'case' | 'caseTask'> & { case?: Maybe<ResolversTypes['Case']>, caseTask: ResolversTypes['CaseTask'] }>;
  UpsertDiyFunnelData: UpsertDiyFunnelData;
  UserFinancingOptionOrNull: ResolverTypeWrapper<UserFinancingOptionOrNull>;
  UserRoleName: ResolverTypeWrapper<UserRoleName>;
  UserType: UserType;
  UsersWithCalendarIssues: ResolverTypeWrapper<UsersWithCalendarIssues>;
  VerificationIdentifierType: VerificationIdentifierType;
  VoucherDiscount: ResolverTypeWrapper<VoucherDiscount>;
  WhatIsNext: ResolverTypeWrapper<WhatIsNext>;
  WhatIsNextEnum: WhatIsNextEnum;
  WireTransferMethod: ResolverTypeWrapper<WireTransferMethod>;
  WisetackLoanAmountStatus: ResolverTypeWrapper<WisetackLoanAmountStatus>;
  WisetackLoanLink: ResolverTypeWrapper<WisetackLoanLink>;
  WisetackPrequalLink: ResolverTypeWrapper<WisetackPrequalLink>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountForAch: AccountForAch;
  String: Scalars['String']['output'];
  Float: Scalars['Float']['output'];
  AccountStatus: AccountStatus;
  Int: Scalars['Int']['output'];
  AccountsForAchInput: AccountsForAchInput;
  AchData: AchData;
  ActivateLoanInput: ActivateLoanInput;
  AddCaseEventInput: AddCaseEventInput;
  ID: Scalars['ID']['output'];
  AddCaseTaskInput: AddCaseTaskInput;
  AddDocumentClassificationPayload: AddDocumentClassificationPayload;
  AddPaymentInput: AddPaymentInput;
  Boolean: Scalars['Boolean']['output'];
  AddRequiredDocumentPayload: AddRequiredDocumentPayload;
  Addendum: Omit<Addendum, 'attorney' | 'case'> & { attorney?: Maybe<ResolversParentTypes['Attorney']>, case?: Maybe<ResolversParentTypes['Case']> };
  Address: Address;
  AddressInput: AddressInput;
  Adjustment: Omit<Adjustment, 'attorney' | 'case'> & { attorney?: Maybe<ResolversParentTypes['Attorney']>, case?: Maybe<ResolversParentTypes['Case']> };
  Admin: Admin;
  AffirmProviderEventInput: AffirmProviderEventInput;
  AppConfigurations: AppConfigurations;
  AppInstallation: AppInstallation;
  AppInstallationInput: AppInstallationInput;
  Attorney: Omit<Attorney, 'address' | 'case' | 'cases' | 'earning' | 'earnings' | 'lssCase' | 'lssCases' | 'lssCasesByView' | 'pendingEarnings'> & { address?: Maybe<ResolversParentTypes['Address']>, case?: Maybe<ResolversParentTypes['Case']>, cases?: Maybe<Array<Maybe<ResolversParentTypes['Case']>>>, earning?: Maybe<ResolversParentTypes['Earning']>, earnings: Array<Maybe<ResolversParentTypes['Earning']>>, lssCase?: Maybe<ResolversParentTypes['Case']>, lssCases?: Maybe<Array<Maybe<ResolversParentTypes['Case']>>>, lssCasesByView?: Maybe<Array<Maybe<ResolversParentTypes['Case']>>>, pendingEarnings?: Maybe<ResolversParentTypes['Earning']> };
  AuthorizeTransactionPayload: AuthorizeTransactionPayload;
  Autopay: Autopay;
  BatchCreatePreAuthLinkResponse: Omit<BatchCreatePreAuthLinkResponse, 'customer'> & { customer?: Maybe<ResolversParentTypes['Customer']> };
  BatchDeletePactsafeContractsInput: BatchDeletePactsafeContractsInput;
  BigInt: Scalars['BigInt']['output'];
  BillingCycle: BillingCycle;
  BillingCycleOutput: BillingCycleOutput;
  Bonus: Omit<Bonus, 'attorney'> & { attorney?: Maybe<ResolversParentTypes['Attorney']> };
  BraintreeAddress: BraintreeAddress;
  BraintreeCurrencyAmount: BraintreeCurrencyAmount;
  BrazeCustomerInput: BrazeCustomerInput;
  BrazeMigrationInput: BrazeMigrationInput;
  BrazeMigrationResponse: BrazeMigrationResponse;
  BrazeProperties: BrazeProperties;
  CalendarAvailability: CalendarAvailability;
  CalendlyEvent: CalendlyEvent;
  CallLog: CallLog;
  CallLogCreatedBy: CallLogCreatedBy;
  CallLogInput: CallLogInput;
  CancelAutopayRequest: CancelAutopayRequest;
  CancelEventInput: CancelEventInput;
  CancelLoanApplicationInput: CancelLoanApplicationInput;
  CancelPaymentOnHoldInput: CancelPaymentOnHoldInput;
  CancelScheduledEventInput: CancelScheduledEventInput;
  CancelScheduledEventsInput: CancelScheduledEventsInput;
  CancelScheduledEventsOptions: CancelScheduledEventsOptions;
  CancelScheduledEventsResponse: CancelScheduledEventsResponse;
  CancelledEvent: CancelledEvent;
  CapturePaymentOnHoldInput: CapturePaymentOnHoldInput;
  CaptureTransactionResponse: CaptureTransactionResponse;
  Card: Card;
  Case: Omit<Case, 'changeLogs' | 'customer' | 'documents' | 'events' | 'lssAttorney' | 'responsibleAttorney' | 'stageTasksInfo' | 'tasks'> & { changeLogs?: Maybe<Array<Maybe<ResolversParentTypes['ChangeLog']>>>, customer?: Maybe<ResolversParentTypes['Customer']>, documents: Array<Maybe<ResolversParentTypes['CaseDocument']>>, events?: Maybe<Array<Maybe<ResolversParentTypes['CaseEvent']>>>, lssAttorney?: Maybe<ResolversParentTypes['Attorney']>, responsibleAttorney?: Maybe<ResolversParentTypes['Attorney']>, stageTasksInfo?: Maybe<ResolversParentTypes['CaseTasksInfo']>, tasks?: Maybe<Array<Maybe<ResolversParentTypes['CaseTask']>>> };
  CaseCostTicketData: CaseCostTicketData;
  CaseDocument: Omit<CaseDocument, 'case'> & { case?: Maybe<ResolversParentTypes['Case']> };
  CaseDocumentFilterInput: CaseDocumentFilterInput;
  CaseDocumentForm: Omit<CaseDocumentForm, 'case'> & { case?: Maybe<ResolversParentTypes['Case']> };
  CaseDocumentFormField: CaseDocumentFormField;
  CaseDocumentFormFieldsUpdateInput: CaseDocumentFormFieldsUpdateInput;
  CaseDocumentInput: CaseDocumentInput;
  CaseDocumentSharingOptions: CaseDocumentSharingOptions;
  CaseEvent: Omit<CaseEvent, 'case'> & { case?: Maybe<ResolversParentTypes['Case']> };
  CaseEventCreatedBy: CaseEventCreatedBy;
  CaseEventLocation: CaseEventLocation;
  CaseEventLocationInput: CaseEventLocationInput;
  CaseEventsFilterInput: CaseEventsFilterInput;
  CaseFilters: CaseFilters;
  CaseLegalProduct: CaseLegalProduct;
  CaseLegalService: CaseLegalService;
  CaseLocation: CaseLocation;
  CaseMeeting: CaseMeeting;
  CaseNote: CaseNote;
  CaseNoteInput: CaseNoteInput;
  CaseOfferCriticalDate: CaseOfferCriticalDate;
  CaseRequiredDocumentsData: CaseRequiredDocumentsData;
  CaseService: CaseService;
  CaseSubentityFailedObject: CaseSubentityFailedObject;
  CaseSubentityMigratedObject: CaseSubentityMigratedObject;
  CaseSubentityMigrationResponse: CaseSubentityMigrationResponse;
  CaseTask: Omit<CaseTask, 'owner'> & { owner?: Maybe<ResolversParentTypes['Owner']> };
  CaseTaskStatus: CaseTaskStatus;
  CaseTaskStatusInput: CaseTaskStatusInput;
  CaseTasksInfo: Omit<CaseTasksInfo, 'currentTask'> & { currentTask?: Maybe<ResolversParentTypes['CaseTask']> };
  CaseUpdate: CaseUpdate;
  CaseUpdateInput: CaseUpdateInput;
  CaseUpdateInputWithTags: CaseUpdateInputWithTags;
  CaseUpdateSharingOptions: CaseUpdateSharingOptions;
  CaseUpdateSharingOptionsInput: CaseUpdateSharingOptionsInput;
  CasesFilterInput: CasesFilterInput;
  CasesResults: Omit<CasesResults, 'cases'> & { cases?: Maybe<Array<Maybe<ResolversParentTypes['Case']>>> };
  ChangeLog: ChangeLog;
  CheckCalendarsAvailabilityResponse: CheckCalendarsAvailabilityResponse;
  Clawback: Omit<Clawback, 'attorney' | 'case'> & { attorney?: Maybe<ResolversParentTypes['Attorney']>, case?: Maybe<ResolversParentTypes['Case']> };
  ClosedCase: ClosedCase;
  CodeVerificationForEmailInput: CodeVerificationForEmailInput;
  CodeVerificationInput: CodeVerificationInput;
  CodeVerificationResponse: CodeVerificationResponse;
  CollectOverdueBalanceInput: CollectOverdueBalanceInput;
  ContactUsInput: ContactUsInput;
  ConvertPendingToPaidInput: ConvertPendingToPaidInput;
  CourtInfo: CourtInfo;
  CoveredCarePrequalData: CoveredCarePrequalData;
  CoveredCarePrequalLink: CoveredCarePrequalLink;
  CoveredCarePrequalOffer: CoveredCarePrequalOffer;
  CoveredCarePrequalResult: CoveredCarePrequalResult;
  CreateAchInput: CreateAchInput;
  CreateAdminInput: CreateAdminInput;
  CreateAttorneyCodeVerificationResponse: CreateAttorneyCodeVerificationResponse;
  CreateAttorneyInput: CreateAttorneyInput;
  CreateCaseDocumentFormInput: CreateCaseDocumentFormInput;
  CreateCodeVerificationResponse: CreateCodeVerificationResponse;
  CreateCustomerInput: CreateCustomerInput;
  CreateFilingFeePaymentPlanComponentInput: CreateFilingFeePaymentPlanComponentInput;
  CreateLegalProductInput: CreateLegalProductInput;
  CreateLinkTokenInput: CreateLinkTokenInput;
  CreatePayInFullPaymentPlanComponentInput: CreatePayInFullPaymentPlanComponentInput;
  CreatePaymentOnHoldInput: CreatePaymentOnHoldInput;
  CreatePaymentPlanComponentInput: CreatePaymentPlanComponentInput;
  CreatePayoutItemBulkInput: CreatePayoutItemBulkInput;
  CreatePayoutItemBulkResponse: Omit<CreatePayoutItemBulkResponse, 'responses'> & { responses: Array<ResolversParentTypes['CreatePayoutItemResponse']> };
  CreatePayoutItemError: CreatePayoutItemError;
  CreatePayoutItemInput: CreatePayoutItemInput;
  CreatePayoutItemResponse: Omit<CreatePayoutItemResponse, 'data'> & { data?: Maybe<ResolversParentTypes['PayoutItem']> };
  CreateRejectedCustomerInput: CreateRejectedCustomerInput;
  CreateShortLivedTokenOutput: CreateShortLivedTokenOutput;
  CreateThirdPartyUserInput: CreateThirdPartyUserInput;
  CreateWebLeadInput: CreateWebLeadInput;
  CreateWisetackLoanLinkInput: CreateWisetackLoanLinkInput;
  CreditCardPaymentInput: CreditCardPaymentInput;
  CreditCheckAddressInput: CreditCheckAddressInput;
  CreditFinancingOffered: CreditFinancingOffered;
  Customer: Omit<Customer, 'address' | 'customerState' | 'payment' | 'paymentAccount'> & { address?: Maybe<ResolversParentTypes['Address']>, customerState?: Maybe<ResolversParentTypes['CustomerState']>, payment?: Maybe<ResolversParentTypes['Payment']>, paymentAccount?: Maybe<ResolversParentTypes['PaymentAccount']> };
  CustomerDetails: CustomerDetails;
  CustomerFinancingSummary: CustomerFinancingSummary;
  CustomerInput: CustomerInput;
  CustomerLeadInput: CustomerLeadInput;
  CustomerNotificationInput: CustomerNotificationInput;
  CustomerState: CustomerState;
  CustomerStripeData: CustomerStripeData;
  CustomerUpdateInput: CustomerUpdateInput;
  Date: Scalars['Date']['output'];
  DateTime: Scalars['DateTime']['output'];
  DeleteDownPaymentInput: DeleteDownPaymentInput;
  DeletePactsafeContractInput: DeletePactsafeContractInput;
  DeleteThirdPartyPaymentInput: DeleteThirdPartyPaymentInput;
  Details: Details;
  DevVerificationCodeInput: DevVerificationCodeInput;
  DiyFunnelData: Omit<DiyFunnelData, 'customer'> & { customer?: Maybe<ResolversParentTypes['Customer']> };
  DiyFunnelLocation: DiyFunnelLocation;
  DocumentClassification: DocumentClassification;
  DocumentClassificationCategory: DocumentClassificationCategory;
  DocumentClassificationsWithCategory: DocumentClassificationsWithCategory;
  DownPayment: DownPayment;
  DownPaymentsPaidAmount: DownPaymentsPaidAmount;
  DriveDocument: DriveDocument;
  ELOverrides: ElOverrides;
  Earning: Omit<Earning, 'payoutItems'> & { payoutItems: Array<ResolversParentTypes['PayoutItem']> };
  EditRecurringPaymentInput: EditRecurringPaymentInput;
  EditThirdPartyPaymentInput: EditThirdPartyPaymentInput;
  EmailAddress: Scalars['EmailAddress']['output'];
  EnableAutopayRequest: EnableAutopayRequest;
  EndRecurringPaymentInput: EndRecurringPaymentInput;
  EveryTwoWeeks: EveryTwoWeeks;
  EveryTwoWeeksOutput: EveryTwoWeeksOutput;
  ExchangePublicTokenInput: ExchangePublicTokenInput;
  Fee: Fee;
  FetchTransactionDataForCustomerInput: FetchTransactionDataForCustomerInput;
  FilterVal: FilterVal;
  FinancingOption: FinancingOption;
  FinancingTerms: FinancingTerms;
  Form: Form;
  FormData: FormData;
  FormDataInput: FormDataInput;
  FormField: FormField;
  FormFieldInput: FormFieldInput;
  FormInput: FormInput;
  FormTemplate: FormTemplate;
  FormTemplateField: FormTemplateField;
  FormTemplateFieldInput: FormTemplateFieldInput;
  FormTemplatesFilterInput: FormTemplatesFilterInput;
  FreeTaxGeneratorPayload: FreeTaxGeneratorPayload;
  GenerateELInput: GenerateElInput;
  GenerateLeadInput: GenerateLeadInput;
  GenerateLoanApplicationLinkInput: GenerateLoanApplicationLinkInput;
  GeneratePaymentAgreementResponse: GeneratePaymentAgreementResponse;
  GeneratePrequalLinkInput: GeneratePrequalLinkInput;
  GenesisLoanAccount: GenesisLoanAccount;
  GenesisLoanApplication: GenesisLoanApplication;
  GenesisLoanTerms: GenesisLoanTerms;
  GenesisPrequalApplication: GenesisPrequalApplication;
  GenesisStartPrequalInput: GenesisStartPrequalInput;
  GetAppConfigurationsInput: GetAppConfigurationsInput;
  GetAppPrivateConfigurationsInput: GetAppPrivateConfigurationsInput;
  GetAppPublicConfigurationsInput: GetAppPublicConfigurationsInput;
  GetCalendarsAvailabilityByUrlOptions: GetCalendarsAvailabilityByUrlOptions;
  GetCalendarsAvailabilityInput: GetCalendarsAvailabilityInput;
  GetCustomerRiskLevelInput: GetCustomerRiskLevelInput;
  GetCustomerRiskLevelResult: GetCustomerRiskLevelResult;
  GetLegalServicesOptions: GetLegalServicesOptions;
  GetLegalServicesResult: GetLegalServicesResult;
  GetOnboardingProgressInput: GetOnboardingProgressInput;
  GetOpportunityThirdPartyPayerFinancingOptionsInput: GetOpportunityThirdPartyPayerFinancingOptionsInput;
  GetOrCreatePaymentPlanAccountInput: GetOrCreatePaymentPlanAccountInput;
  GetOrCreatePaymentPlanAccountResponse: GetOrCreatePaymentPlanAccountResponse;
  GetPaginatedDiyUsersParams: GetPaginatedDiyUsersParams;
  GetPaginatedDiyUsersResponse: GetPaginatedDiyUsersResponse;
  GetPaidDownPaymentsAmountInput: GetPaidDownPaymentsAmountInput;
  GetPaidLssAmountInput: GetPaidLssAmountInput;
  GetPayoutItemsInput: GetPayoutItemsInput;
  GetPendingSigningRequestDetailsInput: GetPendingSigningRequestDetailsInput;
  GetRefundTicketByIdInput: GetRefundTicketByIdInput;
  GetRefundTicketByIdResponse: GetRefundTicketByIdResponse;
  GetRequiredDocumentsOptions: GetRequiredDocumentsOptions;
  GetSuggestedThirdPartyProviderInput: GetSuggestedThirdPartyProviderInput;
  GetThirdPartyFinancedAmountInput: GetThirdPartyFinancedAmountInput;
  GetUnderwritingFinancingInput: GetUnderwritingFinancingInput;
  GetUserByExternalIdInput: GetUserByExternalIdInput;
  GetUserInput: GetUserInput;
  HashOptions: HashOptions;
  HashResponse: HashResponse;
  ICaseDocument: ResolversInterfaceTypes<ResolversParentTypes>['ICaseDocument'];
  IFormTemplateField: ResolversInterfaceTypes<ResolversParentTypes>['IFormTemplateField'];
  IdentityVerificationInput: IdentityVerificationInput;
  IdentityVerificationOutput: IdentityVerificationOutput;
  ImpersonateByApiKeyInput: ImpersonateByApiKeyInput;
  InHousePrequalApplication: InHousePrequalApplication;
  InHousePrequalApplicationMetadata: InHousePrequalApplicationMetadata;
  InHousePrequalApplicationOffer: InHousePrequalApplicationOffer;
  Installment: Omit<Installment, 'attorney' | 'case'> & { attorney?: Maybe<ResolversParentTypes['Attorney']>, case?: Maybe<ResolversParentTypes['Case']> };
  IntakeMeeting: IntakeMeeting;
  IntakeMeetingSlots: IntakeMeetingSlots;
  IntakeMeetingSlotsInput: IntakeMeetingSlotsInput;
  JSON: Scalars['JSON']['output'];
  Lead: Lead;
  LegalProduct: LegalProduct;
  LegalService: LegalService;
  LegalServiceInput: LegalServiceInput;
  LegalServiceProvider: LegalServiceProvider;
  LegalServiceSection: LegalServiceSection;
  LegalServicesInput: LegalServicesInput;
  LenderOffer: LenderOffer;
  LinkTokenCreateResponse: LinkTokenCreateResponse;
  LoanProvider: LoanProvider;
  LoginByCognitoInput: LoginByCognitoInput;
  LoginByGoogleInput: LoginByGoogleInput;
  LoginByTokenResponse: LoginByTokenResponse;
  LoginDemoUserByGoogleInput: LoginDemoUserByGoogleInput;
  LssAttorneyResponseInput: LssAttorneyResponseInput;
  LssCancelAndRefundRequestInput: LssCancelAndRefundRequestInput;
  LssCaseInfo: LssCaseInfo;
  LssCaseInfoInput: LssCaseInfoInput;
  LssCasesByViewInput: LssCasesByViewInput;
  LssCasesFilterInput: LssCasesFilterInput;
  LssClientDetails: LssClientDetails;
  LssClientDetailsInput: LssClientDetailsInput;
  LssCriticalDate: LssCriticalDate;
  LssCriticalDateInput: LssCriticalDateInput;
  LssRefundRequestInput: LssRefundRequestInput;
  LssRejected: LssRejected;
  LssRescheduled: LssRescheduled;
  LssSummary: LssSummary;
  LssSummaryInput: LssSummaryInput;
  MakeLoanPaymentInput: MakeLoanPaymentInput;
  MakeLoanPaymentResponse: MakeLoanPaymentResponse;
  ManagerCasesFilterInput: ManagerCasesFilterInput;
  MarkAllNotificationsAsReadResult: MarkAllNotificationsAsReadResult;
  MeetingBookingInput: MeetingBookingInput;
  MeetingDetailsLeadInput: MeetingDetailsLeadInput;
  MigrateCaseDocumentsToVinnyResponse: MigrateCaseDocumentsToVinnyResponse;
  MigrateToVinnyParams: MigrateToVinnyParams;
  MigrateToVinnyPayload: MigrateToVinnyPayload;
  MigrateToVinnyResponse: MigrateToVinnyResponse;
  MissedPayment: MissedPayment;
  Mutation: {};
  Name: Name;
  NameInput: NameInput;
  NextPayment: NextPayment;
  Notification: Notification;
  NotificationData: NotificationData;
  NotificationDataInput: NotificationDataInput;
  NotificationInput: NotificationInput;
  NotificationsOrderByInput: NotificationsOrderByInput;
  Onboarding: Onboarding;
  OnboardingProgress: OnboardingProgress;
  OnboardingStepProgress: OnboardingStepProgress;
  OneTimePlan: OneTimePlan;
  OpportunityLinkFields: OpportunityLinkFields;
  OpportunityLinkFieldsByCaseIdInput: OpportunityLinkFieldsByCaseIdInput;
  OpportunityLinkFieldsInput: OpportunityLinkFieldsInput;
  OpportunityPaymentData: OpportunityPaymentData;
  OpportunityStage: OpportunityStage;
  OpposingParty: OpposingParty;
  Owner: ResolversUnionTypes<ResolversParentTypes>['Owner'];
  Page: Page;
  PaidLssAmount: PaidLssAmount;
  PartialAttorney: Omit<PartialAttorney, 'address'> & { address?: Maybe<ResolversParentTypes['Address']> };
  PartialName: PartialName;
  PartialPaymentAccount: PartialPaymentAccount;
  PastPayment: PastPayment;
  PastTransaction: PastTransaction;
  PayTotalOutstandingRequest: PayTotalOutstandingRequest;
  PayTotalOutstandingResponse: PayTotalOutstandingResponse;
  PayerFinancingOptions: PayerFinancingOptions;
  Payment: Omit<Payment, 'methods'> & { methods?: Maybe<Array<Maybe<ResolversParentTypes['PaymentMethod']>>> };
  PaymentAccount: Omit<PaymentAccount, 'missedPayments' | 'plans'> & { missedPayments?: Maybe<Array<ResolversParentTypes['MissedPayment']>>, plans?: Maybe<Array<ResolversParentTypes['PaymentPlan']>> };
  PaymentAgreementResponse: PaymentAgreementResponse;
  PaymentCustomerAddress: PaymentCustomerAddress;
  PaymentInput: PaymentInput;
  PaymentMethod: ResolversInterfaceTypes<ResolversParentTypes>['PaymentMethod'];
  PaymentOnHold: PaymentOnHold;
  PaymentPlan: Omit<PaymentPlan, 'components'> & { components: Array<ResolversParentTypes['PaymentPlanComponent']> };
  PaymentPlanComponent: ResolversInterfaceTypes<ResolversParentTypes>['PaymentPlanComponent'];
  PaymentPlanConfig: PaymentPlanConfig;
  PaymentPlanData: PaymentPlanData;
  PaymentPlanSetupAccountData: PaymentPlanSetupAccountData;
  PaymentPlanSetupOpportunity: PaymentPlanSetupOpportunity;
  PaymentPlanSetupPaymentPlan: PaymentPlanSetupPaymentPlan;
  PaymentPlanSetupPaymentPlanComponent: PaymentPlanSetupPaymentPlanComponent;
  PaymentPlansPaymentMethods: PaymentPlansPaymentMethods;
  PayoutItem: ResolversInterfaceTypes<ResolversParentTypes>['PayoutItem'];
  PaypalCheckout: PaypalCheckout;
  PaypalClientTokenResponse: PaypalClientTokenResponse;
  PendingOnboardingAttorney: Omit<PendingOnboardingAttorney, 'attorney'> & { attorney: ResolversParentTypes['PartialAttorney'] };
  PhoneNumber: Scalars['PhoneNumber']['output'];
  Phones: Phones;
  PhonesInput: PhonesInput;
  PostLssFormInput: PostLssFormInput;
  PrequalApplicationOutput: PrequalApplicationOutput;
  PrequalInput: PrequalInput;
  PrequalResponse: PrequalResponse;
  Query: {};
  RecommendedService: RecommendedService;
  RecommendedServiceDetails: RecommendedServiceDetails;
  RecurringPlan: RecurringPlan;
  RefundLoanApplicationInput: RefundLoanApplicationInput;
  RefundLoanInput: RefundLoanInput;
  RefundLoanOutput: RefundLoanOutput;
  RefundTransaction: RefundTransaction;
  RefundTransactionResult: RefundTransactionResult;
  RegisterStripeCustomerInput: RegisterStripeCustomerInput;
  RegisterStripeCustomerResponse: RegisterStripeCustomerResponse;
  RejectedCustomer: RejectedCustomer;
  RejectedLssSummaryInput: RejectedLssSummaryInput;
  RenderContractInput: RenderContractInput;
  RenderContractResponse: RenderContractResponse;
  RequiredDocument: RequiredDocument;
  RescheduleLssInput: RescheduleLssInput;
  RescheduleLssMeetingInput: RescheduleLssMeetingInput;
  RunCoveredCarePrequalInput: RunCoveredCarePrequalInput;
  RunCreditCheckInput: RunCreditCheckInput;
  SFEvent: SfEvent;
  SFIntakeData: SfIntakeData;
  SFIntakeDataCriticalDate: SfIntakeDataCriticalDate;
  SFLegalService: SfLegalService;
  SFService: SfService;
  SFServicesInput: SfServicesInput;
  SFUpdateEventInput: SfUpdateEventInput;
  ScheduleLssMeetingReminderInput: ScheduleLssMeetingReminderInput;
  ScheduledMeeting: ScheduledMeeting;
  SendAddServicesRequestInput: SendAddServicesRequestInput;
  SendAttorneyFeedbackInput: SendAttorneyFeedbackInput;
  SendPayoutMailResult: SendPayoutMailResult;
  SendPayoutMailsInput: SendPayoutMailsInput;
  SendPayoutMailsResponse: SendPayoutMailsResponse;
  ServiceOnOpportunity: ServiceOnOpportunity;
  ServicePaymentData: ServicePaymentData;
  ServicesPaymentDataForOpportunity: ServicesPaymentDataForOpportunity;
  SetNotificationsAllowedInput: SetNotificationsAllowedInput;
  SetUserInput: SetUserInput;
  SignedInput: SignedInput;
  SigningRequestDetails: SigningRequestDetails;
  SortInput: SortInput;
  SpecificCaseEventsFilterInput: SpecificCaseEventsFilterInput;
  Stage: Stage;
  StartLoanApplicationInput: StartLoanApplicationInput;
  StartLoanApplicationOutput: StartLoanApplicationOutput;
  StripeData: StripeData;
  StripeDescription: StripeDescription;
  StripeMetadata: StripeMetadata;
  SubmitRequestForDpPolicyChangeInput: SubmitRequestForDpPolicyChangeInput;
  SubmitRequestForDpPolicyChangeResult: SubmitRequestForDpPolicyChangeResult;
  SuggestedThirdPartyProviderResponse: SuggestedThirdPartyProviderResponse;
  SyncCaseFromOpportunityInput: SyncCaseFromOpportunityInput;
  SyncClosedCaseInput: SyncClosedCaseInput;
  SyncDeviceResponse: SyncDeviceResponse;
  SyncDocumentToDriveInput: SyncDocumentToDriveInput;
  SyncDocumentToDriveOptions: SyncDocumentToDriveOptions;
  ThirdPartyFinancedAmount: ThirdPartyFinancedAmount;
  Ticket: Ticket;
  TicketComment: TicketComment;
  Time: Scalars['Time']['output'];
  TransactionPaymentMethod: TransactionPaymentMethod;
  TwiceMonthly: TwiceMonthly;
  TwiceMonthlyOutput: TwiceMonthlyOutput;
  URL: Scalars['URL']['output'];
  UTMInput: UtmInput;
  UWAddress: UwAddress;
  UWAddressInput: UwAddressInput;
  UWUser: UwUser;
  UnderwritingFinancingOptionInput: UnderwritingFinancingOptionInput;
  UpdateAttorneyInput: UpdateAttorneyInput;
  UpdateCaseCourtInfoInput: UpdateCaseCourtInfoInput;
  UpdateCaseDocumentFormFieldsInput: UpdateCaseDocumentFormFieldsInput;
  UpdateCaseDocumentFormStatusInput: UpdateCaseDocumentFormStatusInput;
  UpdateCaseDocumentTypeInput: UpdateCaseDocumentTypeInput;
  UpdateCaseEventInput: UpdateCaseEventInput;
  UpdateCaseTaskInput: UpdateCaseTaskInput;
  UpdateDefaultPaymentMethodInput: UpdateDefaultPaymentMethodInput;
  UpdateDocumentSharingWithClientPayload: UpdateDocumentSharingWithClientPayload;
  UpdateFormTemplateInput: UpdateFormTemplateInput;
  UpdateLegalProductInput: UpdateLegalProductInput;
  UpdateSFRefundTicketInput: UpdateSfRefundTicketInput;
  UpdateUserInput: UpdateUserInput;
  UpdateWebLeadInput: UpdateWebLeadInput;
  UpdateWhatIsNextInput: UpdateWhatIsNextInput;
  UpdatedCaseDocumentStatus: UpdatedCaseDocumentStatus;
  Upload: Scalars['Upload']['output'];
  UpsertCaseTaskResult: Omit<UpsertCaseTaskResult, 'case' | 'caseTask'> & { case?: Maybe<ResolversParentTypes['Case']>, caseTask: ResolversParentTypes['CaseTask'] };
  UpsertDiyFunnelData: UpsertDiyFunnelData;
  UserFinancingOptionOrNull: UserFinancingOptionOrNull;
  UserRoleName: UserRoleName;
  UsersWithCalendarIssues: UsersWithCalendarIssues;
  VoucherDiscount: VoucherDiscount;
  WhatIsNext: WhatIsNext;
  WireTransferMethod: WireTransferMethod;
  WisetackLoanAmountStatus: WisetackLoanAmountStatus;
  WisetackLoanLink: WisetackLoanLink;
  WisetackPrequalLink: WisetackPrequalLink;
};

export type AccountForAchResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountForAch'] = ResolversParentTypes['AccountForAch']> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mask?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountStatus'] = ResolversParentTypes['AccountStatus']> = {
  accountNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amountAvailable?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amountUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AchDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['AchData'] = ResolversParentTypes['AchData']> = {
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddendumResolvers<ContextType = any, ParentType extends ResolversParentTypes['Addendum'] = ResolversParentTypes['Addendum']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numOfPayments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  opportunityExternalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Adjustment'] = ResolversParentTypes['Adjustment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  opportunityExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']> = {
  alternativeEmails?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailAddress']>>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['Notification']>>>, ParentType, ContextType, RequireFields<AdminNotificationsArgs, 'filter'>>;
  unreadNotificationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppConfigurationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppConfigurations'] = ResolversParentTypes['AppConfigurations']> = {
  client?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  configurations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppInstallationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppInstallation'] = ResolversParentTypes['AppInstallation']> = {
  appBundleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deviceBrand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceModel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceOS?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceOSVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notificationsAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttorneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attorney'] = ResolversParentTypes['Attorney']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  attorneyType?: Resolver<Maybe<ResolversTypes['AttorneyType']>, ParentType, ContextType>;
  barLicenseExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  barLicenseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  barNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cafNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendlyEmail?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  calendlyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<AttorneyCaseArgs, 'id'>>;
  caseCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType>;
  casesOffLimits?: Resolver<Maybe<Array<Maybe<ResolversTypes['CasesOffLimits']>>>, ParentType, ContextType>;
  casesOffLimitsOther?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conservatorship?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contestedDivorce?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  earning?: Resolver<Maybe<ResolversTypes['Earning']>, ParentType, ContextType, RequireFields<AttorneyEarningArgs, 'billingDate'>>;
  earnings?: Resolver<Array<Maybe<ResolversTypes['Earning']>>, ParentType, ContextType>;
  education?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  familyCourtNY?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  guardianship?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  handlesCases?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  inHouse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  juvenileCrime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  languages?: Resolver<Array<ResolversTypes['Language']>, ParentType, ContextType>;
  licensingJurisdiction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  litigation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  lssCase?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<AttorneyLssCaseArgs, 'id'>>;
  lssCases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType>;
  lssCasesByView?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType, RequireFields<AttorneyLssCasesByViewArgs, 'input'>>;
  marbleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matrimonialCourtNY?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  numberOfActiveCases?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfAssociates?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfLegalSecretaries?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfParalegals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pendingEarnings?: Resolver<Maybe<ResolversTypes['Earning']>, ParentType, ContextType>;
  performsLSS?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phones?: Resolver<ResolversTypes['Phones'], ParentType, ContextType>;
  practiceAreas?: Resolver<Array<ResolversTypes['PracticeArea']>, ParentType, ContextType>;
  prenup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ptin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesforceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortDeadline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  toggleHandlesCasesAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  togglePerformsLSSAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  totalEarnings?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutopayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Autopay'] = ResolversParentTypes['Autopay']> = {
  agreementDocumentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentInstrumentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchCreatePreAuthLinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchCreatePreAuthLinkResponse'] = ResolversParentTypes['BatchCreatePreAuthLinkResponse']> = {
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BillingCycleOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingCycleOutput'] = ResolversParentTypes['BillingCycleOutput']> = {
  everyTwoWeeks?: Resolver<Maybe<ResolversTypes['EveryTwoWeeksOutput']>, ParentType, ContextType>;
  monthly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  singleTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twiceMonthly?: Resolver<Maybe<ResolversTypes['TwiceMonthlyOutput']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentFrequency'], ParentType, ContextType>;
  weekly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bonus'] = ResolversParentTypes['Bonus']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrazeMigrationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrazeMigrationResponse'] = ResolversParentTypes['BrazeMigrationResponse']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendarAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendarAvailability'] = ResolversParentTypes['CalendarAvailability']> = {
  eventType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freeSlotsToday?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hoursThreshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isWithinThreshold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nextSpot?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallLog'] = ResolversParentTypes['CallLog']> = {
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['CallLogCreatedBy'], ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purpose?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rep?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  touchpoint?: Resolver<ResolversTypes['TouchpointOptions'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  utcDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallLogCreatedByResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallLogCreatedBy'] = ResolversParentTypes['CallLogCreatedBy']> = {
  adminId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelScheduledEventsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelScheduledEventsResponse'] = ResolversParentTypes['CancelScheduledEventsResponse']> = {
  cancelledEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['CancelledEvent']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelledEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelledEvent'] = ResolversParentTypes['CancelledEvent']> = {
  cancelled?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaptureTransactionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaptureTransactionResponse'] = ResolversParentTypes['CaptureTransactionResponse']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  funding?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Case'] = ResolversParentTypes['Case']> = {
  additionalExternalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  attorneyFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  callLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['CallLog']>>>, ParentType, ContextType>;
  caseManager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseManagerCalendlyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseManagerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseManagerInfo?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType>;
  caseParalegal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseParalegalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseParalegalInfo?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType>;
  caseReviewMeetingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changeLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChangeLog']>>>, ParentType, ContextType>;
  clientPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  courtInfo?: Resolver<Maybe<ResolversTypes['CourtInfo']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  criticalDates?: Resolver<Maybe<Array<ResolversTypes['CaseOfferCriticalDate']>>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  dispatchAgent?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType>;
  documents?: Resolver<Array<Maybe<ResolversTypes['CaseDocument']>>, ParentType, ContextType>;
  engagementLetterSignUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseEvent']>>>, ParentType, ContextType, Partial<CaseEventsArgs>>;
  externalCreatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formFields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intakeMeeting?: Resolver<Maybe<ResolversTypes['IntakeMeeting']>, ParentType, ContextType>;
  isAttorneyCompletedStage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isIntroductionCallStage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isLssAttorneyCompletedSummary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isLssRejectedPcNoShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUrgentDispatch?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCustomerContact?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  latestLssSummary?: Resolver<Maybe<ResolversTypes['LssSummary']>, ParentType, ContextType>;
  legalCircleMeetingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legalProductIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  legalProducts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseLegalProduct']>>>, ParentType, ContextType>;
  legalServices?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseLegalService']>>>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['CaseLocation']>, ParentType, ContextType>;
  lssAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  lssAttorneyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lssSummaries?: Resolver<Maybe<Array<ResolversTypes['LssSummary']>>, ParentType, ContextType>;
  milestone?: Resolver<Maybe<ResolversTypes['CaseMilestone']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseNote']>>>, ParentType, ContextType>;
  opposingParty?: Resolver<ResolversTypes['OpposingParty'], ParentType, ContextType>;
  optionalServices?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseService']>>>, ParentType, ContextType>;
  practiceArea?: Resolver<Maybe<ResolversTypes['PracticeArea']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requiredDocuments?: Resolver<Array<Maybe<ResolversTypes['RequiredDocument']>>, ParentType, ContextType>;
  requiredDocumentsData?: Resolver<Maybe<ResolversTypes['CaseRequiredDocumentsData']>, ParentType, ContextType>;
  responsibleAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  responsibleAttorneyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['Stage']>, ParentType, ContextType>;
  stageTasksInfo?: Resolver<Maybe<ResolversTypes['CaseTasksInfo']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CaseStatus']>, ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseTask']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CaseType']>, ParentType, ContextType>;
  updates?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseUpdate']>>>, ParentType, ContextType>;
  whatIsNext?: Resolver<Maybe<ResolversTypes['WhatIsNext']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCostTicketDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCostTicketData'] = ResolversParentTypes['CaseCostTicketData']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeOfFee?: Resolver<Maybe<ResolversTypes['TypeOfFee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseDocument'] = ResolversParentTypes['CaseDocument']> = {
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['CaseDocumentCategory'], ParentType, ContextType>;
  classification?: Resolver<Maybe<ResolversTypes['DocumentClassification']>, ParentType, ContextType>;
  classificationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRead?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  s3Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharingOptions?: Resolver<Maybe<ResolversTypes['CaseDocumentSharingOptions']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['CaseDocumentSource']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseDocumentStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseDocumentFormResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseDocumentForm'] = ResolversParentTypes['CaseDocumentForm']> = {
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  classification?: Resolver<Maybe<ResolversTypes['DocumentClassification']>, ParentType, ContextType>;
  classificationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseDocumentFormField']>>>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sharingOptions?: Resolver<Maybe<ResolversTypes['CaseDocumentSharingOptions']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['CaseDocumentSource']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseDocumentStatus'], ParentType, ContextType>;
  template?: Resolver<ResolversTypes['FormTemplate'], ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseDocumentFormFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseDocumentFormField'] = ResolversParentTypes['CaseDocumentFormField']> = {
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FormTemplateFieldType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseDocumentSharingOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseDocumentSharingOptions'] = ResolversParentTypes['CaseDocumentSharingOptions']> = {
  attorney?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  client?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseEvent'] = ResolversParentTypes['CaseEvent']> = {
  arbitraryLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  clioId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['CaseEventCreatedBy']>, ParentType, ContextType>;
  eventSubtype?: Resolver<Maybe<ResolversTypes['CaseEventSubtype']>, ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['CaseEventType'], ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['CaseEventLocation']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occursAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  occursAtTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['CaseEventSource'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  virtualLocation?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseEventCreatedByResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseEventCreatedBy'] = ResolversParentTypes['CaseEventCreatedBy']> = {
  adminId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseEventLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseEventLocation'] = ResolversParentTypes['CaseEventLocation']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseFilters'] = ResolversParentTypes['CaseFilters']> = {
  legalServicesFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegalServiceSection']>>>, ParentType, ContextType>;
  lpFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['FilterVal']>>>, ParentType, ContextType>;
  managersFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['FilterVal']>>>, ParentType, ContextType>;
  milestoneFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseMilestone']>>>, ParentType, ContextType>;
  paralegalsFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['FilterVal']>>>, ParentType, ContextType>;
  scopeFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseLegalProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseLegalProduct'] = ResolversParentTypes['CaseLegalProduct']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseLegalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseLegalService'] = ResolversParentTypes['CaseLegalService']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  downgrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legalService?: Resolver<Maybe<ResolversTypes['LegalService']>, ParentType, ContextType>;
  legalServiceExternalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseLocation'] = ResolversParentTypes['CaseLocation']> = {
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseMeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseMeeting'] = ResolversParentTypes['CaseMeeting']> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseNote'] = ResolversParentTypes['CaseNote']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseOfferCriticalDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseOfferCriticalDate'] = ResolversParentTypes['CaseOfferCriticalDate']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CaseOfferCriticalDateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRequiredDocumentsDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRequiredDocumentsData'] = ResolversParentTypes['CaseRequiredDocumentsData']> = {
  ignoredIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  requiredIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseService'] = ResolversParentTypes['CaseService']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseSubentityFailedObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseSubentityFailedObject'] = ResolversParentTypes['CaseSubentityFailedObject']> = {
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseSubentityMigratedObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseSubentityMigratedObject'] = ResolversParentTypes['CaseSubentityMigratedObject']> = {
  flareId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseSubentityMigrationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseSubentityMigrationResponse'] = ResolversParentTypes['CaseSubentityMigrationResponse']> = {
  failed?: Resolver<Array<Maybe<ResolversTypes['CaseSubentityFailedObject']>>, ParentType, ContextType>;
  migrated?: Resolver<Array<Maybe<ResolversTypes['CaseSubentityMigratedObject']>>, ParentType, ContextType>;
  skipped?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTask'] = ResolversParentTypes['CaseTask']> = {
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  caseStage?: Resolver<ResolversTypes['StageEnum'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Owner']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerType?: Resolver<ResolversTypes['CaseTaskOwnerType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseTaskStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTaskStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTaskStatus'] = ResolversParentTypes['CaseTaskStatus']> = {
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['CaseTaskStatusValue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTasksInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTasksInfo'] = ResolversParentTypes['CaseTasksInfo']> = {
  completed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentTask?: Resolver<Maybe<ResolversTypes['CaseTask']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseUpdate'] = ResolversParentTypes['CaseUpdate']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isUrgent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  occursAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  sharingOptions?: Resolver<Maybe<ResolversTypes['CaseUpdateSharingOptions']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['CaseUpdateSources'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userRead?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseUpdateSharingOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseUpdateSharingOptions'] = ResolversParentTypes['CaseUpdateSharingOptions']> = {
  attorney?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  client?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasesResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasesResults'] = ResolversParentTypes['CasesResults']> = {
  cases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangeLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeLog'] = ResolversParentTypes['ChangeLog']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  entityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  entityType?: Resolver<ResolversTypes['ChangeLogEntityTypes'], ParentType, ContextType>;
  fieldName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  newValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oldValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckCalendarsAvailabilityResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckCalendarsAvailabilityResponse'] = ResolversParentTypes['CheckCalendarsAvailabilityResponse']> = {
  failed?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  succeeded?: Resolver<Array<Maybe<ResolversTypes['CalendarAvailability']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClawbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['Clawback'] = ResolversParentTypes['Clawback']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  opportunityExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClosedCaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedCase'] = ResolversParentTypes['ClosedCase']> = {
  driveCreationTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  driveId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodeVerificationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodeVerificationResponse'] = ResolversParentTypes['CodeVerificationResponse']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourtInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourtInfo'] = ResolversParentTypes['CourtInfo']> = {
  caseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoveredCarePrequalDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoveredCarePrequalData'] = ResolversParentTypes['CoveredCarePrequalData']> = {
  applicationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incompletePrequal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  offers?: Resolver<Maybe<Array<Maybe<ResolversTypes['CoveredCarePrequalOffer']>>>, ParentType, ContextType>;
  startUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  takenLoanAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoveredCarePrequalLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoveredCarePrequalLink'] = ResolversParentTypes['CoveredCarePrequalLink']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoveredCarePrequalOfferResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoveredCarePrequalOffer'] = ResolversParentTypes['CoveredCarePrequalOffer']> = {
  LoanGradeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  downPayment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  downPaymentMinPct?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  downPaymentResult?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estMonthlyPaymentAmt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expireDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loanAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  loanInterest?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  marketingMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  matrixGUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxDownPaymentAmt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minDownPaymentAmt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minPaymentAmt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  offerGUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  term?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoveredCarePrequalResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CoveredCarePrequalResult'] = ResolversParentTypes['CoveredCarePrequalResult']> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAttorneyCodeVerificationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAttorneyCodeVerificationResponse'] = ResolversParentTypes['CreateAttorneyCodeVerificationResponse']> = {
  countdown?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCodeVerificationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCodeVerificationResponse'] = ResolversParentTypes['CreateCodeVerificationResponse']> = {
  countdown?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePayoutItemBulkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePayoutItemBulkResponse'] = ResolversParentTypes['CreatePayoutItemBulkResponse']> = {
  responses?: Resolver<Array<ResolversTypes['CreatePayoutItemResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePayoutItemErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePayoutItemError'] = ResolversParentTypes['CreatePayoutItemError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePayoutItemResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePayoutItemResponse'] = ResolversParentTypes['CreatePayoutItemResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['PayoutItem']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<ResolversTypes['CreatePayoutItemError']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateShortLivedTokenOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateShortLivedTokenOutput'] = ResolversParentTypes['CreateShortLivedTokenOutput']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerState?: Resolver<Maybe<ResolversTypes['CustomerState']>, ParentType, ContextType>;
  diyFunnelData?: Resolver<Maybe<ResolversTypes['DiyFunnelData']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formData?: Resolver<Maybe<ResolversTypes['FormData']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  marbleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  paymentAccount?: Resolver<Maybe<ResolversTypes['PaymentAccount']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeData?: Resolver<Maybe<ResolversTypes['StripeData']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['VoucherDiscount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerFinancingSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerFinancingSummary'] = ResolversParentTypes['CustomerFinancingSummary']> = {
  amountToFinance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  downPaymentsPaidAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paidLssAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thirdPartyFinancedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDiscount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPaid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerState'] = ResolversParentTypes['CustomerState']> = {
  blockAllCommunications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  emailConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstEmailSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  postBookingShown?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submittedForms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerStripeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerStripeData'] = ResolversParentTypes['CustomerStripeData']> = {
  stripeCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripeCustomerSecretKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DiyFunnelDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiyFunnelData'] = ResolversParentTypes['DiyFunnelData']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAfterOnboarding?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAlreadyViewed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['DiyFunnelLocation']>, ParentType, ContextType>;
  selectedService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stepsData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiyFunnelLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiyFunnelLocation'] = ResolversParentTypes['DiyFunnelLocation']> = {
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassification'] = ResolversParentTypes['DocumentClassification']> = {
  category?: Resolver<Maybe<ResolversTypes['DocumentClassificationCategory']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationCategory'] = ResolversParentTypes['DocumentClassificationCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationsWithCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationsWithCategory'] = ResolversParentTypes['DocumentClassificationsWithCategory']> = {
  category?: Resolver<ResolversTypes['DocumentClassificationCategory'], ParentType, ContextType>;
  classifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType>;
  topClassifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownPayment'] = ResolversParentTypes['DownPayment']> = {
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownPaymentsPaidAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownPaymentsPaidAmount'] = ResolversParentTypes['DownPaymentsPaidAmount']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriveDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['DriveDocument'] = ResolversParentTypes['DriveDocument']> = {
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  driveCreationTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  driveId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EarningResolvers<ContextType = any, ParentType extends ResolversParentTypes['Earning'] = ResolversParentTypes['Earning']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  caseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payoutItems?: Resolver<Array<ResolversTypes['PayoutItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type EveryTwoWeeksOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EveryTwoWeeksOutput'] = ResolversParentTypes['EveryTwoWeeksOutput']> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fee'] = ResolversParentTypes['Fee']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterValResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterVal'] = ResolversParentTypes['FilterVal']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FinancingOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancingOption'] = ResolversParentTypes['FinancingOption']> = {
  amountToFinance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  downPayment?: Resolver<Maybe<ResolversTypes['DownPayment']>, ParentType, ContextType>;
  financingTermsOptions?: Resolver<Maybe<Array<ResolversTypes['FinancingTerms']>>, ParentType, ContextType>;
  installment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['FinancingOptionProvider'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FinancingTermsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancingTerms'] = ResolversParentTypes['FinancingTerms']> = {
  amountToFinance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  downPayment?: Resolver<Maybe<ResolversTypes['DownPayment']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  installment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interestRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormResolvers<ContextType = any, ParentType extends ResolversParentTypes['Form'] = ResolversParentTypes['Form']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormData'] = ResolversParentTypes['FormData']> = {
  formData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormField'] = ResolversParentTypes['FormField']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormTemplate'] = ResolversParentTypes['FormTemplate']> = {
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormTemplateField']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<Maybe<ResolversTypes['PracticeArea']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormTemplateFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormTemplateField'] = ResolversParentTypes['FormTemplateField']> = {
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FormTemplateFieldType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneratePaymentAgreementResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneratePaymentAgreementResponse'] = ResolversParentTypes['GeneratePaymentAgreementResponse']> = {
  contractURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenesisLoanAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenesisLoanAccount'] = ResolversParentTypes['GenesisLoanAccount']> = {
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  availableCredit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenesisLoanApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenesisLoanApplication'] = ResolversParentTypes['GenesisLoanApplication']> = {
  approvedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bank?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenesisLoanTermsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenesisLoanTerms'] = ResolversParentTypes['GenesisLoanTerms']> = {
  base64Html?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenesisPrequalApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenesisPrequalApplication'] = ResolversParentTypes['GenesisPrequalApplication']> = {
  approvedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bank?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestedAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCustomerRiskLevelResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCustomerRiskLevelResult'] = ResolversParentTypes['GetCustomerRiskLevelResult']> = {
  riskLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetLegalServicesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetLegalServicesResult'] = ResolversParentTypes['GetLegalServicesResult']> = {
  legalServices?: Resolver<Array<Maybe<ResolversTypes['LegalService']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetOrCreatePaymentPlanAccountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetOrCreatePaymentPlanAccountResponse'] = ResolversParentTypes['GetOrCreatePaymentPlanAccountResponse']> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentPlanProvider?: Resolver<Maybe<ResolversTypes['PaymentPlanProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPaginatedDiyUsersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPaginatedDiyUsersResponse'] = ResolversParentTypes['GetPaginatedDiyUsersResponse']> = {
  results?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiyFunnelData']>>>, ParentType, ContextType>;
  totalResults?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetRefundTicketByIdResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetRefundTicketByIdResponse'] = ResolversParentTypes['GetRefundTicketByIdResponse']> = {
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amountPaid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  opportunityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  refundAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  refundReason?: Resolver<ResolversTypes['RefundReason'], ParentType, ContextType>;
  services?: Resolver<Array<ResolversTypes['ServiceOnOpportunity']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HashResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['HashResponse'] = ResolversParentTypes['HashResponse']> = {
  algorithm?: Resolver<Maybe<ResolversTypes['HashAlgorithm']>, ParentType, ContextType>;
  hashed?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICaseDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ICaseDocument'] = ResolversParentTypes['ICaseDocument']> = {
  __resolveType: TypeResolveFn<'CaseDocument' | 'CaseDocumentForm', ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  classificationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['CaseDocumentSource']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseDocumentStatus'], ParentType, ContextType>;
};

export type IFormTemplateFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['IFormTemplateField'] = ResolversParentTypes['IFormTemplateField']> = {
  __resolveType: TypeResolveFn<'CaseDocumentFormField' | 'FormTemplateField', ParentType, ContextType>;
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FormTemplateFieldType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type IdentityVerificationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityVerificationOutput'] = ResolversParentTypes['IdentityVerificationOutput']> = {
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idvRequestId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['IdentityVerificationStatus'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InHousePrequalApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InHousePrequalApplication'] = ResolversParentTypes['InHousePrequalApplication']> = {
  borrowerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dealId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['InHousePrequalApplicationMetadata']>, ParentType, ContextType>;
  offers?: Resolver<Maybe<Array<Maybe<ResolversTypes['InHousePrequalApplicationOffer']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InHousePrequalApplicationMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['InHousePrequalApplicationMetadata'] = ResolversParentTypes['InHousePrequalApplicationMetadata']> = {
  minDpAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minDpPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InHousePrequalApplicationOfferResolvers<ContextType = any, ParentType extends ResolversParentTypes['InHousePrequalApplicationOffer'] = ResolversParentTypes['InHousePrequalApplicationOffer']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  downPayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  installments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  interestRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minDpPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstallmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Installment'] = ResolversParentTypes['Installment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  amountTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numOfPayments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  opportunityExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntakeMeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntakeMeeting'] = ResolversParentTypes['IntakeMeeting']> = {
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notesUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntakeMeetingSlotsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntakeMeetingSlots'] = ResolversParentTypes['IntakeMeetingSlots']> = {
  slotTimeSpan?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slots?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LeadResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lead'] = ResolversParentTypes['Lead']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalProduct'] = ResolversParentTypes['LegalProduct']> = {
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalService'] = ResolversParentTypes['LegalService']> = {
  advancedBilling?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billingPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buyingPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chargeScheduling?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chargeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commitment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  earlyTermination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  indexValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  itemCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loanAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  loanApprovalDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  minimumUnit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pricePlanItem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pricingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prorationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantityUnitOfMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredDocuments?: Resolver<Array<Maybe<ResolversTypes['RequiredDocument']>>, ParentType, ContextType>;
  requiredDocumentsIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  serviceCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceDescriptionFormatted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceSKU?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceType?: Resolver<ResolversTypes['LegalServiceType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subzeeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timelineDescriptionLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalServiceProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalServiceProvider'] = ResolversParentTypes['LegalServiceProvider']> = {
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legalArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalServiceSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalServiceSection'] = ResolversParentTypes['LegalServiceSection']> = {
  serviceCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LenderOfferResolvers<ContextType = any, ParentType extends ResolversParentTypes['LenderOffer'] = ResolversParentTypes['LenderOffer']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  downPayment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expirationDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  installments?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  interestRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkTokenCreateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkTokenCreateResponse'] = ResolversParentTypes['LinkTokenCreateResponse']> = {
  linkToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoanProviderResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoanProvider'] = ResolversParentTypes['LoanProvider']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  applicationProcessId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['LenderName'], ParentType, ContextType>;
  lenderRefId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginByTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginByTokenResponse'] = ResolversParentTypes['LoginByTokenResponse']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssCaseInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssCaseInfo'] = ResolversParentTypes['LssCaseInfo']> = {
  appearance?: Resolver<Maybe<ResolversTypes['LssAppearance']>, ParentType, ContextType>;
  county?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courtCaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filingOrResponding?: Resolver<Maybe<ResolversTypes['LssFilingOrResponding']>, ParentType, ContextType>;
  opposingParty?: Resolver<Maybe<ResolversTypes['Name']>, ParentType, ContextType>;
  practiceArea?: Resolver<ResolversTypes['PracticeArea'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  urgent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssClientDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssClientDetails'] = ResolversParentTypes['LssClientDetails']> = {
  birthday?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  charges?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['LssGender'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  numberOfChildren?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssCriticalDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssCriticalDate'] = ResolversParentTypes['LssCriticalDate']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['LssCriticalDateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssRejectedResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssRejected'] = ResolversParentTypes['LssRejected']> = {
  other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['LssRejectionReason'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssRescheduledResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssRescheduled'] = ResolversParentTypes['LssRescheduled']> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssSummary'] = ResolversParentTypes['LssSummary']> = {
  additionalServices?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attorneyAcceptedCase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  caseInfo?: Resolver<Maybe<ResolversTypes['LssCaseInfo']>, ParentType, ContextType>;
  clientDetails?: Resolver<Maybe<ResolversTypes['LssClientDetails']>, ParentType, ContextType>;
  clientGoals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  criticalDates?: Resolver<Maybe<Array<ResolversTypes['LssCriticalDate']>>, ParentType, ContextType>;
  executiveSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalServicesIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  financialInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impressionOfSelectedClientValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impressionsOfClients?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meetingTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  recommendedServices?: Resolver<Maybe<Array<ResolversTypes['RecommendedServiceDetails']>>, ParentType, ContextType>;
  rejected?: Resolver<Maybe<ResolversTypes['LssRejected']>, ParentType, ContextType>;
  rescheduled?: Resolver<Maybe<ResolversTypes['LssRescheduled']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MakeLoanPaymentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MakeLoanPaymentResponse'] = ResolversParentTypes['MakeLoanPaymentResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarkAllNotificationsAsReadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarkAllNotificationsAsReadResult'] = ResolversParentTypes['MarkAllNotificationsAsReadResult']> = {
  identity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MigrateCaseDocumentsToVinnyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MigrateCaseDocumentsToVinnyResponse'] = ResolversParentTypes['MigrateCaseDocumentsToVinnyResponse']> = {
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failed?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  flareCaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  migrated?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  skipped?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MigrateToVinnyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MigrateToVinnyResponse'] = ResolversParentTypes['MigrateToVinnyResponse']> = {
  caseEvents?: Resolver<Maybe<ResolversTypes['CaseSubentityMigrationResponse']>, ParentType, ContextType>;
  caseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseUpdates?: Resolver<Maybe<ResolversTypes['CaseSubentityMigrationResponse']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flareCaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flareServiceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissedPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissedPayment'] = ResolversParentTypes['MissedPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  attempt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  billingCycle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chargeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  resultDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  activateLoan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationActivateLoanArgs, 'input'>>;
  addAdminAlternativeEmail?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType, RequireFields<MutationAddAdminAlternativeEmailArgs, 'email'>>;
  addCallLog?: Resolver<Maybe<ResolversTypes['CallLog']>, ParentType, ContextType, Partial<MutationAddCallLogArgs>>;
  addCaseEvent?: Resolver<Maybe<ResolversTypes['CaseEvent']>, ParentType, ContextType, Partial<MutationAddCaseEventArgs>>;
  addCaseNote?: Resolver<Maybe<ResolversTypes['CaseNote']>, ParentType, ContextType, Partial<MutationAddCaseNoteArgs>>;
  addCaseTask?: Resolver<Maybe<ResolversTypes['UpsertCaseTaskResult']>, ParentType, ContextType, Partial<MutationAddCaseTaskArgs>>;
  addCaseUpdate?: Resolver<Maybe<ResolversTypes['CaseUpdate']>, ParentType, ContextType, Partial<MutationAddCaseUpdateArgs>>;
  addCaseUpdateWithTags?: Resolver<Maybe<ResolversTypes['CaseUpdate']>, ParentType, ContextType, Partial<MutationAddCaseUpdateWithTagsArgs>>;
  addDocumentClassification?: Resolver<Maybe<ResolversTypes['DocumentClassification']>, ParentType, ContextType, RequireFields<MutationAddDocumentClassificationArgs, 'payload'>>;
  addPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddPaymentArgs, 'input'>>;
  addPaymentMethodToCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationAddPaymentMethodToCustomerArgs>>;
  addRequiredDocument?: Resolver<Maybe<ResolversTypes['RequiredDocument']>, ParentType, ContextType, RequireFields<MutationAddRequiredDocumentArgs, 'payload'>>;
  addRequiredDocumentToCase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddRequiredDocumentToCaseArgs, 'caseId' | 'requiredDocumentId'>>;
  archiveCallLog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationArchiveCallLogArgs, 'callLogId'>>;
  archiveCaseDocument?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, RequireFields<MutationArchiveCaseDocumentArgs, 'caseDocumentId'>>;
  archiveCaseEvent?: Resolver<Maybe<ResolversTypes['CaseEvent']>, ParentType, ContextType, RequireFields<MutationArchiveCaseEventArgs, 'id'>>;
  archiveFormTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationArchiveFormTemplateArgs, 'id'>>;
  authorizeAndCaptureTransaction?: Resolver<Maybe<ResolversTypes['CaptureTransactionResponse']>, ParentType, ContextType, RequireFields<MutationAuthorizeAndCaptureTransactionArgs, 'input'>>;
  batchCreatePreAuthLink?: Resolver<Maybe<Array<Maybe<ResolversTypes['BatchCreatePreAuthLinkResponse']>>>, ParentType, ContextType, RequireFields<MutationBatchCreatePreAuthLinkArgs, 'emails'>>;
  batchDeletePactsafeContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationBatchDeletePactsafeContractArgs, 'input'>>;
  cancelAutopay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationCancelAutopayArgs>>;
  cancelLoanApplication?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCancelLoanApplicationArgs, 'input'>>;
  cancelPaymentOnHold?: Resolver<ResolversTypes['PaymentOnHold'], ParentType, ContextType, RequireFields<MutationCancelPaymentOnHoldArgs, 'input'>>;
  cancelScheduledEvent?: Resolver<Maybe<ResolversTypes['CancelledEvent']>, ParentType, ContextType, RequireFields<MutationCancelScheduledEventArgs, 'input'>>;
  cancelScheduledEvents?: Resolver<Maybe<ResolversTypes['CancelScheduledEventsResponse']>, ParentType, ContextType, RequireFields<MutationCancelScheduledEventsArgs, 'input'>>;
  capturePaymentOnHold?: Resolver<ResolversTypes['PaymentOnHold'], ParentType, ContextType, RequireFields<MutationCapturePaymentOnHoldArgs, 'input'>>;
  codeVerification?: Resolver<Maybe<ResolversTypes['CodeVerificationResponse']>, ParentType, ContextType, Partial<MutationCodeVerificationArgs>>;
  codeVerificationForEmail?: Resolver<Maybe<ResolversTypes['CodeVerificationResponse']>, ParentType, ContextType, Partial<MutationCodeVerificationForEmailArgs>>;
  collectOverdueBalance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCollectOverdueBalanceArgs, 'input'>>;
  contactUs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationContactUsArgs>>;
  createAch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateAchArgs, 'input'>>;
  createAdmin?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType, Partial<MutationCreateAdminArgs>>;
  createAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType, Partial<MutationCreateAttorneyArgs>>;
  createAttorneyCodeVerification?: Resolver<Maybe<ResolversTypes['CreateAttorneyCodeVerificationResponse']>, ParentType, ContextType, RequireFields<MutationCreateAttorneyCodeVerificationArgs, 'phone'>>;
  createCaseDocumentForm?: Resolver<Maybe<ResolversTypes['CaseDocumentForm']>, ParentType, ContextType, Partial<MutationCreateCaseDocumentFormArgs>>;
  createCodeVerification?: Resolver<Maybe<ResolversTypes['CreateCodeVerificationResponse']>, ParentType, ContextType, Partial<MutationCreateCodeVerificationArgs>>;
  createCodeVerificationWithEmail?: Resolver<Maybe<ResolversTypes['CreateCodeVerificationResponse']>, ParentType, ContextType, Partial<MutationCreateCodeVerificationWithEmailArgs>>;
  createCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, Partial<MutationCreateCustomerArgs>>;
  createFilingFeePaymentPlanComponent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateFilingFeePaymentPlanComponentArgs, 'input'>>;
  createForms?: Resolver<Maybe<Array<Maybe<ResolversTypes['Form']>>>, ParentType, ContextType, Partial<MutationCreateFormsArgs>>;
  createIdentityVerification?: Resolver<Maybe<ResolversTypes['IdentityVerificationOutput']>, ParentType, ContextType, RequireFields<MutationCreateIdentityVerificationArgs, 'input'>>;
  createLegalProduct?: Resolver<Maybe<ResolversTypes['LegalProduct']>, ParentType, ContextType, Partial<MutationCreateLegalProductArgs>>;
  createLinkToken?: Resolver<ResolversTypes['LinkTokenCreateResponse'], ParentType, ContextType, RequireFields<MutationCreateLinkTokenArgs, 'input'>>;
  createMeetingBookingUrl?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateMeetingBookingUrlArgs, 'input'>>;
  createPayInFullPaymentPlanComponent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreatePayInFullPaymentPlanComponentArgs, 'input'>>;
  createPaymentOnHold?: Resolver<ResolversTypes['PaymentOnHold'], ParentType, ContextType, RequireFields<MutationCreatePaymentOnHoldArgs, 'input'>>;
  createPaymentPlanComponent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreatePaymentPlanComponentArgs, 'input'>>;
  createPayoutItemBulk?: Resolver<Maybe<ResolversTypes['CreatePayoutItemBulkResponse']>, ParentType, ContextType, RequireFields<MutationCreatePayoutItemBulkArgs, 'input'>>;
  createRejectedCustomer?: Resolver<Maybe<ResolversTypes['RejectedCustomer']>, ParentType, ContextType, RequireFields<MutationCreateRejectedCustomerArgs, 'rejectedCustomer'>>;
  createShortLivedToken?: Resolver<Maybe<ResolversTypes['CreateShortLivedTokenOutput']>, ParentType, ContextType>;
  createWebLead?: Resolver<ResolversTypes['Lead'], ParentType, ContextType, RequireFields<MutationCreateWebLeadArgs, 'input'>>;
  createWisetackLoanLink?: Resolver<ResolversTypes['WisetackLoanLink'], ParentType, ContextType, RequireFields<MutationCreateWisetackLoanLinkArgs, 'input'>>;
  createWisetackPrequalLink?: Resolver<ResolversTypes['WisetackPrequalLink'], ParentType, ContextType, RequireFields<MutationCreateWisetackPrequalLinkArgs, 'userId'>>;
  deleteCustomerPaymentMethod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationDeleteCustomerPaymentMethodArgs>>;
  deleteDevVerificationCode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationDeleteDevVerificationCodeArgs>>;
  deleteDownPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDownPaymentArgs, 'input'>>;
  deletePactsafeContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeletePactsafeContractArgs, 'input'>>;
  deletePeachCustomerPaymentMethodByExternalId?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeletePeachCustomerPaymentMethodByExternalIdArgs, 'paymentMethodId'>>;
  deleteThirdPartyPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteThirdPartyPaymentArgs, 'input'>>;
  deployPortal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeployPortalArgs, 'environment'>>;
  disableAllMarketingMessages?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDisableAllMarketingMessagesArgs, 'customerId'>>;
  editRecurringPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationEditRecurringPaymentArgs, 'input'>>;
  editThirdPartyPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationEditThirdPartyPaymentArgs, 'input'>>;
  enableAutopay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationEnableAutopayArgs>>;
  endRecurringPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationEndRecurringPaymentArgs, 'input'>>;
  exchangePublicToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationExchangePublicTokenArgs, 'input'>>;
  fetchTransactionsDataForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationFetchTransactionsDataForCustomerArgs, 'input'>>;
  generateEl?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationGenerateElArgs, 'input'>>;
  generateLead?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationGenerateLeadArgs, 'lead'>>;
  generateLeadFromSMS?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType, RequireFields<MutationGenerateLeadFromSmsArgs, 'lead'>>;
  generateLoanApplicationLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationGenerateLoanApplicationLinkArgs, 'input'>>;
  generatePaymentAgreement?: Resolver<ResolversTypes['GeneratePaymentAgreementResponse'], ParentType, ContextType, RequireFields<MutationGeneratePaymentAgreementArgs, 'accountId' | 'opportunityId' | 'signature'>>;
  generatePowerOfAttorney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationGeneratePowerOfAttorneyArgs, 'input'>>;
  generatePrequalLink?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationGeneratePrequalLinkArgs, 'input'>>;
  genesisAcceptOffer?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGenesisAcceptOfferArgs, 'userId'>>;
  genesisCaptureFunds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationGenesisCaptureFundsArgs, 'amount' | 'opportunityId' | 'userId'>>;
  genesisStartPrequal?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGenesisStartPrequalArgs, 'input'>>;
  getCustomerStripeData?: Resolver<ResolversTypes['CustomerStripeData'], ParentType, ContextType>;
  getOrCreatePaymentPlanAccount?: Resolver<ResolversTypes['GetOrCreatePaymentPlanAccountResponse'], ParentType, ContextType, RequireFields<MutationGetOrCreatePaymentPlanAccountArgs, 'input'>>;
  hash?: Resolver<Maybe<ResolversTypes['HashResponse']>, ParentType, ContextType, RequireFields<MutationHashArgs, 'input'>>;
  impersonateAdminLogin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationImpersonateAdminLoginArgs, 'email'>>;
  impersonateByApiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationImpersonateByApiKeyArgs, 'input'>>;
  initiateSMSConversation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationInitiateSmsConversationArgs>>;
  login?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'password'>>;
  loginAsCustomer?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType, RequireFields<MutationLoginAsCustomerArgs, 'email'>>;
  loginByCognitoToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationLoginByCognitoTokenArgs, 'input'>>;
  loginByGoogleToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<MutationLoginByGoogleTokenArgs>>;
  loginByMail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationLoginByMailArgs, 'email'>>;
  loginByPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationLoginByPasswordArgs, 'email' | 'password'>>;
  loginByToken?: Resolver<Maybe<ResolversTypes['LoginByTokenResponse']>, ParentType, ContextType, RequireFields<MutationLoginByTokenArgs, 'token'>>;
  loginDemoUserByGoogle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<MutationLoginDemoUserByGoogleArgs>>;
  lssCancelAndRefundRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLssCancelAndRefundRequestArgs, 'input'>>;
  lssRefundRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLssRefundRequestArgs, 'input'>>;
  makeLoanPayment?: Resolver<ResolversTypes['MakeLoanPaymentResponse'], ParentType, ContextType, RequireFields<MutationMakeLoanPaymentArgs, 'input'>>;
  markAllNotificationsAsRead?: Resolver<Maybe<ResolversTypes['MarkAllNotificationsAsReadResult']>, ParentType, ContextType>;
  migrateAttorneys?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateBrazeProfiles?: Resolver<Maybe<ResolversTypes['BrazeMigrationResponse']>, ParentType, ContextType, Partial<MutationMigrateBrazeProfilesArgs>>;
  migrateCaseDocumentsToVinny?: Resolver<Maybe<ResolversTypes['MigrateCaseDocumentsToVinnyResponse']>, ParentType, ContextType, RequireFields<MutationMigrateCaseDocumentsToVinnyArgs, 'caseId' | 'token'>>;
  migrateOneAttorney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationMigrateOneAttorneyArgs, 'id'>>;
  migrateToDiy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationMigrateToDiyArgs, 'opportunityIds'>>;
  migrateToVinny?: Resolver<Maybe<Array<Maybe<ResolversTypes['MigrateToVinnyResponse']>>>, ParentType, ContextType, RequireFields<MutationMigrateToVinnyArgs, 'payload'>>;
  notificationOpened?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationNotificationOpenedArgs, 'notificationId'>>;
  notificationsViewed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationNotificationsViewedArgs, 'notificationIds'>>;
  payTotalOutstanding?: Resolver<Maybe<ResolversTypes['PayTotalOutstandingResponse']>, ParentType, ContextType, Partial<MutationPayTotalOutstandingArgs>>;
  paypalCancelPrequalApplication?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationPaypalCancelPrequalApplicationArgs>>;
  paypalCapture?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPaypalCaptureArgs, 'checkout'>>;
  paypalStartPrequalApplication?: Resolver<ResolversTypes['PrequalResponse'], ParentType, ContextType, RequireFields<MutationPaypalStartPrequalApplicationArgs, 'input'>>;
  postAffirmEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationPostAffirmEventArgs, 'input'>>;
  postLssForm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPostLssFormArgs, 'input'>>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRefreshTokenArgs, 'token'>>;
  refundLoan?: Resolver<ResolversTypes['RefundLoanOutput'], ParentType, ContextType, Partial<MutationRefundLoanArgs>>;
  refundLoanApplication?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRefundLoanApplicationArgs, 'input'>>;
  refundTransactions?: Resolver<Maybe<Array<ResolversTypes['RefundTransactionResult']>>, ParentType, ContextType, RequireFields<MutationRefundTransactionsArgs, 'accountId' | 'opportunityId' | 'reason' | 'signature' | 'ticketId' | 'transactions'>>;
  registerDeviceToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRegisterDeviceTokenArgs, 'app' | 'platform' | 'token'>>;
  registerLpDeviceToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRegisterLpDeviceTokenArgs, 'platform' | 'token'>>;
  registerStripeCustomer?: Resolver<ResolversTypes['RegisterStripeCustomerResponse'], ParentType, ContextType, RequireFields<MutationRegisterStripeCustomerArgs, 'input'>>;
  removeRequiredDocument?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveRequiredDocumentArgs, 'id'>>;
  removeRequiredDocumentFromCase?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRemoveRequiredDocumentFromCaseArgs, 'caseId' | 'requiredDocumentId'>>;
  rescheduleLss?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRescheduleLssArgs, 'input'>>;
  rescheduleLssMeeting?: Resolver<Maybe<ResolversTypes['LssSummary']>, ParentType, ContextType, RequireFields<MutationRescheduleLssMeetingArgs, 'input'>>;
  restoreCaseDocument?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, RequireFields<MutationRestoreCaseDocumentArgs, 'caseDocumentId'>>;
  runCoveredCarePrequal?: Resolver<Maybe<ResolversTypes['CoveredCarePrequalResult']>, ParentType, ContextType, Partial<MutationRunCoveredCarePrequalArgs>>;
  runCreditCheck?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRunCreditCheckArgs, 'input'>>;
  runCreditCheckManualUtility?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationRunCreditCheckManualUtilityArgs>>;
  scheduleLssMeetingReminder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationScheduleLssMeetingReminderArgs, 'input'>>;
  sendAddServicesRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSendAddServicesRequestArgs>>;
  sendAttorneyFeedback?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSendAttorneyFeedbackArgs>>;
  sendDocsToClio?: Resolver<Maybe<Array<Maybe<ResolversTypes['UpdatedCaseDocumentStatus']>>>, ParentType, ContextType, Partial<MutationSendDocsToClioArgs>>;
  sendDocumentsPack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSendDocumentsPackArgs>>;
  sendLpNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationSendLpNotificationArgs, 'pushNotificationInput'>>;
  sendNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationSendNotificationArgs, 'pushNotificationInput'>>;
  sendPayoutMails?: Resolver<Maybe<ResolversTypes['SendPayoutMailsResponse']>, ParentType, ContextType, Partial<MutationSendPayoutMailsArgs>>;
  sendSMS?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSendSmsArgs, 'SMSContent' | 'toNumbers' | 'userId'>>;
  setCaseDocumentFilename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetCaseDocumentFilenameArgs, 'filename' | 'id'>>;
  setCaseUpdateStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetCaseUpdateStatusArgs, 'id'>>;
  setCaseWithFlareId?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetCaseWithFlareIdArgs>>;
  setDevVerificationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<MutationSetDevVerificationCodeArgs>>;
  setNotificationsAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetNotificationsAllowedArgs, 'setNotificationsAllowedInput'>>;
  signupOrLogin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSignupOrLoginArgs, 'apiKey' | 'customer'>>;
  startLoanApplication?: Resolver<ResolversTypes['StartLoanApplicationOutput'], ParentType, ContextType, Partial<MutationStartLoanApplicationArgs>>;
  submitCaseForm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSubmitCaseFormArgs, 'caseId'>>;
  submitLssSummary?: Resolver<Maybe<ResolversTypes['LssSummary']>, ParentType, ContextType, RequireFields<MutationSubmitLssSummaryArgs, 'input'>>;
  submitRejectedLssSummary?: Resolver<Maybe<ResolversTypes['LssSummary']>, ParentType, ContextType, RequireFields<MutationSubmitRejectedLssSummaryArgs, 'input'>>;
  submitRequestForDpPolicyChange?: Resolver<ResolversTypes['SubmitRequestForDpPolicyChangeResult'], ParentType, ContextType, RequireFields<MutationSubmitRequestForDpPolicyChangeArgs, 'input'>>;
  syncCaseFromOpportunity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSyncCaseFromOpportunityArgs, 'input'>>;
  syncClosedCaseInDrive?: Resolver<Maybe<ResolversTypes['ClosedCase']>, ParentType, ContextType, Partial<MutationSyncClosedCaseInDriveArgs>>;
  syncDeviceInfo?: Resolver<Maybe<ResolversTypes['SyncDeviceResponse']>, ParentType, ContextType, RequireFields<MutationSyncDeviceInfoArgs, 'appInstallationInput'>>;
  syncDocumentToDrive?: Resolver<Maybe<ResolversTypes['DriveDocument']>, ParentType, ContextType, Partial<MutationSyncDocumentToDriveArgs>>;
  syncUserToBraze?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSyncUserToBrazeArgs>>;
  underwritingCreateThirdPartyUser?: Resolver<ResolversTypes['UWUser'], ParentType, ContextType, RequireFields<MutationUnderwritingCreateThirdPartyUserArgs, 'input'>>;
  underwritingSetUser?: Resolver<ResolversTypes['UWUser'], ParentType, ContextType, RequireFields<MutationUnderwritingSetUserArgs, 'input'>>;
  updateAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType, Partial<MutationUpdateAttorneyArgs>>;
  updateCaseCourtInfo?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, Partial<MutationUpdateCaseCourtInfoArgs>>;
  updateCaseDocument?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, Partial<MutationUpdateCaseDocumentArgs>>;
  updateCaseDocumentClassification?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, Partial<MutationUpdateCaseDocumentClassificationArgs>>;
  updateCaseDocumentFormClassification?: Resolver<Maybe<ResolversTypes['CaseDocumentForm']>, ParentType, ContextType, Partial<MutationUpdateCaseDocumentFormClassificationArgs>>;
  updateCaseDocumentFormFields?: Resolver<Maybe<ResolversTypes['CaseDocumentForm']>, ParentType, ContextType, Partial<MutationUpdateCaseDocumentFormFieldsArgs>>;
  updateCaseDocumentFormStatus?: Resolver<Maybe<ResolversTypes['UpdatedCaseDocumentStatus']>, ParentType, ContextType, Partial<MutationUpdateCaseDocumentFormStatusArgs>>;
  updateCaseEvent?: Resolver<Maybe<ResolversTypes['CaseEvent']>, ParentType, ContextType, Partial<MutationUpdateCaseEventArgs>>;
  updateCaseStage?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<MutationUpdateCaseStageArgs, 'caseId' | 'newStage'>>;
  updateCaseTask?: Resolver<Maybe<ResolversTypes['UpsertCaseTaskResult']>, ParentType, ContextType, Partial<MutationUpdateCaseTaskArgs>>;
  updateCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationUpdateCustomerArgs, 'customer'>>;
  updateCustomerDefaultPaymentMethod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationUpdateCustomerDefaultPaymentMethodArgs>>;
  updateCustomerState?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateCustomerStateArgs, 'key' | 'value'>>;
  updateDefaultPaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateDefaultPaymentMethodArgs, 'input'>>;
  updateDocumentSharingWithClient?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, Partial<MutationUpdateDocumentSharingWithClientArgs>>;
  updateFormTemplate?: Resolver<Maybe<ResolversTypes['FormTemplate']>, ParentType, ContextType, Partial<MutationUpdateFormTemplateArgs>>;
  updateLPOnEl?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateLpOnElArgs, 'input'>>;
  updateLastCustomerContact?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<MutationUpdateLastCustomerContactArgs, 'caseId'>>;
  updateLegalProduct?: Resolver<Maybe<ResolversTypes['LegalProduct']>, ParentType, ContextType, Partial<MutationUpdateLegalProductArgs>>;
  updateSFEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateSfEventArgs, 'input'>>;
  updateSFrefundTicket?: Resolver<Maybe<ResolversTypes['GetRefundTicketByIdResponse']>, ParentType, ContextType, RequireFields<MutationUpdateSFrefundTicketArgs, 'input'>>;
  updateUnderwritingUser?: Resolver<ResolversTypes['UWUser'], ParentType, ContextType, RequireFields<MutationUpdateUnderwritingUserArgs, 'input'>>;
  updateWebLead?: Resolver<ResolversTypes['Lead'], ParentType, ContextType, RequireFields<MutationUpdateWebLeadArgs, 'input'>>;
  updateWhatIsNext?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, Partial<MutationUpdateWhatIsNextArgs>>;
  upsertDiyFunnelData?: Resolver<Maybe<ResolversTypes['DiyFunnelData']>, ParentType, ContextType, RequireFields<MutationUpsertDiyFunnelDataArgs, 'payload'>>;
  upsertFormData?: Resolver<Maybe<ResolversTypes['FormData']>, ParentType, ContextType, Partial<MutationUpsertFormDataArgs>>;
};

export type NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Name'] = ResolversParentTypes['Name']> = {
  first?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NextPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NextPayment'] = ResolversParentTypes['NextPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chargeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  additionalData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['NotificationCategory']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['NotificationData']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationStatus?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  notificationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sound?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationData'] = ResolversParentTypes['NotificationData']> = {
  actionData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  actionType?: Resolver<Maybe<ResolversTypes['ActionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnboardingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Onboarding'] = ResolversParentTypes['Onboarding']> = {
  engagementLetterSigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Fee']>, ParentType, ContextType>;
  lssMeeting?: Resolver<Maybe<ResolversTypes['ScheduledMeeting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnboardingProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingProgress'] = ResolversParentTypes['OnboardingProgress']> = {
  step?: Resolver<Maybe<ResolversTypes['OnboardingStepProgress']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnboardingStepProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingStepProgress'] = ResolversParentTypes['OnboardingStepProgress']> = {
  stepId?: Resolver<ResolversTypes['OnboardingStepId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OneTimePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['OneTimePlan'] = ResolversParentTypes['OneTimePlan']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentFrequency?: Resolver<Maybe<ResolversTypes['PaymentFrequency']>, ParentType, ContextType>;
  paymentMethodLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['PaymentPlanItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpportunityLinkFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpportunityLinkFields'] = ResolversParentTypes['OpportunityLinkFields']> = {
  accountFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  agentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  agentPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpportunityPaymentDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpportunityPaymentData'] = ResolversParentTypes['OpportunityPaymentData']> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  action_text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ccAuthorized?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deal_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice_area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeAccount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpportunityStageResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpportunityStage'] = ResolversParentTypes['OpportunityStage']> = {
  stage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpposingPartyResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpposingParty'] = ResolversParentTypes['OpposingParty']> = {
  name?: Resolver<ResolversTypes['PartialName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner']> = {
  __resolveType: TypeResolveFn<'Admin' | 'Attorney' | 'Customer', ParentType, ContextType>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaidLssAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaidLssAmount'] = ResolversParentTypes['PaidLssAmount']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialAttorneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialAttorney'] = ResolversParentTypes['PartialAttorney']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  adminOnboardingStatus?: Resolver<ResolversTypes['AdminOnboardingStatus'], ParentType, ContextType>;
  attorneyType?: Resolver<Maybe<ResolversTypes['AttorneyType']>, ParentType, ContextType>;
  barNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cafNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendlyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  casesOffLimits?: Resolver<Maybe<Array<Maybe<ResolversTypes['CasesOffLimits']>>>, ParentType, ContextType>;
  casesOffLimitsOther?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conservatorship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contestedDivorce?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contractLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  education?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  familyCourtNY?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  guardianship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  handlesCases?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  inHouse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  juvenileCrime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<ResolversTypes['Language']>>, ParentType, ContextType>;
  licensingJurisdiction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  litigation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  matrimonialCourtNY?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  performsLSS?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  phones?: Resolver<Maybe<ResolversTypes['Phones']>, ParentType, ContextType>;
  practiceAreas?: Resolver<Maybe<Array<ResolversTypes['PracticeArea']>>, ParentType, ContextType>;
  prenup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ptin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesforceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortDeadline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialName'] = ResolversParentTypes['PartialName']> = {
  first?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialPaymentAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialPaymentAccount'] = ResolversParentTypes['PartialPaymentAccount']> = {
  nextPayment?: Resolver<Maybe<ResolversTypes['NextPayment']>, ParentType, ContextType>;
  totalDebt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PastPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PastPayment'] = ResolversParentTypes['PastPayment']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chargeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loanId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  p_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PastTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PastTransaction'] = ResolversParentTypes['PastTransaction']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loanId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['TransactionPaymentMethod']>, ParentType, ContextType>;
  paymentProvider?: Resolver<ResolversTypes['TransactionPaymentProvider'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['TransactionsProvider'], ParentType, ContextType>;
  refundAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TransactionsStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransactionsType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayTotalOutstandingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayTotalOutstandingResponse'] = ResolversParentTypes['PayTotalOutstandingResponse']> = {
  accountHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amountPaid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paymentMethodMask?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayerFinancingOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayerFinancingOptions'] = ResolversParentTypes['PayerFinancingOptions']> = {
  financingOptions?: Resolver<Maybe<Array<ResolversTypes['FinancingOption']>>, ParentType, ContextType>;
  payer?: Resolver<Maybe<ResolversTypes['UWUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  methods?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentAccount'] = ResolversParentTypes['PaymentAccount']> = {
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  financingType?: Resolver<Maybe<ResolversTypes['FinancingType']>, ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isEligibleForFillingFees?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  missedPayments?: Resolver<Maybe<Array<ResolversTypes['MissedPayment']>>, ParentType, ContextType>;
  nextPayment?: Resolver<Maybe<ResolversTypes['NextPayment']>, ParentType, ContextType>;
  pastPayments?: Resolver<Maybe<Array<ResolversTypes['PastPayment']>>, ParentType, ContextType>;
  paymentPlanProvider?: Resolver<Maybe<ResolversTypes['PaymentPlanProvider']>, ParentType, ContextType>;
  paymentStatus?: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>;
  plans?: Resolver<Maybe<Array<ResolversTypes['PaymentPlan']>>, ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalBookings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDebt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalOutstanding?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalOutstandingAttempted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPaid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unacknowledgedOutstandingBalancePayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentAgreementResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentAgreementResponse'] = ResolversParentTypes['PaymentAgreementResponse']> = {
  paSigned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  __resolveType: TypeResolveFn<'AchData' | 'Card' | 'WireTransferMethod', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type PaymentOnHoldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentOnHold'] = ResolversParentTypes['PaymentOnHold']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethodLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripePaymentIntentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripePaymentStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlan'] = ResolversParentTypes['PaymentPlan']> = {
  caseExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  components?: Resolver<Array<ResolversTypes['PaymentPlanComponent']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanComponentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanComponent'] = ResolversParentTypes['PaymentPlanComponent']> = {
  __resolveType: TypeResolveFn<'OneTimePlan' | 'RecurringPlan', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentFrequency?: Resolver<Maybe<ResolversTypes['PaymentFrequency']>, ParentType, ContextType>;
  paymentMethodLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['PaymentPlanItem'], ParentType, ContextType>;
};

export type PaymentPlanConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanConfig'] = ResolversParentTypes['PaymentPlanConfig']> = {
  minDownpaymentRatio?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  thirdPartyLoanAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanData'] = ResolversParentTypes['PaymentPlanData']> = {
  paymentPlans?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentPlanSetupPaymentPlan']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanSetupAccountDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanSetupAccountData'] = ResolversParentTypes['PaymentPlanSetupAccountData']> = {
  accountHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  achData?: Resolver<Maybe<ResolversTypes['AchData']>, ParentType, ContextType>;
  customerFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  financingType?: Resolver<Maybe<ResolversTypes['FinancingType']>, ParentType, ContextType>;
  opportunities?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentPlanSetupOpportunity']>>>, ParentType, ContextType>;
  paymentMethods?: Resolver<Maybe<Array<Maybe<ResolversTypes['Card']>>>, ParentType, ContextType>;
  paymentPlanProvider?: Resolver<Maybe<ResolversTypes['PaymentPlanProvider']>, ParentType, ContextType>;
  paymentPlans?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentPlanSetupPaymentPlan']>>>, ParentType, ContextType>;
  payments?: Resolver<Maybe<Array<Maybe<ResolversTypes['PastPayment']>>>, ParentType, ContextType>;
  paymentsOnHold?: Resolver<Maybe<Array<ResolversTypes['PaymentOnHold']>>, ParentType, ContextType>;
  totalBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalBookings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalOutstanding?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanSetupOpportunityResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanSetupOpportunity'] = ResolversParentTypes['PaymentPlanSetupOpportunity']> = {
  actualTotalCost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legalCaseStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityOwner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  opportunityRecordType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  servicesWithFilingFees?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thirdPartyFinanceAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyFinanceApprovalDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanSetupPaymentPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanSetupPaymentPlan'] = ResolversParentTypes['PaymentPlanSetupPaymentPlan']> = {
  components?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentPlanSetupPaymentPlanComponent']>>>, ParentType, ContextType>;
  opportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanSetupPaymentPlanComponentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanSetupPaymentPlanComponent'] = ResolversParentTypes['PaymentPlanSetupPaymentPlanComponent']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  autopay?: Resolver<Maybe<ResolversTypes['Autopay']>, ParentType, ContextType>;
  autopayAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  billingCycle?: Resolver<Maybe<ResolversTypes['BillingCycleOutput']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downPayments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  financeCompany?: Resolver<Maybe<ResolversTypes['ThirdPartyProviderType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  installments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  overdueBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paidBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paymentMethodLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  servicer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentPlanSetupPaymentPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlansPaymentMethodsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlansPaymentMethods'] = ResolversParentTypes['PaymentPlansPaymentMethods']> = {
  paymentMethods?: Resolver<Maybe<Array<Maybe<ResolversTypes['Card']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayoutItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutItem'] = ResolversParentTypes['PayoutItem']> = {
  __resolveType: TypeResolveFn<'Addendum' | 'Adjustment' | 'Bonus' | 'Clawback' | 'Installment', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  attorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PayoutItemType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
};

export type PaypalClientTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaypalClientTokenResponse'] = ResolversParentTypes['PaypalClientTokenResponse']> = {
  clientToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingOnboardingAttorneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PendingOnboardingAttorney'] = ResolversParentTypes['PendingOnboardingAttorney']> = {
  attorney?: Resolver<ResolversTypes['PartialAttorney'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isOnboarded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber';
}

export type PhonesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Phones'] = ResolversParentTypes['Phones']> = {
  external?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumber']>>>, ParentType, ContextType>;
  fax?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumber']>>>, ParentType, ContextType>;
  personal?: Resolver<Array<Maybe<ResolversTypes['PhoneNumber']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrequalApplicationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrequalApplicationOutput'] = ResolversParentTypes['PrequalApplicationOutput']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  applicationProcessId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['LenderName'], ParentType, ContextType>;
  lenderRefId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offers?: Resolver<Maybe<Array<Maybe<ResolversTypes['LenderOffer']>>>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['GqlPrequalApplicationStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrequalResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrequalResponse'] = ResolversParentTypes['PrequalResponse']> = {
  loanApplicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  allAttorneys?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attorney']>>>, ParentType, ContextType, RequireFields<QueryAllAttorneysArgs, 'apiKey'>>;
  checkAttonreysWithSlots?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  checkCalendarsAvailability?: Resolver<Maybe<ResolversTypes['CheckCalendarsAvailabilityResponse']>, ParentType, ContextType, Partial<QueryCheckCalendarsAvailabilityArgs>>;
  genesisGetAccount?: Resolver<Maybe<ResolversTypes['GenesisLoanAccount']>, ParentType, ContextType, RequireFields<QueryGenesisGetAccountArgs, 'userId'>>;
  genesisGetLoanApplication?: Resolver<Maybe<ResolversTypes['GenesisLoanApplication']>, ParentType, ContextType, RequireFields<QueryGenesisGetLoanApplicationArgs, 'userId'>>;
  genesisGetLoanTerms?: Resolver<Maybe<ResolversTypes['GenesisLoanTerms']>, ParentType, ContextType, RequireFields<QueryGenesisGetLoanTermsArgs, 'userId'>>;
  genesisGetPrequalApplication?: Resolver<Maybe<ResolversTypes['GenesisPrequalApplication']>, ParentType, ContextType, RequireFields<QueryGenesisGetPrequalApplicationArgs, 'userId'>>;
  getAccountStatuses?: Resolver<Array<ResolversTypes['AccountStatus']>, ParentType, ContextType, RequireFields<QueryGetAccountStatusesArgs, 'userId'>>;
  getAccountsForAch?: Resolver<Maybe<Array<ResolversTypes['AccountForAch']>>, ParentType, ContextType, RequireFields<QueryGetAccountsForAchArgs, 'input'>>;
  getActiveCasesFilters?: Resolver<Maybe<ResolversTypes['CaseFilters']>, ParentType, ContextType, Partial<QueryGetActiveCasesFiltersArgs>>;
  getAdmin?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType, Partial<QueryGetAdminArgs>>;
  getAdminNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['Notification']>>>, ParentType, ContextType, RequireFields<QueryGetAdminNotificationsArgs, 'filter'>>;
  getAdmins?: Resolver<Array<Maybe<ResolversTypes['Admin']>>, ParentType, ContextType>;
  getAdminsByTeams?: Resolver<Array<ResolversTypes['Admin']>, ParentType, ContextType, RequireFields<QueryGetAdminsByTeamsArgs, 'teams'>>;
  getAllBillingDates?: Resolver<Array<Maybe<ResolversTypes['Date']>>, ParentType, ContextType>;
  getAllFinancingOptions?: Resolver<Array<ResolversTypes['UserFinancingOptionOrNull']>, ParentType, ContextType, RequireFields<QueryGetAllFinancingOptionsArgs, 'input'>>;
  getAppConfigurations?: Resolver<Maybe<ResolversTypes['AppConfigurations']>, ParentType, ContextType, RequireFields<QueryGetAppConfigurationsArgs, 'input'>>;
  getAppInstallationsInfo?: Resolver<Maybe<ResolversTypes['AppInstallation']>, ParentType, ContextType, RequireFields<QueryGetAppInstallationsInfoArgs, 'userId'>>;
  getAppPrivateConfigurations?: Resolver<Maybe<ResolversTypes['AppConfigurations']>, ParentType, ContextType, RequireFields<QueryGetAppPrivateConfigurationsArgs, 'input'>>;
  getAppPublicConfigurations?: Resolver<Maybe<ResolversTypes['AppConfigurations']>, ParentType, ContextType, RequireFields<QueryGetAppPublicConfigurationsArgs, 'input'>>;
  getAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType, RequireFields<QueryGetAttorneyArgs, 'id'>>;
  getAttorneys?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attorney']>>>, ParentType, ContextType>;
  getCalendarsAvailability?: Resolver<Maybe<Array<Maybe<ResolversTypes['CalendarAvailability']>>>, ParentType, ContextType, RequireFields<QueryGetCalendarsAvailabilityArgs, 'input'>>;
  getCalendarsAvailabilityByUrl?: Resolver<Maybe<Array<Maybe<ResolversTypes['CalendarAvailability']>>>, ParentType, ContextType, RequireFields<QueryGetCalendarsAvailabilityByUrlArgs, 'input'>>;
  getCase?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<QueryGetCaseArgs, 'id'>>;
  getCaseByExternalId?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<QueryGetCaseByExternalIdArgs, 'externalId'>>;
  getCaseCostTicketDataById?: Resolver<Maybe<ResolversTypes['CaseCostTicketData']>, ParentType, ContextType, RequireFields<QueryGetCaseCostTicketDataByIdArgs, 'caseId'>>;
  getCaseDocument?: Resolver<Maybe<ResolversTypes['CaseDocument']>, ParentType, ContextType, RequireFields<QueryGetCaseDocumentArgs, 'id'>>;
  getCaseDocumentFormById?: Resolver<Maybe<ResolversTypes['CaseDocumentForm']>, ParentType, ContextType, Partial<QueryGetCaseDocumentFormByIdArgs>>;
  getCaseDocuments?: Resolver<Array<Maybe<ResolversTypes['CaseDocument']>>, ParentType, ContextType, RequireFields<QueryGetCaseDocumentsArgs, 'filter'>>;
  getCaseDocumentsByCaseId?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseDocument']>>>, ParentType, ContextType, RequireFields<QueryGetCaseDocumentsByCaseIdArgs, 'caseId'>>;
  getCaseDocumentsWithForms?: Resolver<Array<Maybe<ResolversTypes['ICaseDocument']>>, ParentType, ContextType, RequireFields<QueryGetCaseDocumentsWithFormsArgs, 'filter'>>;
  getCaseEventById?: Resolver<Maybe<ResolversTypes['CaseEvent']>, ParentType, ContextType, RequireFields<QueryGetCaseEventByIdArgs, 'id'>>;
  getCaseEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['CaseEvent']>>>, ParentType, ContextType, Partial<QueryGetCaseEventsArgs>>;
  getCases?: Resolver<Maybe<ResolversTypes['CasesResults']>, ParentType, ContextType, RequireFields<QueryGetCasesArgs, 'filter'>>;
  getChatHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['Ticket']>>>, ParentType, ContextType>;
  getCoveredCarePrequalData?: Resolver<Maybe<Array<ResolversTypes['CoveredCarePrequalData']>>, ParentType, ContextType, Partial<QueryGetCoveredCarePrequalDataArgs>>;
  getCoveredCarePrequalLink?: Resolver<ResolversTypes['CoveredCarePrequalLink'], ParentType, ContextType, RequireFields<QueryGetCoveredCarePrequalLinkArgs, 'userId'>>;
  getCurrent?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  getCurrentAdmin?: Resolver<Maybe<ResolversTypes['Admin']>, ParentType, ContextType>;
  getCurrentAttorney?: Resolver<Maybe<ResolversTypes['Attorney']>, ParentType, ContextType>;
  getCustomerCases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType>;
  getCustomerFinancingSummary?: Resolver<Maybe<ResolversTypes['CustomerFinancingSummary']>, ParentType, ContextType, RequireFields<QueryGetCustomerFinancingSummaryArgs, 'externalId' | 'opportunityId'>>;
  getCustomerNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['Notification']>>>, ParentType, ContextType, RequireFields<QueryGetCustomerNotificationsArgs, 'filter'>>;
  getCustomerPaymentPlanData?: Resolver<Maybe<ResolversTypes['PaymentPlanData']>, ParentType, ContextType>;
  getCustomerRiskLevel?: Resolver<ResolversTypes['GetCustomerRiskLevelResult'], ParentType, ContextType, RequireFields<QueryGetCustomerRiskLevelArgs, 'input'>>;
  getCustomerUnreadNotificationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  getDecryptedPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetDecryptedPhoneArgs, 'phone'>>;
  getDocument?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getDocumentClassificationsWithCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassificationsWithCategory']>>>, ParentType, ContextType>;
  getFormTemplate?: Resolver<Maybe<ResolversTypes['FormTemplate']>, ParentType, ContextType, RequireFields<QueryGetFormTemplateArgs, 'id'>>;
  getFormTemplates?: Resolver<Array<Maybe<ResolversTypes['FormTemplate']>>, ParentType, ContextType, Partial<QueryGetFormTemplatesArgs>>;
  getForms?: Resolver<Maybe<Array<Maybe<ResolversTypes['Form']>>>, ParentType, ContextType>;
  getFreeTaxFunnelPDF?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetFreeTaxFunnelPdfArgs, 'payload'>>;
  getIdentityVerification?: Resolver<Maybe<ResolversTypes['IdentityVerificationOutput']>, ParentType, ContextType, RequireFields<QueryGetIdentityVerificationArgs, 'input'>>;
  getInHouseFinancingType?: Resolver<Maybe<ResolversTypes['FinancingType']>, ParentType, ContextType>;
  getIntakeMeetingSlots?: Resolver<ResolversTypes['IntakeMeetingSlots'], ParentType, ContextType, RequireFields<QueryGetIntakeMeetingSlotsArgs, 'input'>>;
  getIsReadyForCaseStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<QueryGetIsReadyForCaseStatusArgs>>;
  getLeadIdByFunnelActionId?: Resolver<Maybe<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<QueryGetLeadIdByFunnelActionIdArgs, 'funnelActionId'>>;
  getLegalProducts?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegalProduct']>>>, ParentType, ContextType>;
  getLegalServices?: Resolver<ResolversTypes['GetLegalServicesResult'], ParentType, ContextType, RequireFields<QueryGetLegalServicesArgs, 'options'>>;
  getLssAttorneyCases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType>;
  getManagerCases?: Resolver<Maybe<Array<Maybe<ResolversTypes['Case']>>>, ParentType, ContextType, RequireFields<QueryGetManagerCasesArgs, 'filter'>>;
  getNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QueryGetNotificationArgs, 'id'>>;
  getOpportunityLinkFields?: Resolver<ResolversTypes['OpportunityLinkFields'], ParentType, ContextType, RequireFields<QueryGetOpportunityLinkFieldsArgs, 'input'>>;
  getOpportunityLinkFieldsByCaseId?: Resolver<ResolversTypes['OpportunityLinkFields'], ParentType, ContextType, RequireFields<QueryGetOpportunityLinkFieldsByCaseIdArgs, 'input'>>;
  getOpportunityPaymentData?: Resolver<ResolversTypes['OpportunityPaymentData'], ParentType, ContextType, RequireFields<QueryGetOpportunityPaymentDataArgs, 'input'>>;
  getOpportunityStage?: Resolver<Maybe<ResolversTypes['OpportunityStage']>, ParentType, ContextType, RequireFields<QueryGetOpportunityStageArgs, 'opportunityId'>>;
  getOpportunityThirdPartyPayerFinancingOptions?: Resolver<Array<ResolversTypes['PayerFinancingOptions']>, ParentType, ContextType, RequireFields<QueryGetOpportunityThirdPartyPayerFinancingOptionsArgs, 'input'>>;
  getPaginatedDiyUsers?: Resolver<Maybe<ResolversTypes['GetPaginatedDiyUsersResponse']>, ParentType, ContextType, Partial<QueryGetPaginatedDiyUsersArgs>>;
  getPaidDownPaymentAmount?: Resolver<ResolversTypes['DownPaymentsPaidAmount'], ParentType, ContextType, RequireFields<QueryGetPaidDownPaymentAmountArgs, 'input'>>;
  getPaidLssAmount?: Resolver<ResolversTypes['PaidLssAmount'], ParentType, ContextType, RequireFields<QueryGetPaidLssAmountArgs, 'input'>>;
  getPastTransactions?: Resolver<Maybe<Array<ResolversTypes['PastTransaction']>>, ParentType, ContextType, RequireFields<QueryGetPastTransactionsArgs, 'accountId' | 'signature'>>;
  getPaymentAccount?: Resolver<Maybe<ResolversTypes['PaymentAccount']>, ParentType, ContextType, RequireFields<QueryGetPaymentAccountArgs, 'id'>>;
  getPaymentAgreement?: Resolver<ResolversTypes['PaymentAgreementResponse'], ParentType, ContextType, RequireFields<QueryGetPaymentAgreementArgs, 'accountId' | 'opportunityId' | 'signature'>>;
  getPaymentPlanBalanceData?: Resolver<Maybe<ResolversTypes['PartialPaymentAccount']>, ParentType, ContextType>;
  getPaymentPlanSetupAccountData?: Resolver<Maybe<ResolversTypes['PaymentPlanSetupAccountData']>, ParentType, ContextType, RequireFields<QueryGetPaymentPlanSetupAccountDataArgs, 'accountId' | 'signature'>>;
  getPaymentsOnHold?: Resolver<Maybe<Array<ResolversTypes['PaymentOnHold']>>, ParentType, ContextType, RequireFields<QueryGetPaymentsOnHoldArgs, 'accountId' | 'signature'>>;
  getPayoutItems?: Resolver<Array<Maybe<ResolversTypes['PayoutItem']>>, ParentType, ContextType, Partial<QueryGetPayoutItemsArgs>>;
  getPendingOnboardingAttorneys?: Resolver<Maybe<Array<Maybe<ResolversTypes['PendingOnboardingAttorney']>>>, ParentType, ContextType>;
  getPendingSigningRequestDetails?: Resolver<Maybe<ResolversTypes['SigningRequestDetails']>, ParentType, ContextType, RequireFields<QueryGetPendingSigningRequestDetailsArgs, 'input'>>;
  getPrequalApplications?: Resolver<Array<ResolversTypes['PrequalApplicationOutput']>, ParentType, ContextType, RequireFields<QueryGetPrequalApplicationsArgs, 'userId'>>;
  getRefundAmountByReason?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryGetRefundAmountByReasonArgs, 'accountId' | 'opportunityId' | 'reason' | 'signature'>>;
  getRefundTicketById?: Resolver<ResolversTypes['GetRefundTicketByIdResponse'], ParentType, ContextType, RequireFields<QueryGetRefundTicketByIdArgs, 'input'>>;
  getRejectedCustomers?: Resolver<Maybe<Array<Maybe<ResolversTypes['RejectedCustomer']>>>, ParentType, ContextType>;
  getRequiredDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequiredDocument']>>>, ParentType, ContextType, RequireFields<QueryGetRequiredDocumentsArgs, 'options'>>;
  getSFAttorneyEvents?: Resolver<Array<ResolversTypes['SFEvent']>, ParentType, ContextType, RequireFields<QueryGetSfAttorneyEventsArgs, 'attorneyId'>>;
  getSFIntakeData?: Resolver<Maybe<ResolversTypes['SFIntakeData']>, ParentType, ContextType, RequireFields<QueryGetSfIntakeDataArgs, 'opportunityId'>>;
  getSFLegalServices?: Resolver<Maybe<Array<Maybe<ResolversTypes['SFLegalService']>>>, ParentType, ContextType, RequireFields<QueryGetSfLegalServicesArgs, 'input'>>;
  getSFServices?: Resolver<Array<ResolversTypes['SFService']>, ParentType, ContextType, RequireFields<QueryGetSfServicesArgs, 'input'>>;
  getSchedulingPageForUser?: Resolver<Maybe<Array<Maybe<ResolversTypes['Page']>>>, ParentType, ContextType, RequireFields<QueryGetSchedulingPageForUserArgs, 'input'>>;
  getServicesPaymentDataForOpportunity?: Resolver<ResolversTypes['ServicesPaymentDataForOpportunity'], ParentType, ContextType, RequireFields<QueryGetServicesPaymentDataForOpportunityArgs, 'opportunityId'>>;
  getSuggestedThirdPartyProvider?: Resolver<ResolversTypes['SuggestedThirdPartyProviderResponse'], ParentType, ContextType, RequireFields<QueryGetSuggestedThirdPartyProviderArgs, 'input'>>;
  getThirdPartyFinancedAmount?: Resolver<ResolversTypes['ThirdPartyFinancedAmount'], ParentType, ContextType, RequireFields<QueryGetThirdPartyFinancedAmountArgs, 'input'>>;
  getTopDocumentClassifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType>;
  getTransactionLoanAmountStatusByOpportunityId?: Resolver<ResolversTypes['WisetackLoanAmountStatus'], ParentType, ContextType, RequireFields<QueryGetTransactionLoanAmountStatusByOpportunityIdArgs, 'opportunityId'>>;
  getUserRoleName?: Resolver<Maybe<ResolversTypes['UserRoleName']>, ParentType, ContextType, RequireFields<QueryGetUserRoleNameArgs, 'salesforceId'>>;
  inHouseGetPrequalApplication?: Resolver<Maybe<ResolversTypes['InHousePrequalApplication']>, ParentType, ContextType, RequireFields<QueryInHouseGetPrequalApplicationArgs, 'opportunityId' | 'userId'>>;
  isBitlyLoanApplicationSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryIsBitlyLoanApplicationSentArgs, 'opportunityId'>>;
  loanProviderByExternalId?: Resolver<Array<ResolversTypes['LoanProvider']>, ParentType, ContextType, RequireFields<QueryLoanProviderByExternalIdArgs, 'externalId'>>;
  onboardingProgress?: Resolver<Maybe<ResolversTypes['OnboardingProgress']>, ParentType, ContextType, RequireFields<QueryOnboardingProgressArgs, 'input'>>;
  paypalGenerateClientToken?: Resolver<ResolversTypes['PaypalClientTokenResponse'], ParentType, ContextType>;
  renderContract?: Resolver<ResolversTypes['RenderContractResponse'], ParentType, ContextType, RequireFields<QueryRenderContractArgs, 'input'>>;
  underwritingFinancingOption?: Resolver<Maybe<ResolversTypes['FinancingOption']>, ParentType, ContextType, RequireFields<QueryUnderwritingFinancingOptionArgs, 'input'>>;
  underwritingFinancingOptionV2?: Resolver<Maybe<ResolversTypes['FinancingOption']>, ParentType, ContextType, RequireFields<QueryUnderwritingFinancingOptionV2Args, 'input'>>;
  underwritingGetUserByExternalId?: Resolver<Maybe<ResolversTypes['UWUser']>, ParentType, ContextType, RequireFields<QueryUnderwritingGetUserByExternalIdArgs, 'input'>>;
  underwritingUser?: Resolver<Maybe<ResolversTypes['UWUser']>, ParentType, ContextType, RequireFields<QueryUnderwritingUserArgs, 'input'>>;
};

export type RecommendedServiceDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedServiceDetails'] = ResolversParentTypes['RecommendedServiceDetails']> = {
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringPlan'] = ResolversParentTypes['RecurringPlan']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  autopay?: Resolver<Maybe<ResolversTypes['Autopay']>, ParentType, ContextType>;
  autopayAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  billingCycle?: Resolver<Maybe<ResolversTypes['BillingCycleOutput']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downPayments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  fromDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numOfPayments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  overdueBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paidBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paymentFrequency?: Resolver<ResolversTypes['PaymentFrequency'], ParentType, ContextType>;
  paymentMethodLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['PaymentPlanItem'], ParentType, ContextType>;
  servicer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundLoanOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundLoanOutput'] = ResolversParentTypes['RefundLoanOutput']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundTransactionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundTransactionResult'] = ResolversParentTypes['RefundTransactionResult']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterStripeCustomerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterStripeCustomerResponse'] = ResolversParentTypes['RegisterStripeCustomerResponse']> = {
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RejectedCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['RejectedCustomer'] = ResolversParentTypes['RejectedCustomer']> = {
  customerRejectionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  practiceArea?: Resolver<Maybe<ResolversTypes['PracticeArea']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RenderContractResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RenderContractResponse'] = ResolversParentTypes['RenderContractResponse']> = {
  contractHtml?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequiredDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequiredDocument'] = ResolversParentTypes['RequiredDocument']> = {
  classification?: Resolver<ResolversTypes['DocumentClassification'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legalServiceExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['SFEvent'] = ResolversParentTypes['SFEvent']> = {
  assignedAttorney?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfIntakeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SFIntakeData'] = ResolversParentTypes['SFIntakeData']> = {
  charges?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  county?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courtCaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courtDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  criticalDates?: Resolver<Maybe<Array<ResolversTypes['SFIntakeDataCriticalDate']>>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inTakeAgentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inTakeDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inTakeNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opposingPartyFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opposingPartyLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfIntakeDataCriticalDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SFIntakeDataCriticalDate'] = ResolversParentTypes['SFIntakeDataCriticalDate']> = {
  criticalDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  criticalDateType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfLegalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SFLegalService'] = ResolversParentTypes['SFLegalService']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  family?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SFService'] = ResolversParentTypes['SFService']> = {
  addendumProduct?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  family?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledMeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledMeeting'] = ResolversParentTypes['ScheduledMeeting']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meetingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendPayoutMailResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendPayoutMailResult'] = ResolversParentTypes['SendPayoutMailResult']> = {
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendPayoutMailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendPayoutMailsResponse'] = ResolversParentTypes['SendPayoutMailsResponse']> = {
  billingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  results?: Resolver<Array<Maybe<ResolversTypes['SendPayoutMailResult']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceOnOpportunityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceOnOpportunity'] = ResolversParentTypes['ServiceOnOpportunity']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceAfterDiscountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePaymentDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePaymentData'] = ResolversParentTypes['ServicePaymentData']> = {
  downgrade?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  serviceName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  servicePrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  servicePriceAfterDiscount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicesPaymentDataForOpportunityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicesPaymentDataForOpportunity'] = ResolversParentTypes['ServicesPaymentDataForOpportunity']> = {
  opportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  servicesPaymentData?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServicePaymentData']>>>, ParentType, ContextType>;
  totalBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDiscount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SigningRequestDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SigningRequestDetails'] = ResolversParentTypes['SigningRequestDetails']> = {
  completedBy?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  contractId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractTemplateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downloadLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  renderData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signingUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stage'] = ResolversParentTypes['Stage']> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['StageEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartLoanApplicationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StartLoanApplicationOutput'] = ResolversParentTypes['StartLoanApplicationOutput']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['LenderName'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeData'] = ResolversParentTypes['StripeData']> = {
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitRequestForDpPolicyChangeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmitRequestForDpPolicyChangeResult'] = ResolversParentTypes['SubmitRequestForDpPolicyChangeResult']> = {
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedThirdPartyProviderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestedThirdPartyProviderResponse'] = ResolversParentTypes['SuggestedThirdPartyProviderResponse']> = {
  creditCheckErrorCode?: Resolver<Maybe<ResolversTypes['CreditCheckErrorCode']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['ThirdPartyProviderType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncDeviceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncDeviceResponse'] = ResolversParentTypes['SyncDeviceResponse']> = {
  isSupported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThirdPartyFinancedAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThirdPartyFinancedAmount'] = ResolversParentTypes['ThirdPartyFinancedAmount']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ticket'] = ResolversParentTypes['Ticket']> = {
  comments?: Resolver<Array<ResolversTypes['TicketComment']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['TicketComment'] = ResolversParentTypes['TicketComment']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sentByUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export type TransactionPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionPaymentMethod'] = ResolversParentTypes['TransactionPaymentMethod']> = {
  brand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransactionPaymentMethodType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwiceMonthlyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TwiceMonthlyOutput'] = ResolversParentTypes['TwiceMonthlyOutput']> = {
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UwAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['UWAddress'] = ResolversParentTypes['UWAddress']> = {
  apartment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UwUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['UWUser'] = ResolversParentTypes['UWUser']> = {
  address?: Resolver<Maybe<ResolversTypes['UWAddress']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobileNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthlyIncome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ssn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyPayerForExternalId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatedCaseDocumentStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatedCaseDocumentStatus'] = ResolversParentTypes['UpdatedCaseDocumentStatus']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseDocumentStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UpsertCaseTaskResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertCaseTaskResult'] = ResolversParentTypes['UpsertCaseTaskResult']> = {
  case?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType>;
  caseTask?: Resolver<ResolversTypes['CaseTask'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFinancingOptionOrNullResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFinancingOptionOrNull'] = ResolversParentTypes['UserFinancingOptionOrNull']> = {
  amountToFinance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downPayment?: Resolver<Maybe<ResolversTypes['DownPayment']>, ParentType, ContextType>;
  installment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['FinancingOptionProvider']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRoleNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRoleName'] = ResolversParentTypes['UserRoleName']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersWithCalendarIssuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersWithCalendarIssues'] = ResolversParentTypes['UsersWithCalendarIssues']> = {
  calendarsNotConfigured?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  calendarsToReconnect?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherDiscount'] = ResolversParentTypes['VoucherDiscount']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentOff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhatIsNextResolvers<ContextType = any, ParentType extends ResolversParentTypes['WhatIsNext'] = ResolversParentTypes['WhatIsNext']> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['WhatIsNextEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WireTransferMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['WireTransferMethod'] = ResolversParentTypes['WireTransferMethod']> = {
  bankName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisetackLoanAmountStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisetackLoanAmountStatus'] = ResolversParentTypes['WisetackLoanAmountStatus']> = {
  status?: Resolver<ResolversTypes['LoanAmountStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisetackLoanLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisetackLoanLink'] = ResolversParentTypes['WisetackLoanLink']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisetackPrequalLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisetackPrequalLink'] = ResolversParentTypes['WisetackPrequalLink']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccountForAch?: AccountForAchResolvers<ContextType>;
  AccountStatus?: AccountStatusResolvers<ContextType>;
  AchData?: AchDataResolvers<ContextType>;
  Addendum?: AddendumResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  Adjustment?: AdjustmentResolvers<ContextType>;
  Admin?: AdminResolvers<ContextType>;
  AppConfigurations?: AppConfigurationsResolvers<ContextType>;
  AppInstallation?: AppInstallationResolvers<ContextType>;
  Attorney?: AttorneyResolvers<ContextType>;
  Autopay?: AutopayResolvers<ContextType>;
  BatchCreatePreAuthLinkResponse?: BatchCreatePreAuthLinkResponseResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  BillingCycleOutput?: BillingCycleOutputResolvers<ContextType>;
  Bonus?: BonusResolvers<ContextType>;
  BrazeMigrationResponse?: BrazeMigrationResponseResolvers<ContextType>;
  CalendarAvailability?: CalendarAvailabilityResolvers<ContextType>;
  CallLog?: CallLogResolvers<ContextType>;
  CallLogCreatedBy?: CallLogCreatedByResolvers<ContextType>;
  CancelScheduledEventsResponse?: CancelScheduledEventsResponseResolvers<ContextType>;
  CancelledEvent?: CancelledEventResolvers<ContextType>;
  CaptureTransactionResponse?: CaptureTransactionResponseResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  Case?: CaseResolvers<ContextType>;
  CaseCostTicketData?: CaseCostTicketDataResolvers<ContextType>;
  CaseDocument?: CaseDocumentResolvers<ContextType>;
  CaseDocumentForm?: CaseDocumentFormResolvers<ContextType>;
  CaseDocumentFormField?: CaseDocumentFormFieldResolvers<ContextType>;
  CaseDocumentSharingOptions?: CaseDocumentSharingOptionsResolvers<ContextType>;
  CaseEvent?: CaseEventResolvers<ContextType>;
  CaseEventCreatedBy?: CaseEventCreatedByResolvers<ContextType>;
  CaseEventLocation?: CaseEventLocationResolvers<ContextType>;
  CaseFilters?: CaseFiltersResolvers<ContextType>;
  CaseLegalProduct?: CaseLegalProductResolvers<ContextType>;
  CaseLegalService?: CaseLegalServiceResolvers<ContextType>;
  CaseLocation?: CaseLocationResolvers<ContextType>;
  CaseMeeting?: CaseMeetingResolvers<ContextType>;
  CaseNote?: CaseNoteResolvers<ContextType>;
  CaseOfferCriticalDate?: CaseOfferCriticalDateResolvers<ContextType>;
  CaseRequiredDocumentsData?: CaseRequiredDocumentsDataResolvers<ContextType>;
  CaseService?: CaseServiceResolvers<ContextType>;
  CaseSubentityFailedObject?: CaseSubentityFailedObjectResolvers<ContextType>;
  CaseSubentityMigratedObject?: CaseSubentityMigratedObjectResolvers<ContextType>;
  CaseSubentityMigrationResponse?: CaseSubentityMigrationResponseResolvers<ContextType>;
  CaseTask?: CaseTaskResolvers<ContextType>;
  CaseTaskStatus?: CaseTaskStatusResolvers<ContextType>;
  CaseTasksInfo?: CaseTasksInfoResolvers<ContextType>;
  CaseUpdate?: CaseUpdateResolvers<ContextType>;
  CaseUpdateSharingOptions?: CaseUpdateSharingOptionsResolvers<ContextType>;
  CasesResults?: CasesResultsResolvers<ContextType>;
  ChangeLog?: ChangeLogResolvers<ContextType>;
  CheckCalendarsAvailabilityResponse?: CheckCalendarsAvailabilityResponseResolvers<ContextType>;
  Clawback?: ClawbackResolvers<ContextType>;
  ClosedCase?: ClosedCaseResolvers<ContextType>;
  CodeVerificationResponse?: CodeVerificationResponseResolvers<ContextType>;
  CourtInfo?: CourtInfoResolvers<ContextType>;
  CoveredCarePrequalData?: CoveredCarePrequalDataResolvers<ContextType>;
  CoveredCarePrequalLink?: CoveredCarePrequalLinkResolvers<ContextType>;
  CoveredCarePrequalOffer?: CoveredCarePrequalOfferResolvers<ContextType>;
  CoveredCarePrequalResult?: CoveredCarePrequalResultResolvers<ContextType>;
  CreateAttorneyCodeVerificationResponse?: CreateAttorneyCodeVerificationResponseResolvers<ContextType>;
  CreateCodeVerificationResponse?: CreateCodeVerificationResponseResolvers<ContextType>;
  CreatePayoutItemBulkResponse?: CreatePayoutItemBulkResponseResolvers<ContextType>;
  CreatePayoutItemError?: CreatePayoutItemErrorResolvers<ContextType>;
  CreatePayoutItemResponse?: CreatePayoutItemResponseResolvers<ContextType>;
  CreateShortLivedTokenOutput?: CreateShortLivedTokenOutputResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerFinancingSummary?: CustomerFinancingSummaryResolvers<ContextType>;
  CustomerState?: CustomerStateResolvers<ContextType>;
  CustomerStripeData?: CustomerStripeDataResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DiyFunnelData?: DiyFunnelDataResolvers<ContextType>;
  DiyFunnelLocation?: DiyFunnelLocationResolvers<ContextType>;
  DocumentClassification?: DocumentClassificationResolvers<ContextType>;
  DocumentClassificationCategory?: DocumentClassificationCategoryResolvers<ContextType>;
  DocumentClassificationsWithCategory?: DocumentClassificationsWithCategoryResolvers<ContextType>;
  DownPayment?: DownPaymentResolvers<ContextType>;
  DownPaymentsPaidAmount?: DownPaymentsPaidAmountResolvers<ContextType>;
  DriveDocument?: DriveDocumentResolvers<ContextType>;
  Earning?: EarningResolvers<ContextType>;
  EmailAddress?: GraphQLScalarType;
  EveryTwoWeeksOutput?: EveryTwoWeeksOutputResolvers<ContextType>;
  Fee?: FeeResolvers<ContextType>;
  FilterVal?: FilterValResolvers<ContextType>;
  FinancingOption?: FinancingOptionResolvers<ContextType>;
  FinancingTerms?: FinancingTermsResolvers<ContextType>;
  Form?: FormResolvers<ContextType>;
  FormData?: FormDataResolvers<ContextType>;
  FormField?: FormFieldResolvers<ContextType>;
  FormTemplate?: FormTemplateResolvers<ContextType>;
  FormTemplateField?: FormTemplateFieldResolvers<ContextType>;
  GeneratePaymentAgreementResponse?: GeneratePaymentAgreementResponseResolvers<ContextType>;
  GenesisLoanAccount?: GenesisLoanAccountResolvers<ContextType>;
  GenesisLoanApplication?: GenesisLoanApplicationResolvers<ContextType>;
  GenesisLoanTerms?: GenesisLoanTermsResolvers<ContextType>;
  GenesisPrequalApplication?: GenesisPrequalApplicationResolvers<ContextType>;
  GetCustomerRiskLevelResult?: GetCustomerRiskLevelResultResolvers<ContextType>;
  GetLegalServicesResult?: GetLegalServicesResultResolvers<ContextType>;
  GetOrCreatePaymentPlanAccountResponse?: GetOrCreatePaymentPlanAccountResponseResolvers<ContextType>;
  GetPaginatedDiyUsersResponse?: GetPaginatedDiyUsersResponseResolvers<ContextType>;
  GetRefundTicketByIdResponse?: GetRefundTicketByIdResponseResolvers<ContextType>;
  HashResponse?: HashResponseResolvers<ContextType>;
  ICaseDocument?: ICaseDocumentResolvers<ContextType>;
  IFormTemplateField?: IFormTemplateFieldResolvers<ContextType>;
  IdentityVerificationOutput?: IdentityVerificationOutputResolvers<ContextType>;
  InHousePrequalApplication?: InHousePrequalApplicationResolvers<ContextType>;
  InHousePrequalApplicationMetadata?: InHousePrequalApplicationMetadataResolvers<ContextType>;
  InHousePrequalApplicationOffer?: InHousePrequalApplicationOfferResolvers<ContextType>;
  Installment?: InstallmentResolvers<ContextType>;
  IntakeMeeting?: IntakeMeetingResolvers<ContextType>;
  IntakeMeetingSlots?: IntakeMeetingSlotsResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Lead?: LeadResolvers<ContextType>;
  LegalProduct?: LegalProductResolvers<ContextType>;
  LegalService?: LegalServiceResolvers<ContextType>;
  LegalServiceProvider?: LegalServiceProviderResolvers<ContextType>;
  LegalServiceSection?: LegalServiceSectionResolvers<ContextType>;
  LenderOffer?: LenderOfferResolvers<ContextType>;
  LinkTokenCreateResponse?: LinkTokenCreateResponseResolvers<ContextType>;
  LoanProvider?: LoanProviderResolvers<ContextType>;
  LoginByTokenResponse?: LoginByTokenResponseResolvers<ContextType>;
  LssCaseInfo?: LssCaseInfoResolvers<ContextType>;
  LssClientDetails?: LssClientDetailsResolvers<ContextType>;
  LssCriticalDate?: LssCriticalDateResolvers<ContextType>;
  LssRejected?: LssRejectedResolvers<ContextType>;
  LssRescheduled?: LssRescheduledResolvers<ContextType>;
  LssSummary?: LssSummaryResolvers<ContextType>;
  MakeLoanPaymentResponse?: MakeLoanPaymentResponseResolvers<ContextType>;
  MarkAllNotificationsAsReadResult?: MarkAllNotificationsAsReadResultResolvers<ContextType>;
  MigrateCaseDocumentsToVinnyResponse?: MigrateCaseDocumentsToVinnyResponseResolvers<ContextType>;
  MigrateToVinnyResponse?: MigrateToVinnyResponseResolvers<ContextType>;
  MissedPayment?: MissedPaymentResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Name?: NameResolvers<ContextType>;
  NextPayment?: NextPaymentResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationData?: NotificationDataResolvers<ContextType>;
  Onboarding?: OnboardingResolvers<ContextType>;
  OnboardingProgress?: OnboardingProgressResolvers<ContextType>;
  OnboardingStepProgress?: OnboardingStepProgressResolvers<ContextType>;
  OneTimePlan?: OneTimePlanResolvers<ContextType>;
  OpportunityLinkFields?: OpportunityLinkFieldsResolvers<ContextType>;
  OpportunityPaymentData?: OpportunityPaymentDataResolvers<ContextType>;
  OpportunityStage?: OpportunityStageResolvers<ContextType>;
  OpposingParty?: OpposingPartyResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PaidLssAmount?: PaidLssAmountResolvers<ContextType>;
  PartialAttorney?: PartialAttorneyResolvers<ContextType>;
  PartialName?: PartialNameResolvers<ContextType>;
  PartialPaymentAccount?: PartialPaymentAccountResolvers<ContextType>;
  PastPayment?: PastPaymentResolvers<ContextType>;
  PastTransaction?: PastTransactionResolvers<ContextType>;
  PayTotalOutstandingResponse?: PayTotalOutstandingResponseResolvers<ContextType>;
  PayerFinancingOptions?: PayerFinancingOptionsResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentAccount?: PaymentAccountResolvers<ContextType>;
  PaymentAgreementResponse?: PaymentAgreementResponseResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PaymentOnHold?: PaymentOnHoldResolvers<ContextType>;
  PaymentPlan?: PaymentPlanResolvers<ContextType>;
  PaymentPlanComponent?: PaymentPlanComponentResolvers<ContextType>;
  PaymentPlanConfig?: PaymentPlanConfigResolvers<ContextType>;
  PaymentPlanData?: PaymentPlanDataResolvers<ContextType>;
  PaymentPlanSetupAccountData?: PaymentPlanSetupAccountDataResolvers<ContextType>;
  PaymentPlanSetupOpportunity?: PaymentPlanSetupOpportunityResolvers<ContextType>;
  PaymentPlanSetupPaymentPlan?: PaymentPlanSetupPaymentPlanResolvers<ContextType>;
  PaymentPlanSetupPaymentPlanComponent?: PaymentPlanSetupPaymentPlanComponentResolvers<ContextType>;
  PaymentPlansPaymentMethods?: PaymentPlansPaymentMethodsResolvers<ContextType>;
  PayoutItem?: PayoutItemResolvers<ContextType>;
  PaypalClientTokenResponse?: PaypalClientTokenResponseResolvers<ContextType>;
  PendingOnboardingAttorney?: PendingOnboardingAttorneyResolvers<ContextType>;
  PhoneNumber?: GraphQLScalarType;
  Phones?: PhonesResolvers<ContextType>;
  PrequalApplicationOutput?: PrequalApplicationOutputResolvers<ContextType>;
  PrequalResponse?: PrequalResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RecommendedServiceDetails?: RecommendedServiceDetailsResolvers<ContextType>;
  RecurringPlan?: RecurringPlanResolvers<ContextType>;
  RefundLoanOutput?: RefundLoanOutputResolvers<ContextType>;
  RefundTransactionResult?: RefundTransactionResultResolvers<ContextType>;
  RegisterStripeCustomerResponse?: RegisterStripeCustomerResponseResolvers<ContextType>;
  RejectedCustomer?: RejectedCustomerResolvers<ContextType>;
  RenderContractResponse?: RenderContractResponseResolvers<ContextType>;
  RequiredDocument?: RequiredDocumentResolvers<ContextType>;
  SFEvent?: SfEventResolvers<ContextType>;
  SFIntakeData?: SfIntakeDataResolvers<ContextType>;
  SFIntakeDataCriticalDate?: SfIntakeDataCriticalDateResolvers<ContextType>;
  SFLegalService?: SfLegalServiceResolvers<ContextType>;
  SFService?: SfServiceResolvers<ContextType>;
  ScheduledMeeting?: ScheduledMeetingResolvers<ContextType>;
  SendPayoutMailResult?: SendPayoutMailResultResolvers<ContextType>;
  SendPayoutMailsResponse?: SendPayoutMailsResponseResolvers<ContextType>;
  ServiceOnOpportunity?: ServiceOnOpportunityResolvers<ContextType>;
  ServicePaymentData?: ServicePaymentDataResolvers<ContextType>;
  ServicesPaymentDataForOpportunity?: ServicesPaymentDataForOpportunityResolvers<ContextType>;
  SigningRequestDetails?: SigningRequestDetailsResolvers<ContextType>;
  Stage?: StageResolvers<ContextType>;
  StartLoanApplicationOutput?: StartLoanApplicationOutputResolvers<ContextType>;
  StripeData?: StripeDataResolvers<ContextType>;
  SubmitRequestForDpPolicyChangeResult?: SubmitRequestForDpPolicyChangeResultResolvers<ContextType>;
  SuggestedThirdPartyProviderResponse?: SuggestedThirdPartyProviderResponseResolvers<ContextType>;
  SyncDeviceResponse?: SyncDeviceResponseResolvers<ContextType>;
  ThirdPartyFinancedAmount?: ThirdPartyFinancedAmountResolvers<ContextType>;
  Ticket?: TicketResolvers<ContextType>;
  TicketComment?: TicketCommentResolvers<ContextType>;
  Time?: GraphQLScalarType;
  TransactionPaymentMethod?: TransactionPaymentMethodResolvers<ContextType>;
  TwiceMonthlyOutput?: TwiceMonthlyOutputResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UWAddress?: UwAddressResolvers<ContextType>;
  UWUser?: UwUserResolvers<ContextType>;
  UpdatedCaseDocumentStatus?: UpdatedCaseDocumentStatusResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UpsertCaseTaskResult?: UpsertCaseTaskResultResolvers<ContextType>;
  UserFinancingOptionOrNull?: UserFinancingOptionOrNullResolvers<ContextType>;
  UserRoleName?: UserRoleNameResolvers<ContextType>;
  UsersWithCalendarIssues?: UsersWithCalendarIssuesResolvers<ContextType>;
  VoucherDiscount?: VoucherDiscountResolvers<ContextType>;
  WhatIsNext?: WhatIsNextResolvers<ContextType>;
  WireTransferMethod?: WireTransferMethodResolvers<ContextType>;
  WisetackLoanAmountStatus?: WisetackLoanAmountStatusResolvers<ContextType>;
  WisetackLoanLink?: WisetackLoanLinkResolvers<ContextType>;
  WisetackPrequalLink?: WisetackPrequalLinkResolvers<ContextType>;
};


export type CreateWebLeadMutationVariables = Exact<{
  input: CreateWebLeadInput;
}>;


export type CreateWebLeadMutation = { __typename?: 'Mutation', createWebLead: { __typename?: 'Lead', id: string } };

export type UpdateWebLeadMutationVariables = Exact<{
  input: UpdateWebLeadInput;
}>;


export type UpdateWebLeadMutation = { __typename?: 'Mutation', updateWebLead: { __typename?: 'Lead', id: string } };

export type CreateRejectedCustomerMutationVariables = Exact<{
  rejectedCustomer: CreateRejectedCustomerInput;
}>;


export type CreateRejectedCustomerMutation = { __typename?: 'Mutation', createRejectedCustomer?: { __typename?: 'RejectedCustomer', id: string, email: string } | null | undefined };

export type GetIntakeMeetingSlotsQueryVariables = Exact<{
  input: IntakeMeetingSlotsInput;
}>;


export type GetIntakeMeetingSlotsQuery = { __typename?: 'Query', intakeMeetingSlots: { __typename?: 'IntakeMeetingSlots', slotTimeSpan: number, slots: Array<string> } };

export type GetLeadIdByFunnelActionIdQueryVariables = Exact<{
  funnelActionId: Scalars['String']['input'];
}>;


export type GetLeadIdByFunnelActionIdQuery = { __typename?: 'Query', leadIdByFunnelActionId?: { __typename?: 'Lead', id: string } | null | undefined };

export type RegisterStripeCustomerMutationVariables = Exact<{
  input: RegisterStripeCustomerInput;
}>;


export type RegisterStripeCustomerMutation = { __typename?: 'Mutation', registerStripeCustomer: { __typename?: 'RegisterStripeCustomerResponse', clientSecret: string } };

export type AddPaymentMutationVariables = Exact<{
  input: AddPaymentInput;
}>;


export type AddPaymentMutation = { __typename?: 'Mutation', addPayment: boolean };


export const CreateWebLeadDocument = gql`
    mutation CreateWebLead($input: CreateWebLeadInput!) {
  createWebLead(input: $input) {
    id
  }
}
    `;
export type CreateWebLeadMutationFn = Apollo.MutationFunction<CreateWebLeadMutation, CreateWebLeadMutationVariables>;

/**
 * __useCreateWebLeadMutation__
 *
 * To run a mutation, you first call `useCreateWebLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebLeadMutation, { data, loading, error }] = useCreateWebLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebLeadMutation, CreateWebLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebLeadMutation, CreateWebLeadMutationVariables>(CreateWebLeadDocument, options);
      }
export type CreateWebLeadMutationHookResult = ReturnType<typeof useCreateWebLeadMutation>;
export type CreateWebLeadMutationResult = Apollo.MutationResult<CreateWebLeadMutation>;
export type CreateWebLeadMutationOptions = Apollo.BaseMutationOptions<CreateWebLeadMutation, CreateWebLeadMutationVariables>;
export const UpdateWebLeadDocument = gql`
    mutation UpdateWebLead($input: UpdateWebLeadInput!) {
  updateWebLead(input: $input) {
    id
  }
}
    `;
export type UpdateWebLeadMutationFn = Apollo.MutationFunction<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>;

/**
 * __useUpdateWebLeadMutation__
 *
 * To run a mutation, you first call `useUpdateWebLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebLeadMutation, { data, loading, error }] = useUpdateWebLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebLeadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>(UpdateWebLeadDocument, options);
      }
export type UpdateWebLeadMutationHookResult = ReturnType<typeof useUpdateWebLeadMutation>;
export type UpdateWebLeadMutationResult = Apollo.MutationResult<UpdateWebLeadMutation>;
export type UpdateWebLeadMutationOptions = Apollo.BaseMutationOptions<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>;
export const CreateRejectedCustomerDocument = gql`
    mutation CreateRejectedCustomer($rejectedCustomer: CreateRejectedCustomerInput!) {
  createRejectedCustomer(rejectedCustomer: $rejectedCustomer) {
    id
    email
  }
}
    `;
export type CreateRejectedCustomerMutationFn = Apollo.MutationFunction<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>;

/**
 * __useCreateRejectedCustomerMutation__
 *
 * To run a mutation, you first call `useCreateRejectedCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRejectedCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRejectedCustomerMutation, { data, loading, error }] = useCreateRejectedCustomerMutation({
 *   variables: {
 *      rejectedCustomer: // value for 'rejectedCustomer'
 *   },
 * });
 */
export function useCreateRejectedCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>(CreateRejectedCustomerDocument, options);
      }
export type CreateRejectedCustomerMutationHookResult = ReturnType<typeof useCreateRejectedCustomerMutation>;
export type CreateRejectedCustomerMutationResult = Apollo.MutationResult<CreateRejectedCustomerMutation>;
export type CreateRejectedCustomerMutationOptions = Apollo.BaseMutationOptions<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>;
export const GetIntakeMeetingSlotsDocument = gql`
    query GetIntakeMeetingSlots($input: IntakeMeetingSlotsInput!) {
  intakeMeetingSlots: getIntakeMeetingSlots(input: $input) {
    slotTimeSpan
    slots
  }
}
    `;

/**
 * __useGetIntakeMeetingSlotsQuery__
 *
 * To run a query within a React component, call `useGetIntakeMeetingSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntakeMeetingSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntakeMeetingSlotsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntakeMeetingSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables> & ({ variables: GetIntakeMeetingSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
      }
export function useGetIntakeMeetingSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
        }
export function useGetIntakeMeetingSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
        }
export type GetIntakeMeetingSlotsQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsQuery>;
export type GetIntakeMeetingSlotsLazyQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsLazyQuery>;
export type GetIntakeMeetingSlotsSuspenseQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsSuspenseQuery>;
export type GetIntakeMeetingSlotsQueryResult = Apollo.QueryResult<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>;
export function refetchGetIntakeMeetingSlotsQuery(variables: GetIntakeMeetingSlotsQueryVariables) {
      return { query: GetIntakeMeetingSlotsDocument, variables: variables }
    }
export const GetLeadIdByFunnelActionIdDocument = gql`
    query GetLeadIdByFunnelActionId($funnelActionId: String!) {
  leadIdByFunnelActionId: getLeadIdByFunnelActionId(
    funnelActionId: $funnelActionId
  ) {
    id
  }
}
    `;

/**
 * __useGetLeadIdByFunnelActionIdQuery__
 *
 * To run a query within a React component, call `useGetLeadIdByFunnelActionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadIdByFunnelActionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadIdByFunnelActionIdQuery({
 *   variables: {
 *      funnelActionId: // value for 'funnelActionId'
 *   },
 * });
 */
export function useGetLeadIdByFunnelActionIdQuery(baseOptions: Apollo.QueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables> & ({ variables: GetLeadIdByFunnelActionIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
      }
export function useGetLeadIdByFunnelActionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
        }
export function useGetLeadIdByFunnelActionIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
        }
export type GetLeadIdByFunnelActionIdQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdQuery>;
export type GetLeadIdByFunnelActionIdLazyQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdLazyQuery>;
export type GetLeadIdByFunnelActionIdSuspenseQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdSuspenseQuery>;
export type GetLeadIdByFunnelActionIdQueryResult = Apollo.QueryResult<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>;
export function refetchGetLeadIdByFunnelActionIdQuery(variables: GetLeadIdByFunnelActionIdQueryVariables) {
      return { query: GetLeadIdByFunnelActionIdDocument, variables: variables }
    }
export const RegisterStripeCustomerDocument = gql`
    mutation RegisterStripeCustomer($input: RegisterStripeCustomerInput!) {
  registerStripeCustomer(input: $input) {
    clientSecret
  }
}
    `;
export type RegisterStripeCustomerMutationFn = Apollo.MutationFunction<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>;

/**
 * __useRegisterStripeCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStripeCustomerMutation, { data, loading, error }] = useRegisterStripeCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterStripeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>(RegisterStripeCustomerDocument, options);
      }
export type RegisterStripeCustomerMutationHookResult = ReturnType<typeof useRegisterStripeCustomerMutation>;
export type RegisterStripeCustomerMutationResult = Apollo.MutationResult<RegisterStripeCustomerMutation>;
export type RegisterStripeCustomerMutationOptions = Apollo.BaseMutationOptions<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>;
export const AddPaymentDocument = gql`
    mutation AddPayment($input: AddPaymentInput!) {
  addPayment(input: $input)
}
    `;
export type AddPaymentMutationFn = Apollo.MutationFunction<AddPaymentMutation, AddPaymentMutationVariables>;

/**
 * __useAddPaymentMutation__
 *
 * To run a mutation, you first call `useAddPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMutation, { data, loading, error }] = useAddPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMutation, AddPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentMutation, AddPaymentMutationVariables>(AddPaymentDocument, options);
      }
export type AddPaymentMutationHookResult = ReturnType<typeof useAddPaymentMutation>;
export type AddPaymentMutationResult = Apollo.MutationResult<AddPaymentMutation>;
export type AddPaymentMutationOptions = Apollo.BaseMutationOptions<AddPaymentMutation, AddPaymentMutationVariables>;
import { competenciesMap } from "@/consts/competencies";

export function getCompetencies({
  practice,
  subPractice,
}: {
  practice?: string;
  subPractice?: string;
}) {
  return (
    (practice &&
      subPractice &&
      competenciesMap[practice.toLowerCase()]?.[subPractice]) ||
    []
  );
}

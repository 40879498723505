import { cn } from "@flare/ui";

type StickyActionsProps = {
  children: React.ReactNode;
  classNames?: string;
};

export function StickyActions({ children, classNames }: StickyActionsProps) {
  return (
    <div
      className={cn(
        "fixed inset-x-0 bottom-0 z-10 mx-0 mb-0 border-t border-blue-20 bg-white-80 p-4 md:static",
        classNames,
      )}
    >
      {children}
    </div>
  );
}

import React, { useMemo } from "react";
import { Builder } from "@builder.io/react";

import { V1_TEXT_AREA } from "@/builder/components-sections";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";

import { TextAreaController } from "./TextareaController";
import { createValidationFunc } from "./yup.utils";

type TextareaProps = {
  label: string;
  name: string;
  placeholder: string;
  validation: {
    required?: boolean;
  };
};

// Required.
// To prevent react-hook-form's "register" function from being called without a name.
const FALLBACK_INPUT_NAME = " ";

export const Textarea = ({
  name,
  label = "",
  placeholder = "",
  validation,
}: TextareaProps) => {
  const required = validation?.required;
  const validate = useMemo(
    () => createValidationFunc({ required }),
    [required],
  );

  return (
    <TextAreaController
      name={name || FALLBACK_INPUT_NAME}
      label={label}
      placeholder={placeholder}
      registerProps={{ validate }}
      block
    />
  );
};

Builder.registerComponent(Textarea, {
  name: V1_TEXT_AREA,
  inputs: [
    {
      name: "name",
      type: "string",
      required: true,
      helperText: '"name" must have a unique value within a page',
      enum: Object.values(funnelInputsNameMap),
    },
    {
      name: "label",
      type: "string",
      defaultValue: "Label",
    },
    {
      name: "placeholder",
      type: "string",
      defaultValue: "Placeholder",
    },
    {
      name: "validation",
      type: "object",
      subFields: [
        {
          name: "required",
          type: "boolean",
          helperText: "Check this box if the user must fill out this field.",
        },
      ],
    },
  ],
});

import { Builder } from "@builder.io/sdk";
import { BookitProvider } from "@marbletech/bookit-core";
import { useSessionStorage } from "usehooks-ts";

import { FUNNEL_LSS_SCHEDULER } from "@/builder/components-sections";

import { LssSchedulerWithoutRequest } from "./LssSchedulerWithoutRequest";
import { LssSchedulerWithRequest } from "./LssSchedulerWithRequest";

const bookitBaseUrl = process.env.NEXT_PUBLIC_BOOKIT_BASE_URL || "";

export function LssScheduler() {
  const [bookitRequestId] = useSessionStorage("bookitRequestId", null);
  const isExistingBookitRequest = !!bookitRequestId;

  /**
   * This conditional rendering is here because bookit-core's useSlots hook
   * requires a BookitProvider wrapping it, and BookitProvider requires a meetingId,
   * which is only available if there is an existing bookit request.
   *
   * Ideally, we would have a single component instead of two.
   */
  return isExistingBookitRequest ? (
    <BookitProvider baseUrl={bookitBaseUrl} meetingId={bookitRequestId}>
      <LssSchedulerWithRequest />
    </BookitProvider>
  ) : (
    <LssSchedulerWithoutRequest />
  );
}

Builder.registerComponent(LssScheduler, {
  name: FUNNEL_LSS_SCHEDULER,
  inputs: [],
});

export const competenciesMap: Record<string, Record<string, string[]>> = {
  family: {
    divorce: ["Divorce"],
    "child-support": ["Support"],
    "child-custody": ["Custody"],
  },
  immigration: {
    "fiance-visa": ["K-1 Visa"],
    "green-card": [
      "Family: Removal of Conditions",
      "Family: Complete Green Card Process",
      "General: LPR Card Renewal",
    ],
  },
};

/**
 * @file register.ts
 *
 * @desc This file is used to register our custom components to the Builder.io
 * editor by importing components that using the Builder.register() method.
 *
 * This file is been used in _app.tsx to register the components to the Builder.io editor.
 */
import { Builder } from "@builder.io/sdk";

/**
 * Import the components that you want to register to the Builder.io editor.
 *
 * General components
 */
import "@/components/Builder/Typography/Heading/Heading";
import "@/components/Builder/Typography/Text/Text";
/**
 * Funnel components
 */
import "@/components/Funnel/BackButton";
import "@/components/Funnel/NextButton";
import "@/components/Funnel/NextButtonWithDisabled";
import "@/components/Funnel/SkipButton";
import "@/components/Funnel/TextField";
import "@/components/Funnel/ChoiceBox";
import "@/components/Funnel/IntakeScheduler/IntakeScheduler";
import "@/components/Funnel/LssScheduler/LssScheduler";
import "@/components/Funnel/Checkbox";
import "@/components/Funnel/LssCheckout/LssCheckout";
import "@/components/Funnel/LpFunnelStepForm";
import "@/components/Funnel/ZipCode/ZipCode";
import "@/components/Funnel/v1/Textarea/TextArea";
import "@/components/Funnel/DatePicker";
import "@/components/Funnel/ChildrenInfo";
import "@/components/Funnel/LimitedScopeVideoBanner/LimitedScopeDrawerTrigger";
import "@/components/Funnel/LimitedScopeVideoBanner/LimitedScopeDialogTrigger";

import { builderComponentsSections } from "./components-sections";

/**
 * Organize the components into sections and register them to the Builder.io editor.
 */
builderComponentsSections.forEach(({ name, items }) => {
  Builder.register("insertMenu", {
    name,
    items,
  });
});

import { jsx as e } from "react/jsx-runtime";
import { S as i, c as m } from "../../index-DE_nAeOg.js";
import { cn as o } from "../../utils/cn.es.js";
const d = m("font-sans antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end"
    },
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      "primary-inverted": "text-text-primary-inverted",
      danger: "text-text-danger",
      success: "text-text-success",
      status: "text-text-status"
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6"
    },
    textWrap: {
      balance: "text-balance",
      pretty: "text-pretty"
    },
    variant: {
      t1: "py-[2px] text-t1",
      "t1-style-1": "py-[2px] text-t1-style-1",
      "t1-style-2": "text-t1-style-2",
      t2: "pb-[3px] pt-[1px] text-t2",
      "t2-style-1": "pb-[3px] pt-[1px] text-t2-style-1",
      "t2-style-2": "pb-[3px] pt-[1px] text-t2-style-2",
      "t2-style-3": "text-t2-style-3",
      t3: "text-t3",
      "t3-style-1": "text-t3-style-1",
      "t3-style-2": "text-t3-style-2"
    }
  }
}), f = ({
  alignment: a = "start",
  as: s = "p",
  asChild: x,
  children: t,
  className: r,
  color: p = "primary",
  maxLines: l,
  textWrap: n,
  variant: y = "t1",
  ...c
}) => /* @__PURE__ */ e(
  i,
  {
    className: o(
      d({
        alignment: a,
        color: p,
        maxLines: l,
        textWrap: n,
        variant: y,
        className: r
      })
    ),
    ...c,
    children: x ? t : /* @__PURE__ */ e(s, { children: t })
  }
);
export {
  f as Text
};

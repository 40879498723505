import { useState } from "react";

/**
 * Custom hook that tracks the horizontal scroll position of a container element.
 *
 * @param ref - Reference to the container element.
 * @param scrollThreshold - The threshold value for determining the scroll position. Default is 10.
 * @returns An object containing the current container scroll position and a scroll event handler.
 */
export function useHorizontalScrollPosition(
  ref: React.RefObject<HTMLDivElement>,
  { scrollThreshold = 10 },
) {
  const [containerScrollPosition, setContainerScrollPosition] = useState<
    "start" | "middle" | "end"
  >("start");

  function handleScroll() {
    const container = ref.current;

    if ((container?.scrollLeft || 0) < scrollThreshold) {
      setContainerScrollPosition("start");
    } else if (
      (container?.scrollLeft || 0) +
        (container?.clientWidth || 0) +
        scrollThreshold >
      (container?.scrollWidth || 0)
    ) {
      setContainerScrollPosition("end");
    } else {
      setContainerScrollPosition("middle");
    }
  }

  return { containerScrollPosition, handleScroll };
}

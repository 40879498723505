import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

import type { Intent } from "./calculateLeadScore";
import {
  checkIntakeOfficeAvailability,
  CLOSE_HOUR,
  OPEN_HOUR,
} from "./checkIntakeOfficeAvailability";

type checkCmnEligibilityArgs = {
  leadIntent: Intent;
  leadScore: number;
};

export function checkCmnEligibility({
  leadIntent,
  leadScore,
}: checkCmnEligibilityArgs) {
  const isInBusinessHours = checkIntakeOfficeAvailability();
  const isEligible = leadIntent === "high" && isInBusinessHours;

  analytics.track(BiEventsNames.WebCmnEligibility, {
    is_eligible: isEligible,
    lead_intent: leadIntent,
    in_business_hours: isInBusinessHours,
    lead_score: leadScore,
    business_hour_open: OPEN_HOUR,
    business_hour_close: CLOSE_HOUR,
  });
  return isEligible;
}

export function ExclamationMarkCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" color="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.75 14.25C9.75 14.6642 9.41609 15 9.0042 15H8.9958C8.58391 15 8.25 14.6642 8.25 14.25C8.25 13.8358 8.58391 13.5 8.9958 13.5H9.0042C9.41609 13.5 9.75 13.8358 9.75 14.25ZM8.25 11.25C8.25 11.6642 8.58579 12 9 12C9.41421 12 9.75 11.6642 9.75 11.25L9.75 4.5C9.75 4.08579 9.41421 3.75 9 3.75C8.58579 3.75 8.25 4.08579 8.25 4.5L8.25 11.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

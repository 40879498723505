export function TrashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75006 5.25C9.75006 4.83579 10.0859 4.5 10.5001 4.5H13.5001C13.9143 4.5 14.2501 4.83579 14.2501 5.25C14.2501 5.66421 14.5859 6 15.0001 6C15.4143 6 15.7501 5.66421 15.7501 5.25C15.7501 4.00736 14.7427 3 13.5001 3H10.5001C9.25742 3 8.25006 4.00736 8.25006 5.25C8.25006 5.66421 8.58585 6 9.00006 6C9.41427 6 9.75006 5.66421 9.75006 5.25ZM4.49998 6.75H19.4997C19.9139 6.75002 20.2501 7.08583 20.25 7.50004C20.25 7.91426 19.9142 8.25002 19.5 8.25H4.50007C4.08585 8.25002 3.75002 7.91426 3.75 7.50004C3.74998 7.08583 4.08577 6.75002 4.49998 6.75ZM6.74806 9.69647C6.71855 9.28331 6.35969 8.9723 5.94653 9.00182C5.53337 9.03133 5.22236 9.39019 5.25188 9.80335L5.85267 18.2137C5.96481 19.7836 7.27113 20.9999 8.84504 20.9999H15.155C16.7289 20.9999 18.0352 19.7836 18.1474 18.2137L18.7481 9.80335C18.7776 9.39018 18.4666 9.03133 18.0534 9.00182C17.6403 8.9723 17.2814 9.28331 17.2519 9.69648L16.6512 18.1068C16.5951 18.8917 15.942 19.4999 15.155 19.4999H8.84504C8.05809 19.4999 7.40493 18.8917 7.34885 18.1068L6.74806 9.69647ZM8.25006 12.75C8.25006 12.3358 8.58585 12 9.00006 12H15.0001C15.4143 12 15.7501 12.3358 15.7501 12.75C15.7501 13.1642 15.4143 13.5 15.0001 13.5H9.00006C8.58585 13.5 8.25006 13.1642 8.25006 12.75ZM9.75006 15.75C9.75006 15.3358 10.0859 15 10.5001 15H13.5001C13.9143 15 14.2501 15.3358 14.2501 15.75C14.2501 16.1642 13.9143 16.5 13.5001 16.5H10.5001C10.0859 16.5 9.75006 16.1642 9.75006 15.75Z"
      />
    </svg>
  );
}

import { useRef } from "react";
import { ChevronLeft, ChevronRight } from "@marbletech/core";

import { cn } from "@flare/ui";

import { useHorizontalScrollPosition } from "@/hooks/useHorizontalScrollPosition";

type SliderProps = {
  children: React.ReactNode;
  loading?: boolean;
  scrollBy: number;
  scrollThreshold?: number;
};

export function Slider({
  children,
  loading = false,
  scrollBy,
  scrollThreshold = 10,
}: SliderProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { containerScrollPosition, handleScroll } = useHorizontalScrollPosition(
    containerRef,
    { scrollThreshold },
  );

  const showLeftArrow = !loading && containerScrollPosition !== "start";
  const showRightArrow = !loading && containerScrollPosition !== "end";

  function handleLeftArrowClick() {
    containerRef.current?.scrollBy({
      left: -scrollBy,
      behavior: "smooth",
    });
  }
  function handleRightArrowClick() {
    containerRef.current?.scrollBy({
      left: scrollBy,
      behavior: "smooth",
    });
  }

  return (
    <div className="relative flex flex-row overflow-x-hidden">
      <button
        type="button"
        aria-label="scroll left"
        onClick={handleLeftArrowClick}
        className={cn(
          "invisible absolute left-0 h-[72px] bg-white-80 opacity-0 transition-opacity duration-200 ease-in-out",
          {
            "md:visible opacity-100": showLeftArrow,
          },
        )}
      >
        <ChevronLeft color="b80" />
      </button>
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="flex flex-row gap-2 overflow-x-auto"
      >
        {children}
      </div>
      <button
        type="button"
        aria-label="scroll right"
        onClick={handleRightArrowClick}
        className={cn(
          "invisible absolute right-0 h-[72px] bg-white-80 opacity-0 transition-opacity duration-200 ease-in-out",
          {
            "md:visible opacity-100": showRightArrow,
          },
        )}
      >
        <ChevronRight color="b80" />
      </button>
    </div>
  );
}

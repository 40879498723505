import * as Sentry from "@sentry/nextjs";

import type {
  Address,
  AddressComponent,
  SupportedPractices,
  SupportedStates,
} from "./ZipCode.types";

const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;

const request = async ({ zipCode = "", lat = "", lng = "" }) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&`;
  let searchParams;

  if (zipCode) {
    searchParams = `language=en&address=${zipCode}, USA`;
  }
  if (lat && lng) {
    searchParams = `latlng=${lat},${lng}&result_type=administrative_area_level_2`;
  }
  const response = await fetch(`${url}${searchParams}`);
  const data = await response?.json();
  return data.results?.[0];
};

const getCounty = (addressComponents: AddressComponent[]) => {
  return (
    addressComponents.find(
      (component: AddressComponent) =>
        component.types[0] === "administrative_area_level_2",
    )?.long_name || ""
  );
};
const getState = (addressComponents: AddressComponent[]) => {
  return (
    addressComponents.find(
      (component: AddressComponent) =>
        component.types[0] === "administrative_area_level_1",
    )?.long_name || ""
  );
};

const getStateCode = (addressComponents: AddressComponent[]) => {
  return (
    addressComponents.find(
      (component: AddressComponent) =>
        component.types[0] === "administrative_area_level_1",
    )?.short_name || ""
  );
};

export async function getAddress(
  zipCode: string,
): Promise<Record<string, string>> {
  let county = "";
  let state = "";
  let stateCode = "";
  try {
    const result = await request({ zipCode });
    if (result) {
      const addressComponents = result?.address_components;

      if (Array.isArray(addressComponents)) {
        state = getState(addressComponents);
        county = getCounty(addressComponents);
        stateCode = getStateCode(addressComponents);
      }

      if (!county && state) {
        const { lat, lng } = result.geometry?.location ?? {};
        const fallbackAddressComponent = await request({ lat, lng });

        const addressComponents = fallbackAddressComponent?.address_components;

        if (Array.isArray(addressComponents)) {
          county = getCounty(addressComponents);
        }
      }
    }

    return { county, state, stateCode };
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        zipCode,
        message: `error fetching address for zipCode: ${zipCode}`,
      },
    });
    return { county, state };
  }
}

export const formatAddress = (address: Address | null) => {
  let formattedAddress = address?.state || "";
  if (address?.county) {
    formattedAddress = `${address.county}, ${address.state}`;
  }
  return formattedAddress;
};

export const normalizeStateName = (state: string) => {
  return state.replace(/\s+/g, "-").toLowerCase();
};

import type { USState } from "@/consts/us-states";

export const supportedPractices = {
  FAMILY: "FAMILY",
  IMMIGRATION: "IMMIGRATION",
} as const;

export const familySupportedStates: USState[] = [
  "arizona",
  "california",
  "colorado",
  "florida",
  "georgia",
  "illinois",
  "maryland",
  "michigan",
  "new-york",
  "texas",
];

export const supportedStates: SupportedStates = {
  FAMILY: familySupportedStates,
  IMMIGRATION: [],
};

export function isStateSupported(state: string, practice: SupportedPractices) {
  // @ts-expect-error: implicit any
  const matchedStates = supportedStates[practice.toUpperCase()].filter(
    (supportedState: string) => supportedState === state,
  );
  return matchedStates.length > 0;
}

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Builder, withChildren } from "@builder.io/react";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import { LP_FUNNEL_STEP_FORM } from "@/builder/components-sections";
import type {
  FunnelAnswers,
  Step as CurrentStep,
} from "@/modules/v2/funnel/config";
import { steps } from "@/modules/v2/funnel/config";
import { funnelConfig } from "@/modules/v2/funnel/config";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { BiEventsNames } from "@/services/analytics/event-names";

export type LpFunnelStepFormProps = {
  children: React.ReactNode;
  practice: FunnelAnswers["practice"];
  stepName: string;
  subPractice: FunnelAnswers["subPractice"];
};

export const LpFunnelStepForm = ({
  children,
  stepName,
  practice,
  subPractice,
}: LpFunnelStepFormProps) => {
  const methods = useForm();
  const { setFunnelAnswers } = useFunnelAnswers();
  const { push } = useRouter();

  const hasChildren = Array.isArray(children) && children.length > 0;

  const currentStepConfig: CurrentStep =
    // @ts-expect-error: implicit any
    funnelConfig[practice]?.[subPractice].screens?.[stepName];

  const { next } = currentStepConfig;

  useEffect(() => {
    // Track funnel in lading page view event
    analytics.track(BiEventsNames.WebInPageFunnelView, {
      practice,
      sub_practice: subPractice,
      current_step_key: stepName,
    });
  }, [practice, subPractice, stepName]);

  async function onSubmit(data: FunnelAnswers) {
    const currentFunnelAnswers = {
      ...data,
      practice,
      subPractice,
    };

    analytics.track(BiEventsNames.WebFunnelClick, {
      practice,
      sub_practice: subPractice,
      current_step_key: stepName,
      answer_value: Object.entries(data!).flat().toString(),
      answer_length: data.moreInfo?.length ?? 0,
      full_answer: JSON.stringify(data),
      click_type: "submit",
    });

    // Set latest funnel data to session storage
    setFunnelAnswers(currentFunnelAnswers);

    // Navigate to next step in the funnel
    const nextStep = await next({
      answers: currentFunnelAnswers,
    });
    if (nextStep) {
      await push(`/v2/funnel/${practice}/${subPractice}/${nextStep}`);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {hasChildren ? children : <>insert funnel step form components</>}
      </form>
    </FormProvider>
  );
};

const LpFunnelStepFormWithChildren = withChildren(LpFunnelStepForm);

Builder.registerComponent(LpFunnelStepFormWithChildren, {
  name: LP_FUNNEL_STEP_FORM,
  inputs: [
    {
      name: "stepName",
      friendlyName: "Step Name",
      type: "string",
      enum: Object.values(steps),
      required: true,
    },
    {
      name: "practice",
      friendlyName: "Practice",
      type: "string",
      required: true,
    },
    {
      name: "subPractice",
      friendlyName: "Sub Practice",
      type: "string",
      required: true,
    },
  ],
});

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

import { reportGraphQLError, reportNetworkError } from "./reporters";

const OLD_BACKEND_OPERATIONS = new Set([
  "CreateWebLead",
  "UpdateWebLead",
  "CreateRejectedCustomer",
  "GetIntakeMeetingSlots",
  "GetLeadIdByFunnelActionId",
  "RegisterStripeCustomer",
  "AddPayment",
]);

const isOldBackendOp = (operationName?: string): boolean =>
  Boolean(operationName && OLD_BACKEND_OPERATIONS.has(operationName));

const oldHttpLink = createHttpLink({
  uri: (operation) =>
    `${process.env.NEXT_PUBLIC_OLD_BACKEND_URL}?op=${
      operation.operationName
    }&oid=${Math.random()}`,
});

const httpLink = createHttpLink({
  uri: (operation) =>
    `${process.env.NEXT_PUBLIC_VINNY_URL}?op=${
      operation.operationName
    }&oid=${Math.random()}`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    reportGraphQLError({ operation, graphQLErrors, networkError });
  } else if (networkError) {
    reportNetworkError({ operation, networkError });
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 1000 * 60 * 5, // 5m
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (error) => !!error,
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    retryLink,
    ApolloLink.split(
      (op) => isOldBackendOp(op.operationName),
      oldHttpLink,
      httpLink,
    ),
  ]),
  cache: new InMemoryCache(),
});

export { ApolloProvider, client };

import Balancer from "react-wrap-balancer";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@flare/ui";

const textVariants = cva("font-normal antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end",
    },
    color: {
      beige: "text-neutral-100",
      blue: "text-blue-100",
      gray: "text-blue-40",
      "light-gray": "text-blue-20",
      green: "text-green-100",
      white: "text-white-80",
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6",
    },
    variant: {
      "text-1":
        "font-serif text-base font-light leading-7 md:text-2xl md:leading-9",
      "text-2": "text-base font-medium leading-6 md:text-lg md:leading-7",
      "text-3": "text-base leading-6 md:text-lg md:leading-7",
      "text-4": "text-base leading-6",
      "text-5": "text-sm leading-5",
      "text-6": "text-xs leading-5",
      label:
        "text-xs font-semibold uppercase leading-5 tracking-[1.6px] md:text-base md:leading-6",
    },
  },
});

type TextPProps = { as?: "p" } & React.ComponentPropsWithRef<"p">;
type TextSpanProps = { as: "span" } & React.ComponentPropsWithRef<"span">;

type TextVariants = VariantProps<typeof textVariants>;

export type TextProps = TextVariants &
  (TextPProps | TextSpanProps) & {
    balanceText?: boolean;
  };

export const Text = ({
  alignment = "start",
  as: Tag = "p",
  balanceText,
  children,
  className,
  color = "blue",
  maxLines,
  variant = "text-1",
  ...restProps
}: TextProps) => {
  return (
    <Slot
      className={cn(
        textVariants({ alignment, color, maxLines, variant, className }),
      )}
      {...restProps}
    >
      <Tag>{balanceText ? <Balancer>{children}</Balancer> : children}</Tag>
    </Slot>
  );
};

export const BiEventsNames = {
  WebFunnelPaymentSuccess: "web__funnel__payment__success",
  WebFunnelPaymentDeclined: "web__funnel__payment__declined",
  WebFunnelPaymentClick: "web__funnel__payment__click",
  WebCalendarClick: "web__calendar__click",
  WebCalendarPageView: "web__calendar__page_view",
  WebCalendarSuccess: "web__calendar__success",
  WebCalendarSkip: "web__calendar__skip",
  WebExperimentTreatment: "web__experiment__treatment",
  WebFunnelClick: "web__funnel__click",
  WebFunnelError: "web__funnel__error",
  WebFunnelView: "web__funnel__view",
  WebInPageFunnelView: "web__in_page_funnel__view",
  WebOpenSiteCross: "web__open_site__cross",
  WebPageClick: "web__page__click",
  WebPageCtaClick: "web__page__cta_click",
  WebPageView: "web__page__view",
  WebPopupClick: "web__popup__click",
  WebPageLoadCross: "web__page_load__cross",
  WebLeadCreate: "web__lead__create",
  WebLeadUpdate: "web__lead__update",
  WebPageError: "web__page__error",
  OnlineFunnelFullstoryRecordingStarted:
    "online_funnel__fullstory_recording__started",
  WebLeadScoreResult: "web__lead_score__result",
  WebDirectLssQualification: "web__direct_lss__qualification",
  WebVisitorStatusCodeCheck: "web__visitor_status_code__check",
  WebCcAvailabilityCheck: "web__cc_availability__check",
  WebCmnEligibility: "web__cmn__eligibility",
  WebModalAction: "web__modal__action",
  WebFormSubmit: "web__form__submit",
} as const;
